import React from 'react';
import { v4 as uuid } from 'uuid';

import { Button } from '@cimpress/react-components';

import PopulateFromItemOrOrder from './PopulateFromItemOrOrder';
import TesterFormFields from './TesterFormFields';
import styles from './dataInput.module.scss';

export default function DataInput() {
  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <h2 className={styles.header}>Route Tester</h2>
        <p>
          Search an existing item or order, and optionally edit the order information, to test which
          fulfiller an item would be routed to.
        </p>

        <PopulateFromItemOrOrder key={uuid()} />
        <TesterFormFields key={uuid()} />

        <Button className={styles.testButton} variant="primary" onClick={() => {}}>
          Test Routing Configuration
        </Button>
      </div>
    </div>
  );
}
