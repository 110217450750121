/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { Select } from '@cimpress/react-components';

const ConditionGroupNextNodeSelect = styled(Select)`
  max-width: 300px;
  min-width: 100px;
  margin-bottom: -18px;
`;

export default ConditionGroupNextNodeSelect;
