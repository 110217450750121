import * as FullStory from '@fullstory/browser';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import App from './App';

FullStory.init({ orgId: '97CT5' });

// https://github.com/leothorp/hide-cra-error-overlay
// This is specifically to hide errors from the generic selector.
if (process.env.NODE_ENV !== 'production') {
  import('hide-cra-error-overlay').then(({ initHideOverlay }) => initHideOverlay());
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <App />,
  },
]);

createRoot(document.getElementById('root')!).render(<RouterProvider router={router} />);
