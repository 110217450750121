import React, { memo } from 'react';

import { TextField, TextFieldProps } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

export const RoutedToResultStyledTextField = memo(function RoutedToStyledTextField(
  props: TextFieldProps,
) {
  const inputStyles = {
    height: DEFAULT_INPUT_HEIGHT,
    width: '200px',
  };

  const topLevelStyles = {
    gridArea: 'result-value',
    width: '200px',
  };

  return <TextField {...props} style={topLevelStyles} inputStyle={inputStyles} />;
});

export const RoutedToOrderedStyledTextField = memo(function RoutedToStyledTextField(
  props: TextFieldProps,
) {
  const inputStyles = {
    height: DEFAULT_INPUT_HEIGHT,
    width: '200px',
  };

  const topLevelStyles = {
    gridArea: 'ordered-value',
    width: '200px',
  };

  return <TextField {...props} style={topLevelStyles} inputStyle={inputStyles} />;
});
