/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button } from '@cimpress/react-components';

export default function SidebarFooter({
  onSave,
  onCancel,
  enabled,
  onDelete,
  nodeIsReachable,
}: {
  onSave: () => void;
  onCancel: () => void;
  enabled: boolean;
  onDelete: () => void;
  nodeIsReachable: boolean;
}) {
  return (
    <div css={containerStyles}>
      <Button variant="link" onClick={onDelete} css={buttonStyles}>
        {nodeIsReachable ? 'Unassign' : 'Delete'}
      </Button>

      <div css={saveAndCancelStyles}>
        <Button variant="default" onClick={onCancel} css={buttonStyles}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave} css={buttonStyles} disabled={!enabled}>
          Save
        </Button>
      </div>
    </div>
  );
}
const containerStyles = css`
  display: flex;
  justify-content: space-between;
`;

const saveAndCancelStyles = css`
  display: flex;
  justify-content: space-evenly;
`;

const buttonStyles = css`
  margin: 0 8px;
`;
