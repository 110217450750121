/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { colors } from '@cimpress/react-components';

const HorizontalRule = styled.hr`
  border: 1px solid ${colors.info.base};
  border-radius: 4px;
  margin: auto auto 8px auto;
  width: 90%;
`;

export default HorizontalRule;
