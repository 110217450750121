import React, { memo } from 'react';

import { TextField, TextFieldProps } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

type PathTextfieldProps = Pick<
  TextFieldProps,
  'status' | 'required' | 'value' | 'onFocus' | 'autoFocus' | 'onBlur'
> & {
  onChange: (s: string) => void;
};

export default memo(function PathTextField(props: PathTextfieldProps) {
  const inputStyles = {
    height: DEFAULT_INPUT_HEIGHT,
    width: '300px',
  };

  const topLevelStyles = {
    width: '300px',
    gridArea: 'path',
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = props.onChange(e.target.value);
    return value;
  };

  return (
    <TextField
      {...props}
      label="Path"
      style={topLevelStyles}
      inputStyle={inputStyles}
      onChange={onChange}
      inputMode="text"
    />
  );
});
