/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

export default function SortOrFilter({
  actionType,
  setActionType,
}: {
  actionType: 'sort' | 'filter';
  setActionType: (sortOrFilter: 'sort' | 'filter') => void;
}) {
  return (
    <div css={flexDivStyles}>
      <Button
        variant={actionType === 'sort' ? 'primary' : 'default'}
        onClick={() => setActionType('sort')}
      >
        Sort by
      </Button>
      <Button
        variant={actionType === 'filter' ? 'primary' : 'default'}
        onClick={() => setActionType('filter')}
      >
        Filter by
      </Button>
    </div>
  );
}

const flexDivStyles = css`
  grid-area: sort-or-filter;

  align-items: center;
  display: flex;
  height: ${DEFAULT_INPUT_HEIGHT};
`;
