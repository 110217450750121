// @ts-ignore
// tslint:disable-next-line:no-submodule-imports
import IconCheckCircleAlt from '@cimpress-technology/react-streamline-icons/lib/IconCheckCircleAlt';

import { colors } from '@cimpress/react-components';
import React, { Component } from 'react';
interface IValidIconProps {
  iconColor: string;
}

class ValidIcon extends Component<IValidIconProps, any> {
  public static defaultProps = {
    iconColor: colors.success.base,
  };

  public render() {
    const { iconColor } = this.props;

    return <IconCheckCircleAlt color={iconColor} weight="fill" />;
  }
}

export default ValidIcon;
