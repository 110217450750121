import { colors } from '@cimpress/react-components';
import { get } from 'lodash';
import React from 'react';

import './DetailViewContainer.css';

interface IDetailViewContainerProps {
  label: string;
  isRequired: boolean;
  isValid: boolean;
  children: any;
  styleClasses?: any;
}

/* tslint:disable-next-line */
const DetailViewContainer = ({ label, isRequired, isValid, styleClasses, children }: IDetailViewContainerProps) => {
  return (
    <div className={`detail-view-container ${get(styleClasses, 'detailHeaderContainer', '')}`}>
      <div className="detail-view-header">
        {label} {isRequired && <span style={{ color: colors.persimmon.base }}>*</span>}
        {!isValid ? <i className="fa fa-exclamation-triangle text-danger" /> : null}
      </div>
      <div className="detail-view-body">{children}</div>
    </div>
  );
};

export default DetailViewContainer;
