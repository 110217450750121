import {
  Attribute as ExplorerAttribute,
  AttributeModelExplorerFactory,
  AttributeType as AttributeModelAttributeType,
  IAttributeModelExplorerState,
  PivotAttribute,
  ResolutionState,
  ValueType,
} from '@cimpress-technology/attribute-model-explorer';
import { get, isEmpty } from 'lodash';
import IModelExplorerRepo from '../Components/Interfaces/IModelExplorerRepo';
import { MetadataKeys } from './index';

function getAttributeType(attribute: ExplorerAttribute): AttributeType {
  switch (attribute.type) {
    case AttributeModelAttributeType.String:
      return AttributeType.listOfValues;
    case AttributeModelAttributeType.Numeric:
      if (attribute.values[0] && attribute.values[0].type === ValueType.Formula) {
        return AttributeType.formula;
      }

      return AttributeType.listOfRanges;
    default:
      throw new TypeError(`Invalid type of "${attribute.type}" received from the Attribute Model Explorer.`);
  }
}

function checkIsEveryStateUnresolvable(listOfStates: ResolutionState[]): boolean {
  return listOfStates.length > 0 && listOfStates.every((resolutionState: ResolutionState) => resolutionState === ResolutionState.unresolvable);
}

/**
 * The type of Attributes, defined as how their values are specified.
 * This was copied and extended from the /v1/ruleSets modelling of attribute data.
 */
enum AttributeType {
  listOfValues = 'listOfValues',
  listOfRanges = 'listOfRanges',
  formula = 'formula',

  /**
   * Special Case Type 'Mapping" -- used for Attributes without an explicitly declared type,
   * such as attributes defined only in mappings (a.k.a. "attributeValueTranslations" or "containered attributes")
   */
  mapping = 'mapping',
}

interface IClass {
  name: string;
  subclass?: string;
}

export interface IAttribute {
  key: string;
  type: AttributeType;
  isDisplayed: boolean;
  isResourceAttribute: boolean;
  unitOfMeasure?: string;
  resolvedValue?: string;
  classes: IClass[];
}

export interface IActiveConstraint {
  name: string;
}

export interface ISinglePivotStateDescriptor {
  [attributeKey: string]: { [attributeValue: string]: IStateDescriptor };
}

export interface IDoublePivotStateDescriptor {
  [attributeKey: string]: { [attributeValue: string]: ISinglePivotStateDescriptor };
}

interface IStateDescriptorWithMetadata {
  stateDescriptor: IStateDescriptor | ISinglePivotStateDescriptor | IDoublePivotStateDescriptor;
  metadata: {
    isStateUnresolvable: boolean;
  };
}

export interface IStateDescriptor {
  activeConstraints: IActiveConstraint[];
  attributes: IAttribute[];
  state: ResolutionState;
  validationErrors: string[];
}

export default class StateDescriptor {
  public static createStateFromAttributeModelExplorer(
    attributeModelExplorer: IModelExplorerRepo,
    optimizePivot: boolean,
  ): IStateDescriptorWithMetadata {
    if (attributeModelExplorer.getPivotAttributes().length > 0 && optimizePivot) {
      return StateDescriptor.optimizedPivotStateCreation(attributeModelExplorer);
    }

    return StateDescriptor.createFromAttributeModelExplorer(attributeModelExplorer);
  }

  private static createFromAttributeModelExplorer(
    attributeModelExplorer: IModelExplorerRepo,
  ): IStateDescriptorWithMetadata {
    const state = attributeModelExplorer.getState();

    const pivotAttributes = attributeModelExplorer.getPivotAttributes();
    if (pivotAttributes.length === 0) {
      const amexState = StateDescriptor.createStateDescriptorFromAttributeModelExplorerState(
        state as IAttributeModelExplorerState,
      );

      return {
        metadata: {
          isStateUnresolvable: amexState.state === ResolutionState.unresolvable,
        },
        stateDescriptor: amexState,
      };
    }

    if (pivotAttributes.length === 1) {
      const listOfStates: ResolutionState[] = [];
      const pivotAttributeKey = pivotAttributes[0].key;
      const assignedValues = Object.keys(state[pivotAttributeKey]);

      state[pivotAttributeKey] = assignedValues.map(pivotValue => {
        const amexState = StateDescriptor.createStateDescriptorFromAttributeModelExplorerState(
          state[pivotAttributeKey][pivotValue],
        );

        listOfStates.push(amexState.state);

        return {
          pivotValue,
          resolution: amexState,
        };
      });

      return {
        metadata: {
          isStateUnresolvable: checkIsEveryStateUnresolvable(listOfStates),
        },
        // @ts-ignore
        stateDescriptor: (state as ISinglePivotStateDescriptor),
      };
    }

    if (pivotAttributes.length === 2) {
      const listOfStates: ResolutionState[] = [];
      const pivotAttributeAKey = pivotAttributes[0].key;
      const pivotAttributeBKey = pivotAttributes[1].key;
      const assignedValuesA = Object.keys(get(state, [pivotAttributeAKey], {}));
      const assignedValuesB = Object.keys(get(state, [pivotAttributeAKey, assignedValuesA[0], pivotAttributeBKey], {}));

      state[pivotAttributeAKey] = assignedValuesA.map(pivotAValue => ({
        pivotValue: pivotAValue,
        resolution: {
          [pivotAttributeBKey]: assignedValuesB.map(pivotBValue => {
            const amexState = StateDescriptor.createStateDescriptorFromAttributeModelExplorerState(
              state[pivotAttributeAKey][pivotAValue][pivotAttributeBKey][pivotBValue],
            );

            listOfStates.push(amexState.state);

            return {
              pivotValue: pivotBValue,
              resolution: amexState,
            };
          }),
        },
      }));

      return {
        metadata: {
          isStateUnresolvable: checkIsEveryStateUnresolvable(listOfStates),
        },
        // @ts-ignore
        stateDescriptor: (state as IDoublePivotStateDescriptor),
      };
    }

    throw Error('Too many pivots detected. Expected maximum of two.');
  }

  /**
   * This function is to enhance performance if pivot over on multi values.
   * We will call StateDescriptor.createFromAttributeModelExplorer only if ResolutionState is not partiallyResolved
   * so that it won't be any lag on every selection.
   * @param attributeModelExplorer
   */
  private static optimizedPivotStateCreation(attributeModelExplorer: IModelExplorerRepo) {
    let statePath: string = '';
    const pivotAttributes: PivotAttribute[] = attributeModelExplorer.getPivotAttributes();

    if (attributeModelExplorer !== undefined) {
      pivotAttributes.forEach((pivotAttribute: PivotAttribute) => {
        const values: string[] = pivotAttribute.assignedValues;

        if (values.length > 0) {
          statePath =
            statePath === ''
              ? `${statePath}${pivotAttribute.key}[0].resolution`
              : `${statePath}.${pivotAttribute.key}[0].resolution`;
        }
      });
    }

    const { stateDescriptor, metadata } = StateDescriptor.createFromAttributeModelExplorer(attributeModelExplorer);

    if (!isEmpty(statePath) && get(stateDescriptor, statePath, '').state !== ResolutionState.partiallyResolved) {
      return StateDescriptor.createFromAttributeModelExplorer(attributeModelExplorer);
    }

    // Setting empty state for other selected values of pivot attributes
    this.setEmptyStateForPivotedValues(stateDescriptor, pivotAttributes);

    return { stateDescriptor, metadata };
  }

  private static createStateDescriptorFromAttributeModelExplorerState(
    attributeModelExplorerState: IAttributeModelExplorerState,
  ): IStateDescriptor {
    const attributes = attributeModelExplorerState.attributes.map((attribute: ExplorerAttribute) => {
      const result: IAttribute = {
        classes: attribute.metadata.get(AttributeModelExplorerFactory.METADATA_KEYS.v1RuleSet.classes),
        isDisplayed: attribute.metadata.get(MetadataKeys.isDisplayed),
        isResourceAttribute: attribute.metadata.get(MetadataKeys.isResourceAttribute),
        key: attribute.key,
        resolvedValue: attribute.getResolvedValue(),
        type: getAttributeType(attribute),
        unitOfMeasure: attribute.metadata.get(AttributeModelExplorerFactory.METADATA_KEYS.v1RuleSet.unitOfMeasure),
      };

      return result;
    });

    return {
      attributes,
      activeConstraints: attributeModelExplorerState.activeConstraints,
      state: attributeModelExplorerState.state,
      validationErrors: attributeModelExplorerState.validationErrors,
    };
  }

  /**
   * This function will set empty state for all the selected values of pivot attributes except for first value.
   * @param state
   * @param pivotAttributes
   */
  private static setEmptyStateForPivotedValues(
    state: IStateDescriptor | ISinglePivotStateDescriptor | IDoublePivotStateDescriptor,
    pivotAttributes: PivotAttribute[],
  ) {
    if (pivotAttributes.length === 1) {
      const assignedValuesState = this.getEmptyStateForPivotedAttribute(pivotAttributes[0]);

      state[pivotAttributes[0].key].push(...assignedValuesState);
    } else if (pivotAttributes.length === 2) {
      const assignedValuesOfA = pivotAttributes[0].assignedValues || [];
      const assignedValuesOfB = pivotAttributes[1].assignedValues || [];

      if (assignedValuesOfA.length > 1 || assignedValuesOfB.length > 1) {
        const assignedValuesStateA = this.getEmptyStateForPivotedAttribute(pivotAttributes[0]);

        state[pivotAttributes[0].key].push(...assignedValuesStateA);

        const assignedValuesStateB = this.getEmptyStateForPivotedAttribute(pivotAttributes[1]);
        const pivotAttributeAKey = pivotAttributes[0].key;
        const pivotAttributeBKey = pivotAttributes[1].key;

        state[pivotAttributeAKey][0].resolution[pivotAttributeBKey].push(...assignedValuesStateB);
      }
    }
  }

  /**
   * This function will create empty state for every assigned value except first one.
   * @param pivotAttribute
   */
  private static getEmptyStateForPivotedAttribute(pivotAttribute: PivotAttribute) {
    const assignedValues = pivotAttribute.assignedValues || [];
    let emptyState: any[] = [];

    if (assignedValues.length > 1) {
      const assignedValuesWithoutFirst = assignedValues.slice(1, assignedValues.length);

      emptyState = assignedValuesWithoutFirst.map(assignedValue => ({
        pivotValue: assignedValue,
        resolution: {},
      }));
    }

    return emptyState;
  }
}

