/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import _ from 'lodash';
import React, { memo } from 'react';

import { Select, SelectProps } from '@cimpress/react-components';

const StyledSourceSelect = styled(Select)`
  width: 150px;
  grid-area: source;
`;

const options = [{ label: 'Item', value: 'item' }];

export default memo(
  function SourceSelect(props: SelectProps) {
    const selectedValue = _.find(options, (option) => props.value === option.value) || '';
    return (
      <StyledSourceSelect
        {...props}
        options={options}
        value={selectedValue}
        selectedSelect={Select}
        label="Source"
      />
    );
  },
  (prev, next) => prev.value === next.value,
);
