import styled from '@emotion/styled';
import _ from 'lodash';
import React, { memo } from 'react';
import CreatableSelect from 'react-select/creatable';

import { Select, SelectProps, SelectWrapper, SelectWrapperProps } from '@cimpress/react-components';

// This is the only way I was able to set input width on the react-select input
import './styles.scss';

const StyledSelect = styled(Select)`
  min-width: 200px;
  max-width: 400px;
`;

const StyledSelectWrapper = styled(SelectWrapper)`
  min-width: 200px;
  max-width: 400px;
`;

export default memo(function GeocodeStateSelect(props: SelectProps) {
  const { value } = props;
  const valuesWithLabels = _.map(value, (v) => ({ value: v, label: `${v}` }));

  return (
    <StyledSelect {...props} isMulti={true} value={valuesWithLabels} classNamePrefix={'geocode'} />
  );
});

export const GeocodeCreatableStateSelect = memo(function GeocodeCreatableStateSelect(
  props: SelectWrapperProps,
) {
  const { value } = props;
  const valuesWithLabels = _.map(value, (v) => ({ value: v, label: `${v}` }));

  return (
    <StyledSelectWrapper
      {...props}
      components={{ DropdownIndicator: null }}
      selectedSelect={CreatableSelect}
      isMulti={true}
      value={valuesWithLabels}
      classNamePrefix={'geocode-extra'}
    />
  );
});
