import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Button, DragAndDrop, Drawer, Droppable } from '@cimpress/react-components';

import { CONDITION_CONFIGURATIONS } from '../../constants/conditionConfigurations';
import { useFulfillersWithDefault } from '../../hooks/useFulfillers';
import { useFulfillmentExpectations } from '../../hooks/useFulfillmentExpectations';
import {
  Action,
  DisplayValue,
  IfThenWithConditionIds,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../../types';
import { addIdsToIfThens } from '../../utils/conversions';
import { newBlankIfThen } from '../../utils/newBlankIfThen';
import { deleteNode, disconnectNode, getValidDownstreamNodes } from '../../utils/nodeUtils';
import { parseConditionJoiner } from '../../utils/parseConditionJoiner';
import {
  actionHasAnyBlankFields,
  conditionsHaveAnyBlankFields,
  ifThensAreEmpty,
} from '../../utils/validation';
import AddElementButton from '../AddElementButton';
import CollapsibleWrapper from '../CollapsibleWrapper';
import DraggableElement from '../DraggableElement';
import NextNodeWrapperTooltip from '../NextNodeWrapperTooltip';
import ActionSelection from '../actionSelection/ActionSelection';
import ConditionGroup from '../conditionGroup/ConditionGroup';
import DefaultNextNodeSelect from '../styledComponents/DefaultNextNodeSelect';
import NothingConfigured from '../styledComponents/NothingConfigured';
import StyledTextField from '../styledComponents/StyledTextField';
import TrashIconButton from '../styledComponents/TrashIconButton';
import ConfirmCloseModal from './ConfirmCloseModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import SidebarFooter from './SidebarFooter';
import styles from './nodeSidebar.module.scss';

export default function NodeSidebar({
  show,
  onSave,
  onCancel,
  selectedNode,
  nodeOptions,
  workingConfiguration,
  onSaveConfiguration,
  nodeIsReachable,
  isStartingNode,
}: {
  show: boolean;
  onSave: (updatedNode: WorkingRoutingConfigurationNode, newStartingNode: boolean) => void;
  onCancel: () => void;
  selectedNode: WorkingRoutingConfigurationNode;
  nodeOptions: WorkingRoutingConfigurationNode[];
  workingConfiguration: WorkingRoutingConfigurationV3;
  onSaveConfiguration: (updatedConfiguration: WorkingRoutingConfigurationV3) => void;
  nodeIsReachable: boolean;
  isStartingNode: boolean;
}) {
  const [focusedInput, setFocusedInput] = useState<string | null>();
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hasBecomeStartingNode, setHasBecomeStartingNode] = useState(false);

  const [workingNode, setWorkingNode] = useState<WorkingRoutingConfigurationNode | null>();

  // This is used for comparison purposes to see if we have any changes in the working node
  const [selectedNodeSnapshot, setSelectedNodeSnapshot] =
    useState<WorkingRoutingConfigurationNode | null>();

  const [key, setKey] = useState<string>(uuid());
  const [openAccordions, setOpenAccordions] = useState<string[]>([]);
  const [canSave, setCanSave] = useState(false);

  const fulfillers = useFulfillersWithDefault();
  const fulfillmentExpectations = useFulfillmentExpectations();

  useEffect(() => {
    setCanSave(
      !conditionsHaveAnyBlankFields(workingNode?.ifThens) &&
        !actionHasAnyBlankFields(workingNode?.defaultAction) &&
        !ifThensAreEmpty(workingNode?.ifThens) &&
        (!_.isEqual(selectedNodeSnapshot, workingNode) || hasBecomeStartingNode),
    );
  }, [JSON.stringify(workingNode), JSON.stringify(selectedNode), hasBecomeStartingNode]);

  useEffect(() => {
    const snapshot = {
      ...selectedNode,
      ifThens: addIdsToIfThens(selectedNode?.ifThens ?? []),
    };
    setSelectedNodeSnapshot(snapshot);
    // Clone the snapshot; otherwise they will have the same object reference,
    // which defeats the purpose of tracking them separately!
    setWorkingNode(_.cloneDeep(snapshot));
  }, [JSON.stringify(selectedNode)]);

  const handleIfThenUpdate = (updatedIfThen: IfThenWithConditionIds, index: number) => {
    setWorkingNode((node) => {
      setKey(uuid());
      const ifThens = node!.ifThens ? [...node!.ifThens] : [];
      ifThens[index] = updatedIfThen;
      node!.ifThens = ifThens;
      return node;
    });
  };

  const handleDefaultActionUpdate = (updatedAction: Action) => {
    setWorkingNode({
      ...workingNode!,
      defaultActionId: updatedAction.id,
      defaultAction: updatedAction,
    });
  };

  const handleDefaultActionDelete = () => {
    setWorkingNode({
      ...workingNode!,
      defaultActionId: undefined,
      defaultAction: undefined,
    });
  };

  const deleteConditionGroupByIndex = (index: number) => {
    setWorkingNode((node) => {
      if (node!.ifThens?.length) {
        node!.ifThens.splice(index, 1);
      }

      return {
        ...node!,
      };
    });
  };

  const handleAccordionClick = (ifThenId: string) => {
    if (_.includes(openAccordions, ifThenId)) {
      setOpenAccordions((previousState) => _.filter(previousState, (id) => id !== ifThenId));
    } else {
      setOpenAccordions((previousState) => [...previousState, ifThenId]);
    }
  };

  const nodeOptionsForSelect = _.map(
    getValidDownstreamNodes(nodeOptions, selectedNode?.id),
    (node) => ({
      value: node.id,
      label: node.name,
    }),
  );

  const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

  const addIfThen = () => {
    const newIfThen = newBlankIfThen();
    const ifThens = workingNode!.ifThens ?? [];
    newIfThen.name = `${workingNode!.name || workingNode!.id} - Condition Group ${
      ifThens.length + 1
    }`;
    setWorkingNode((node) => {
      const newIfThens = [...ifThens, newIfThen];
      node!.ifThens = newIfThens;
      return {
        ...node!,
      };
    });
  };

  const handleDiscardChanges = () => {
    setShowDiscardModal(false);
    setCanSave(false);
    onCancel();
  };

  const handleSetNextNode = (nextNodeSelection?: DisplayValue<string>) => {
    const nextNodeWithId = nextNodeSelection?.value
      ? _.find(nodeOptions, { id: nextNodeSelection.value })
      : undefined;
    setWorkingNode((node) => ({
      ...node!,
      defaultNextNodeId: nextNodeWithId?.id ?? undefined,
    }));
  };

  const handleNodeNameUpdate = (e) => {
    setWorkingNode((node) => ({
      ...node!,
      name: e.target.value,
    }));
  };

  const handleNodeDescriptionUpdate = (e) => {
    setWorkingNode((node) => ({
      ...node!,
      description: e.target.value,
    }));
  };

  const handleSave = () => {
    // Ensure integer values are actually integers and not decimals
    _.forEach(workingNode!.ifThens, (ifThen) => {
      const joiner = parseConditionJoiner(ifThen);
      _.forEach(ifThen.rule.conditions[joiner], (condition) => {
        if (condition.customSource) {
          return;
        }
        const matchingConfig = _.find(CONDITION_CONFIGURATIONS, (config) => {
          if (condition.path !== '') {
            return config.path === condition.path;
          }
          // The only time we should ever do this is for option-related conditions
          return config.parameterIdentifier.path === condition.value.path;
        });
        if (matchingConfig?.shouldTruncateNumberValue) {
          condition.value = Math.trunc(condition.value);
        }
      });
    });

    const saveAsNewStartingNode = hasBecomeStartingNode;
    if (hasBecomeStartingNode) {
      setHasBecomeStartingNode(false);
    }

    onSave(
      {
        ...workingNode!,
      },
      saveAsNewStartingNode,
    );
  };

  const findNextNodeNameFromId = (nextNodeId?: string) => {
    const nextNode = _.find(nodeOptions, (node) => node.id === nextNodeId);
    return nextNode?.name ?? '';
  };

  const move = (droppableSource, droppableDestination) => {
    const sourceClone = Array.from(workingNode?.ifThens ?? []);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    sourceClone.splice(droppableDestination.index, 0, removed);

    return sourceClone;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const newResult = move(source, destination);
    setWorkingNode((node) => ({
      ...node!,
      ifThens: [...newResult],
    }));
  };

  const conditionsOrNothing = (
    <>
      {_.map(workingNode?.ifThens ?? [], (ifThen: IfThenWithConditionIds, index: number) => {
        return (
          <DraggableElement key={`draggable-${ifThen.id}`} parentKey={ifThen.id} index={index}>
            <CollapsibleWrapper
              key={`collapsible-${ifThen.id}`}
              title={
                <div className={styles.ifThenContainer} key={`flexdiv-${ifThen.id}`}>
                  <span>{ifThen.name}</span>
                  <TrashIconButton onDelete={() => deleteConditionGroupByIndex(index)} />
                </div>
              }
              isOpen={_.includes(openAccordions, ifThen.id)}
              onAccordionClick={handleAccordionClick}
            >
              <ConditionGroup
                key={key}
                updateIfThen={(_ifThen) => handleIfThenUpdate(_ifThen, index)}
                deleteIfThen={() => deleteConditionGroupByIndex(index)}
                nodeOptions={nodeOptionsForSelect}
                ifThen={ifThen}
                ifThenIdsWithinNode={_.map(workingNode?.ifThens || [], 'id')}
                handleInputFocus={setFocusedInput}
                focusedInput={focusedInput ?? null}
                fulfillers={fulfillers}
                fulfillmentExpectations={fulfillmentExpectations}
              />
            </CollapsibleWrapper>
          </DraggableElement>
        );
      })}

      {!workingNode?.ifThens?.length && (
        <NothingConfigured>
          No condition groups have been added yet. This node will apply the default action if one is
          present, and pass options to the next node.
        </NothingConfigured>
      )}
    </>
  );

  const handleCloseSidebar = () => {
    if (canSave) {
      setShowDiscardModal(true);
    } else {
      handleDiscardChanges();
    }
  };

  const handleDeleteButtonPress = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    const clonedConfiguration = _.cloneDeep(workingConfiguration);
    nodeIsReachable
      ? disconnectNode({
          rc: clonedConfiguration,
          nodeId: workingNode!.id,
        })
      : deleteNode({
          rc: clonedConfiguration,
          nodeId: workingNode!.id,
        });

    onSaveConfiguration(clonedConfiguration);
    setShowDeleteModal(false);
  };

  const Header = (
    <h2 className={styles.header}>
      <span>Edit Node</span>
    </h2>
  );

  return (
    <div className={styles.container}>
      <Drawer
        zIndex={5}
        size={viewportWidth > 1400 ? 0.5 : 1.0}
        show={show}
        header={Header}
        footer={
          <SidebarFooter
            onSave={handleSave}
            onCancel={handleCloseSidebar}
            onDelete={handleDeleteButtonPress}
            nodeIsReachable={nodeIsReachable}
            enabled={canSave}
          />
        }
        onRequestHide={handleCloseSidebar}
      >
        <div>
          <ConfirmCloseModal
            showModal={showDiscardModal}
            onRequestHide={() => setShowDiscardModal(false)}
            onConfirmCancel={handleDiscardChanges}
          />
          <ConfirmDeleteModal
            showModal={showDeleteModal}
            onRequestHide={handleDeleteCancel}
            onConfirmDelete={handleConfirmDelete}
            nodeIsReachable={nodeIsReachable}
          />

          <div>
            <div className={styles.flexRow}>
              <StyledTextField
                size="lg"
                name="nodeNameInput"
                value={workingNode?.name}
                label="Node Name"
                onChange={handleNodeNameUpdate}
                type="text"
                onFocus={() => setFocusedInput('nodeNameInput')}
                autoFocus={focusedInput === 'nodeNameInput'}
                required
              />
              <StyledTextField
                size="lg"
                name="nodeDescriptionInput"
                value={workingNode?.description}
                label="Node Description"
                onChange={handleNodeDescriptionUpdate}
                type="text"
                onFocus={() => setFocusedInput('nodeDescriptionInput')}
                autoFocus={focusedInput === 'nodeDescriptionInput'}
              />
            </div>
            <div className={styles.flexRow}>
              <NextNodeWrapperTooltip enabled={!nodeOptionsForSelect.length}>
                <DefaultNextNodeSelect
                  label="Default next node"
                  value={{
                    value: workingNode?.defaultNextNodeId,
                    label: findNextNodeNameFromId(workingNode?.defaultNextNodeId),
                  }}
                  options={nodeOptionsForSelect}
                  onChange={handleSetNextNode}
                  menuPlacement="top"
                  isDisabled={!nodeOptionsForSelect.length}
                  isClearable
                />
              </NextNodeWrapperTooltip>
            </div>
            {!isStartingNode && hasBecomeStartingNode && (
              <Button
                className={styles.setStartingNode}
                onClick={() => setHasBecomeStartingNode(false)}
                variant="primary"
              >
                Unset Node as starting node
              </Button>
            )}
            {!isStartingNode && !hasBecomeStartingNode && (
              <Button
                className={styles.setStartingNode}
                onClick={() => setHasBecomeStartingNode(true)}
              >
                Set Node as starting node
              </Button>
            )}
            {hasBecomeStartingNode && (
              <p className={styles.isStartingNodeWarning}>
                This Node has been set as the starting node; if changes are saved, preceding Nodes
                will become unassigned.
              </p>
            )}
          </div>

          <DragAndDrop onDragEnd={onDragEnd}>
            <Droppable droppableId="ifThens" isEmpty={!workingNode?.ifThens?.length}>
              {conditionsOrNothing}
            </Droppable>
          </DragAndDrop>
          <AddElementButton
            onClick={addIfThen}
            text="Add condition group"
            className={styles.button}
          />
        </div>
        <p className={styles.then}>Default node action</p>
        <ActionSelection
          action={workingNode?.defaultAction}
          onActionUpdate={(a) => handleDefaultActionUpdate(a)}
          onFocus={(input) => setFocusedInput(input)}
          focusedInput={focusedInput ?? null}
          onDelete={handleDefaultActionDelete}
          notActionConfiguredText={`No default action is configured; 
          if no condition groups are passed, then fulfillment options will 
          automatically pass to the next node.`}
          fulfillers={fulfillers}
          fulfillmentExpectations={fulfillmentExpectations}
        />
      </Drawer>
    </div>
  );
}
