import _ from 'lodash';
import { v4 as uuid } from 'uuid';

import { CONDITION_CONFIGURATIONS } from '../constants/conditionConfigurations';
import {
  Action,
  IfThen,
  IfThenRuleCondition,
  IfThenRuleConditionWithId,
  IfThenWithConditionIds,
  RoutingConfigurationV3,
  WorkingIfThen,
  WorkingRoutingConfigurationNode,
  WorkingRoutingConfigurationV3,
} from '../types';
import { matchValuePathToFulfillmentOptionsBasedConfiguration } from './conversions';
import { parseConditionJoiner } from './parseConditionJoiner';

export function hydrateRoutingConfiguration(
  rc: RoutingConfigurationV3 | null,
): WorkingRoutingConfigurationV3 | null {
  if (!rc) {
    return null;
  }

  const nodes = _.map(rc.nodes, (node) => hydrateNode(rc, node.id));

  const hydratedRc: any = {
    ...rc,
    nodes,
  };

  // These get repopulated when we update the backend anyway;
  // They are never read or edited here, so they should not be in the object.
  // If/when we switch over to Redux, we will need to keep these, as they
  // will live in top-level state rather than only inside Nodes.
  delete hydratedRc.ifThens;

  // We don't need to preserve this; if we update the config,
  // we will just set it to true anyway.
  delete hydratedRc.nativeV3;

  return hydratedRc;
}

export function hydrateNode(
  rc: RoutingConfigurationV3,
  nodeId: string,
): WorkingRoutingConfigurationNode {
  const node = _.find(rc.nodes, (n) => n.id === nodeId);
  if (!node) {
    throw new Error(`Could not find node with id ${nodeId}`);
  }

  // Every ifThen should exist if its ID is referenced,
  // so this is really just a .map that throws explicit error
  const ifThens = _.reduce(
    node.ifThenIds ?? [],
    (ifThenArray, ifThenId) => {
      const ifThen = _.find(rc.ifThens, (it) => it.id === ifThenId);
      if (ifThenId && !ifThen) {
        throw new Error(`Could not find IfThen (Condition Group) with id ${ifThenId}`);
      }
      ifThenArray.push(normalizeIfThenForUI(ifThen!));
      return ifThenArray;
    },
    [] as WorkingIfThen[],
  );

  // Some ifThens may have no associated action, so this is a true reduce (i.e. map + filter)
  // instead of just a map
  const actionIds = _.reduce(
    ifThens,
    (actionIdArray, ifThen) => {
      if (ifThen.actionId) {
        actionIdArray.push(ifThen.actionId!);
      }
      return actionIdArray;
    },
    [] as string[],
  );

  // add the node's default action ID if present
  if (node.defaultActionId) {
    actionIds.push(node.defaultActionId);
  }

  const actions = _.reduce(
    actionIds,
    (actionArray, actionId) => {
      const action = _.find(rc.actions, (a) => a.id === actionId);
      if (actionId && !action) {
        throw new Error(`Could not find Action with id ${actionId}`);
      }
      actionArray.push(action!);
      return actionArray;
    },
    [] as Action[],
  );

  _.forEach(ifThens, (ifThen) => {
    ifThen.action = _.find(actions, (action) => action.id === ifThen.actionId);
  });

  return {
    ...node,
    ifThens,
    defaultAction: _.find(actions, (action) => action.id === node.defaultActionId),
  };
}

export function normalizeIfThenForUI(ifThen: IfThen): IfThenWithConditionIds {
  const joiner = parseConditionJoiner(ifThen);
  return {
    ...ifThen,
    rule: {
      ...ifThen.rule,
      conditions: {
        [joiner]: ifThen.rule.conditions![joiner]!.map((condition) =>
          normalizeConditionForUi(condition),
        ),
      },
    },
  };
}

export function normalizeConditionForUi(condition: IfThenRuleCondition): IfThenRuleConditionWithId {
  // Recognize and parse any custom source/path, and add IDs to conditions

  let newCondition: IfThenRuleConditionWithId = {
    ..._.cloneDeep(condition),
    id: (condition as any).id ?? uuid(),
  };

  const matchingConditionConfig = matchNonCustomConditionToConfig(
    newCondition.fact,
    newCondition.path,
  );
  if (!matchingConditionConfig) {
    const [customSource, ...rest] = _.split(newCondition.path ?? '', '.');
    const customPath = _.join(rest, '.');
    newCondition.customSource = customSource;
    newCondition.customPath = customPath;
  }

  return newCondition;
}

export function matchNonCustomConditionToConfig(fact?: string, path?: string, value?: any) {
  if (!fact && !path && !value?.path) {
    return undefined;
  }
  if (path) {
    return _.find(
      Object.values(CONDITION_CONFIGURATIONS),
      (attribute) => path === attribute.path && fact === attribute.fact,
    );
  }
  if (value?.path) {
    return matchValuePathToFulfillmentOptionsBasedConfiguration(value);
  }
}
