/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import IconAddCircle from '@cimpress-technology/react-streamline-icons/lib/IconAddCircle';
import { Button } from '@cimpress/react-components';

export default function PlusIconButton({
  onClick,
  disabled,
}: {
  onClick: (...args) => void;
  disabled: boolean;
}) {
  return (
    <Button
      css={buttonStyles}
      variant="link"
      onClick={onClick}
      icon={<IconAddCircle size="lg" />}
      disabled={disabled}
    ></Button>
  );
}

const buttonStyles = css`
  height: 44px;
`;
