import {
  Attribute, AttributeType, IAttribute, NumericValueConfiguration, PivotAttribute, StringValueConfiguration,
} from '@cimpress-technology/attribute-model-explorer';
import { get } from 'lodash';
import React from 'react';

import AttributeRangeHelper from '../../../AttributeRangeHelper';
import { IAttributeCustomization, IAttributeSpecifications } from '../../../Models/ICustomizations';
import { MAX_OPTIONS_IN_DROPDOWN } from '../../constants';
import { IAttributeStateTooltipConfiguration } from '../../Interfaces';
import { IValueLabelMap } from '../IInputProps';
import NumberInput from '../NumberInput';
import NumberMultiInput from '../NumberMultiInput';
import NumberSelectInput from '../NumberSelectInput';
import StringButtonInput from '../StringButtonInput';
import StringMultiInput from '../StringMultiInput';
import StringSelectInput from '../StringSelectInput';

type IOnInputChange = (attributeKey: string, value: string | string[]) => void;

/**
 * Generator that takes in an Attribute and based on a test functions determines which
 * Input component to use to represent that Attribute.
 */
export default class InputFactory {
  public static createInput(
    attribute: IAttribute,
    onInputChange: IOnInputChange,
    attributeStateTooltipConfiguration: IAttributeStateTooltipConfiguration,
    label: string | JSX.Element | JSX.Element[],
    valueLabelMap: IValueLabelMap,
    styleClasses: any,
    resourceAttributeIcon?: React.ComponentType<any>,
    attributeSpecifications?: IAttributeSpecifications,
    allowDisabledSelection?: boolean,
    isColorSwatch?: boolean,
  ): JSX.Element | undefined {
    const { getPivotInputComponent, getInputComponent } = InputFactory;

    if (attribute instanceof Attribute) {
      return getInputComponent(
        attribute,
        label,
        valueLabelMap,
        onInputChange,
        attributeStateTooltipConfiguration,
        styleClasses,
        resourceAttributeIcon,
        attributeSpecifications ? attributeSpecifications[attribute.key] : undefined,
        allowDisabledSelection,
        isColorSwatch,
      );
    }

    if (attribute instanceof PivotAttribute) {
      return getPivotInputComponent(attribute, label, valueLabelMap, onInputChange, resourceAttributeIcon, allowDisabledSelection);
    }

    throw new TypeError(`Attribute type "${typeof attribute}" has no corresponding input component.`);
  }

  private static getInputComponent(
    attribute: Attribute,
    label: string | JSX.Element | JSX.Element[],
    valueLabelMap: IValueLabelMap,
    onInputChange: IOnInputChange,
    attributeStateTooltipConfiguration: IAttributeStateTooltipConfiguration,
    styleClasses: string[],
    resourceAttributeIcon?: React.ComponentType<any>,
    attributeCustomization?: IAttributeCustomization,
    allowDisabledSelection?: boolean,
    isColorSwatch?: boolean,
  ): JSX.Element {
    // tslint:disable-next-line: variable-name
    let InputComponent;

    if (attribute.type === AttributeType.Numeric && InputFactory.canBeDropDown(attribute.values, attribute.key)) {
      InputComponent = NumberSelectInput;
    } else if (attribute.type === AttributeType.Numeric) {
      InputComponent = NumberInput;
    } else if (
      attribute.type === AttributeType.String &&
      InputFactory.canBeButtons(attribute.values, attributeCustomization)
    ) {
      InputComponent = StringButtonInput;
    } else if (attribute.type === AttributeType.String) {
      InputComponent = StringSelectInput;
    } else {
      throw new TypeError(`Cannot create Input Component for Attribute with type ${attribute.type}.`);
    }

    return (
      <InputComponent
        attributeKey={attribute.key}
        label={label}
        resolvedValue={attribute.getResolvedValue()}
        onInputChange={onInputChange}
        attributeStateTooltipConfiguration={attributeStateTooltipConfiguration}
        resourceAttributeIcon={resourceAttributeIcon}
        attributeCustomization={attributeCustomization}
        valueLabelMap={valueLabelMap}
        styleClasses={styleClasses}
        allowDisabledSelection={allowDisabledSelection}
        isColorSwatch={isColorSwatch}
        {...attribute}
      />
    );
  }

  private static getPivotInputComponent(
    attribute: PivotAttribute,
    label: string | JSX.Element | JSX.Element[],
    valueLabelMap: IValueLabelMap,
    onInputChange: IOnInputChange,
    resourceAttributeIcon?: React.ComponentType<any>,
    allowDisabledSelection?: boolean,
  ): JSX.Element {
    // tslint:disable-next-line: variable-name
    let InputComponent;
    if (attribute.type === AttributeType.String) {
      InputComponent = StringMultiInput;
    } else if (attribute.type === AttributeType.Numeric) {
      InputComponent = NumberMultiInput;
    } else {
      throw new TypeError(`Cannot create PivotInput Component for PivotAttribute with type ${attribute.type}.`);
    }

    return (
      <InputComponent
        attributeKey={attribute.key}
        label={label}
        valueLabelMap={valueLabelMap}
        onInputChange={onInputChange}
        resourceAttributeIcon={resourceAttributeIcon}
        allowDisabledSelection={allowDisabledSelection}
        {...attribute}
      />
    );
  }

  /**
   * Determine whether the value array should be displayed as buttons or an input dropdown.
   * Note: The constant values here are arbitrary based on past UX decisions.
   * @param values - The Values to test.
   * @param attributeCustomization - Customizations requested by the user
   * @returns True if the Values can be represented as buttons, otherwise false.
   */
  private static canBeButtons(
    values: StringValueConfiguration[],
    attributeCustomization?: IAttributeCustomization,
  ): boolean {
    const MAX_BUTTONS = 5;
    const MAX_TOTAL_STRING_LENGTH = 40;
    let hasImages: boolean = false;

    const valueStrings = values.map(value => value.stringLiteral);

    const items = attributeCustomization ? Object.values(attributeCustomization) : [];

    if (items.length > 0) {
      items.forEach(key => {
        for (const value in key) {
          if (get(key[value], 'imageUrl', undefined)) {
            hasImages = true;
            break;
          }
        }
      });
    }

    return (
      !hasImages &&
      valueStrings.length <= MAX_BUTTONS &&
      valueStrings.reduce((a, b) => a + b).length < MAX_TOTAL_STRING_LENGTH
    );
  }

  private static canBeDropDown(values: NumericValueConfiguration[], attributeKey: string): boolean {
    const validOptions = AttributeRangeHelper.getValidValuesFromRanges(values, MAX_OPTIONS_IN_DROPDOWN, attributeKey);
    return validOptions.length > 0;
  }
}
