// @ts-ignore
import { Spinner } from '@cimpress/react-components';
import { forEach, isEqual } from 'lodash';
import React, { Component } from 'react';
import { IAttributeConfigurationMap } from '../Models';
import ConfigurationServiceClient from '../Services/ConfigurationServiceClient';
import RuleServiceClient from '../Services/RuleServiceClient';
import Utils from '../Utils';
import { IGenericSelectorProps } from './Interfaces';

interface ICustomRequiredProps {
  customRequiredAttributes: string[];
  forwardedRef: any;
}

interface ICustomRequiredState {
  productConfiguration?: { ruleSet: any; attributeConfigurations: IAttributeConfigurationMap };
}

/**
 * An HOC to apply a set of custom required attributes to the Generic Selector
 * @param WrappedComponent
 */
// tslint:disable-next-line: variable-name
export function customRequired(WrappedComponent: React.ComponentType<IGenericSelectorProps>) {
  class CustomRequired extends Component<IGenericSelectorProps & ICustomRequiredProps, ICustomRequiredState> {
    public static displayName: string;

    constructor(props: IGenericSelectorProps & ICustomRequiredProps) {
      super(props);

      this.state = {};
    }

    public async componentDidMount() {
      await this.getProductConfiguration();
    }

    public async componentDidUpdate(prevProps: IGenericSelectorProps & ICustomRequiredProps) {
      if (!isEqual(this.props, prevProps)) {
        await this.getProductConfiguration();
      }
    }

    public render() {
      const { authToken, forwardedRef, onChange, onError, onReset } = this.props;
      const { productConfiguration } = this.state;

      if (productConfiguration === undefined) {
        return <Spinner />;
      }

      return (
        <WrappedComponent
          // @ts-ignore
          ref={forwardedRef}
          product={productConfiguration.ruleSet}
          authToken={authToken}
          onChange={onChange}
          onError={onError}
          onReset={onReset}
          attributeConfigurations={productConfiguration.attributeConfigurations}
        />
      );
    }

    /**
     * Given the Component Props get the configuration necessary to set up the Product Explorer.
     * Note: The order of preference for getting the rule set is:
     * 1. Using configurationUrl to pull from the Configuration Service
     * 2. Direct rule set
     */
    private async getProductConfiguration(): Promise<void> {
      const { attributeConfigurations, authToken, customRequiredAttributes, configurationUrl, product } = this.props;

      try {
        let finalRuleSet;
        if (configurationUrl) {
          const configurationServiceClient = new ConfigurationServiceClient(authToken);
          const configuration = await configurationServiceClient.getConfiguration(configurationUrl);

          if (configuration.referenceId === undefined) {
            throw new Error('Custom Required only supports Rule Set-driven Configuration URLs.');
          }

          const ruleServiceClient = new RuleServiceClient(authToken);
          finalRuleSet = ruleServiceClient.getRuleSet(configuration.referenceId);

          forEach(configuration.attributeSelections, (value, key) => {
            attributeConfigurations[key].initialSelection = value;
          });
        } else if (product) {
          finalRuleSet = product;
        } else {
          throw new Error('Rule set could not be found for the given Props.');
        }

        this.setState({
          productConfiguration: {
            attributeConfigurations,
            ruleSet: Utils.applyCustomRequiredAttributesToV1RuleSet(finalRuleSet, customRequiredAttributes),
          },
        });
      } catch (error) {
        // swallow this for now
      }
    }
  }

  CustomRequired.displayName = 'CustomRequired';

  return React.forwardRef((props, ref) => {
    // @ts-ignore
    return <CustomRequired {...props} forwardedRef={ref} />;
  });
}
