import _ from 'lodash';
import { Moment } from 'moment';
import React, { useEffect, useState } from 'react';

import IconCopy from '@cimpress-technology/react-streamline-icons/lib/IconCopy';
import { Checkbox, Copy, DatePicker } from '@cimpress/react-components';

import { setProductConfigurationUrl } from '../../features/productConfigurationSearch/productConfigurationSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { DisplayValue } from '../../types';
import StyledTextField from '../styledComponents/StyledTextField';
import Tip from '../styledComponents/Tip';
import DottedLine from './DottedLine';
import GenericSelectorWrapper from './GenericSelectorWrapper';
import CountrySelector from './inputs/CountrySelector';
import FulfillmentCapabilitiesSelector from './inputs/FulfillmentCapabilitiesSelector';
import OrderItems, { OrderItem } from './inputs/OrderItems';
import styles from './testerFormFields.module.scss';

export default function TesterFormFields() {
  // TODO - we may have a product config URL from state,
  // if we load from an existing item/order.

  // TODO - if we load from an existing item/order, and no sku matches the
  // skucode for the routing configuration being tested, throw an error.
  const dispatch = useAppDispatch();

  const items = useAppSelector((state) => state.resourceSearch.items);
  const selectedItem = useAppSelector((state) => state.selectedItem.item);
  const createdDate = useAppSelector((state) => state.resourceSearch.order?.createdDate);

  const [orderItems, setOrderItems] = useState<OrderItem[]>(
    items
      ? _.map(items, (item) => ({
          orderedSkuCode: item.orderedSkuCode || item.mcpSku,
          skuCode: item.mcpSku,
          fulfillerId: _.get(item, '_links.fulfiller.name', ''),
        }))
      : [],
  );

  const [orderItemsAreValid, setOrderItemsAreValid] = useState<boolean>(!!items?.length);
  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);

  const productConfigurationUrl = useAppSelector(
    (state) => state.productConfiguration.productConfigurationUrl,
  );

  const [orderDate, setOrderDate] = useState<string | Moment>(
    createdDate || selectedItem
      ? _.get(selectedItem, 'statuses.platformAccepted.updatedDate', '')
      : '',
  );

  const [promisedArrivalDate, setPromisedArrivalDate] = useState<string | Moment>(
    selectedItem ? selectedItem.localPromisedArrivalDate || '' : '',
  );
  const [country, setCountry] = useState<string | null>(
    selectedItem ? selectedItem.destinationAddress?.country || null : null,
  );
  const [postalCode, setPostalCode] = useState<string>(
    selectedItem ? selectedItem.destinationAddress?.postalCode || '' : '',
  );
  const [isPoBox, setIsPoBox] = useState<boolean>(
    selectedItem ? selectedItem.destinationAddress?.isPOBox || false : false,
  );
  const [usingPickupPoint, setUsingPickupPoint] = useState<boolean>(false);
  const [fulfillmentCaps, setFulfillmentCaps] = useState<DisplayValue<string>[]>(
    selectedItem?.fulfillmentCapabilities
      ? selectedItem.fulfillmentCapabilities.map((cap) => ({
          label: cap,
          value: cap,
        }))
      : [],
  );

  useEffect(() => {
    if (selectedItem) {
      setOrderDate(_.get(selectedItem, 'statuses.platformAccepted.updatedDate', ''));
      setPromisedArrivalDate(selectedItem.localPromisedArrivalDate || '');
      const destinationAddress = selectedItem?.deliveryRequest?.destinationAddress;
      setCountry(destinationAddress?.country || null);
      setPostalCode(destinationAddress?.postalCode || '');
      setIsPoBox(destinationAddress?.isPOBox || false);
      if (selectedItem.fulfillmentCapabilities) {
        setFulfillmentCaps(
          selectedItem.fulfillmentCapabilities.map((cap) => ({
            label: cap,
            value: cap,
          })),
        );
      }
      dispatch(setProductConfigurationUrl(selectedItem.productConfigurationUrl));
    }
  }, [selectedItem?.itemId]);

  useEffect(() => {
    setOrderItems(
      items
        ? _.map(items, (item) => ({
            orderedSkuCode: item.orderedSkuCode || item.mcpSku,
            skuCode: item.mcpSku,
            fulfillerId: item.globalFulfillerId,
          }))
        : [],
    );
    setOrderItemsAreValid(true);
  }, [items]);

  return (
    <div>
      <h3 className={styles.semiBold}>Order Criteria</h3>
      <h4 className={styles.semiBold}>Product Information</h4>
      <StyledTextField
        value={skuCode}
        label="SKU"
        className={styles.skuCodeInput}
        onChange={() => {}}
        disabled
      />
      <GenericSelectorWrapper productId={skuCode} />

      {productConfigurationUrl && (
        <div className={styles.container}>
          <StyledTextField
            className={styles.productConfigUrlTextbox}
            value={productConfigurationUrl}
            label="Product Configuration URL"
            onChange={() => {}}
            disabled
          />
          <Copy className={styles.copyButton} variant="button" value={productConfigurationUrl}>
            <IconCopy size="lg" />
          </Copy>
        </div>
      )}

      <div>
        <h4 className={styles.semiBold}>Production Information</h4>
        <div>
          <DatePicker label="Order date" value={orderDate} onChange={setOrderDate} isClearable />
        </div>
        <div>
          <DatePicker
            label="Promised arrival date"
            value={promisedArrivalDate}
            onChange={setPromisedArrivalDate}
            isClearable
          />
        </div>

        <h4 className={styles.semiBold}>Shipping Information</h4>
        <p className={styles.destinationWarning}>
          Please note that if you change the delivery destination, the tool cannot verify that the
          fulfillment options can be delivered to the new destination.
        </p>
        <CountrySelector value={country} onChange={setCountry} isDisabled={usingPickupPoint} />
        <StyledTextField
          value={postalCode}
          label="Postal Code"
          onChange={(e) => setPostalCode(e.target.value)}
          disabled={usingPickupPoint}
        />

        <Checkbox
          checked={isPoBox}
          label="PO Box"
          onChange={(e, payload) => setIsPoBox(payload)}
          disabled={usingPickupPoint}
          className={styles.poBox}
        />
      </div>

      <div>
        <DottedLine />
        <h4 className={`${styles.header} ${styles.semiBold}`}> Or </h4>
        <DottedLine />
      </div>
      <Checkbox
        checked={usingPickupPoint}
        label="Is pickup point"
        onChange={(e) => setUsingPickupPoint(e.target.checked)}
        className={styles.poBox}
      />

      <h4 className={styles.semiBold}>Capabilities</h4>
      <FulfillmentCapabilitiesSelector value={fulfillmentCaps} onChange={setFulfillmentCaps} />

      <h3 className={styles.semiBold}>Order Metadata</h3>
      <Tip>
        <p>These represent other items on the order.</p>
        <p>
          To represent an item that was ordered but has not been routed, enter an OrderedSku but no
          Routed SKU or Fulfiller ID.
        </p>
        <p>
          To represent an item that has already been routed, enter an OrderedSku and one (or both)
          of Routed SKU and Fulfiller ID.
        </p>
        <p>
          If you load an existing item from an order, these will be populated automatically with the
          items found on that order.
        </p>
      </Tip>
      <OrderItems
        orderItems={orderItems}
        setOrderItems={setOrderItems}
        isValid={orderItemsAreValid}
        setIsValid={setOrderItemsAreValid}
      />
    </div>
  );
}
