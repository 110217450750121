import { Button, Tooltip } from '@cimpress/react-components';
import React from 'react';

interface IProps {
  resetExplorer: () => void;
  showTooltip: boolean;
  styleClass: string;
}

// tslint:disable-next-line: variable-name
const ResetAll = ({ resetExplorer, showTooltip, styleClass }: IProps) => (
  <div id="reset-selector-div" style={{ height: '30px' }}>
    <Tooltip
      className="toolTip as-reset-all-tooltip"
      show={showTooltip}
      contents="Click here to reset your selections."
    >
      <Button
        style={{ padding: '0', textDecoration: 'underline', fontWeight: 'bold', border: 'none' }}
        className={`buttonClass ${styleClass}`}
        // tslint:disable-next-line: jsx-no-lambda
        onClick={() => resetExplorer()}
      >
        Reset All
      </Button>
    </Tooltip>
  </div>
);

export default ResetAll;
