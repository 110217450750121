import { Environment } from '../features/resourceSearch/resourceSearchSlice';
import { Link } from '../types';
import fetchWithAuth from '../utils/fetchWithAuth';

export type OrderRequestItem = {
  itemId: string;
  skuCode: string;
  _links: {
    productConfiguration: Link;
  };
};

type OrderRequestResponse = {
  body: OrderRequestItem[];
};

export const getOrderRequestItems = async (
  orderId: string,
  env: Environment,
): Promise<OrderRequestItem[]> => {
  const url =
    env === 'prod'
      ? 'https://order-request.commerce.cimpress.io'
      : 'https://int-order-request.commerce.cimpress.io';
  const response = await fetchWithAuth<OrderRequestResponse>({
    endpointUrl: url,
    route: `v1/orders/${orderId}/items`,
    giveSimpleResponse: false,
  });
  return response.body;
};
