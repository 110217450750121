import _ from 'lodash';

import { UiDictionaryValue } from '../types';

export function addIndexesToDictionaryValues(dictionary: Record<string, any>) {
  let maxIndex = -1;
  let keysWithoutIndexes: string[] = [];

  const dictWithIndexes: Record<string, UiDictionaryValue> = {};

  _.forEach(_.entries(dictionary), ([key, value]) => {
    if (_.isUndefined(value?.index)) {
      keysWithoutIndexes.push(key);
    } else {
      maxIndex = _.max([maxIndex, value.index]);
      dictWithIndexes[key] = value;
    }
  });

  _.forEach(keysWithoutIndexes, (key, localIndex) => {
    const index = localIndex + maxIndex + 1;
    dictWithIndexes[key] = {
      value: dictionary[key],
      index,
    };
  });

  return dictWithIndexes;
}
