import _ from 'lodash';

import { getAccessToken } from '../auth';

export type FulfillerData = {
  email: string;
  fulfillerId: string;
  internalFulfillerId: string;
  language: string;
  links: Record<string, { href: string; rel: string }>[];
  modifiedAt: string;
  name: string;
  phone: string;
};

const constructHeaders = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
  'Content-Type': 'application/json',
});

export const getFulfillers = async () => {
  const params = {
    method: 'get',
    headers: constructHeaders(),
  };
  const url = `https://${process.env.REACT_APP_FULFILLER_IDENTITY_HOST}/v1/fulfillers`;
  const response = await fetch(url, params);
  if (response.ok) {
    const data: FulfillerData[] = await response.json();
    return _.keyBy(data, 'fulfillerId') as Record<string, FulfillerData>;
  }
  throw new Error(`Error retrieving fulfillers, received status code ${response.status}`);
};
