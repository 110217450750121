/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import provinces from 'provinces-ca';
import React from 'react';
import states from 'states-us';

import { DisplayValue } from '../../types';
import TrashIconButton from '../styledComponents/TrashIconButton';
import CountrySelect from './CountrySelect';
import GeocodeStateSelect, { GeocodeCreatableStateSelect } from './GeocodeStateSelect';
import { GeocodeCriteria } from './types';

const usStates = _(states)
  .filter((s) => !s.territory)
  .map((s) => ({ label: s.name, value: s.abbreviation }))
  .value();

const canadaProvinces = _(provinces)
  .filter((s) => !s.territory)
  .map((s) => ({ label: s.name, value: s.abbreviation }))
  .value();

export default function GeocodeRow({
  conditionId,
  index,
  value,
  onChange,
  onFocus,
  focusedId,
  onDelete,
}: {
  conditionId: string;
  index: number;
  value: GeocodeCriteria;
  onChange: (g: GeocodeCriteria) => void;
  onFocus: (s: string | null) => void;
  focusedId: string | null;
  onDelete: (index: number) => void;
}) {
  const { country, stateOrProvinceCodes = [] } = value;
  const stateFocusId = `${conditionId}-geocode-${index}-state`;

  const handleCountryChange = (selection: DisplayValue<string>) => {
    onChange({ country: selection?.value, stateOrProvinceCodes });
  };

  // When a new selection is made, the entire array of selections is returned.
  // Any new selections will be DisplayValues; the old ones will be strings.
  const handleStateChange = (allSelections: (DisplayValue<string> | string)[]) => {
    const codes = _.map(allSelections, (selection) => {
      if (_.has(selection, 'value')) {
        return (selection as DisplayValue<string>).value;
      }

      return selection as string;
    });
    onChange({ country, stateOrProvinceCodes: codes });
  };

  return (
    <div css={geocodeFlexRow} key={`geocode-row-${conditionId}-${index}`}>
      <div css={subRow}>
        <CountrySelect value={country} onChange={handleCountryChange} />
        {country === 'US' && (
          <GeocodeStateSelect
            value={stateOrProvinceCodes}
            label="State or province codes"
            options={usStates}
            onChange={handleStateChange}
            onFocus={() => onFocus(stateFocusId)}
            autoFocus={focusedId === stateFocusId}
            inputMode="text"
          />
        )}
        {country === 'CA' && (
          <GeocodeStateSelect
            value={stateOrProvinceCodes}
            label="State or province codes"
            options={canadaProvinces}
            onChange={handleStateChange}
            onFocus={() => onFocus(stateFocusId)}
            autoFocus={focusedId === stateFocusId}
            inputMode="text"
          />
        )}
        {!['US', 'CA'].includes(country) && (
          <GeocodeCreatableStateSelect
            value={stateOrProvinceCodes}
            label="State or province codes"
            options={[]}
            onChange={handleStateChange}
            onFocus={() => onFocus(stateFocusId)}
            autoFocus={focusedId === stateFocusId}
            inputMode="text"
          />
        )}
      </div>

      <TrashIconButton onDelete={onDelete} />
    </div>
  );
}

const geocodeFlexRow = css`
  display: flex;
  height: auto;
  justify-content: space-between;
`;

const subRow = css`
  display: flex;
  height: auto;
`;
