import { NumericValueConfiguration, ValueType } from '@cimpress-technology/attribute-model-explorer';
import { TextField } from '@cimpress/react-components';
import { debounce, get, isEqual } from 'lodash';
import React, { Component } from 'react';
import AttributeRangeHelper from '../../AttributeRangeHelper';
import { MetadataKeys } from '../../Models';
import { AMEXMetadata } from '../constants';
import { IInputProps } from './';
import AttributeInputContainer from './AttributeInputContainer';
import NumberTooltip from './NumberTooltip';
import ResourceAttributeIconWithLabel from './ResourceAttributeIconWithLabel';
import ValueStateTooltip from './ValueStateTooltip';

interface IState {
  value: string;
  tooltipVisible: boolean;
}

const DEBOUNCE_WAIT_IN_MS = 500;
const NUMBER_TEST_REGEX = new RegExp(/^\d*(\.\d+)?$/);

/**
 * Default Input Component for Range Attributes
 */
export default class NumberInput extends Component<IInputProps, IState> {
  public static defaultProps = {
    attributeStateTooltipConfiguration: {},
    resolvedValue: '',
  };

  private readonly emitChange = debounce(() => {
    const { values, validValues, allowDisabledSelection } = this.props;
    const isValidValuesWithinRange = this.isValueWithinRange(validValues);
    const isValuesWithinRange = this.isValueWithinRange(values);

    if (allowDisabledSelection && !isValidValuesWithinRange && isValuesWithinRange) {
      this.props.onInputChange(this.props.attributeKey, this.state.value, true);
    } else {
      this.props.onInputChange(this.props.attributeKey, this.state.value);
    }
  }, DEBOUNCE_WAIT_IN_MS);

  constructor(props: IInputProps) {
    super(props);

    const { resolvedValue } = this.props;

    this.state = {
      tooltipVisible: false,
      value: resolvedValue,
    };

    this.onChange = this.onChange.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: any) {
    const { allowDisabledSelection } = this.props;

    if (
      this.props.resolvedValue !== prevProps.resolvedValue ||
      (allowDisabledSelection && this.isDisabledSelectionReverted(prevState))
    ) {
      this.setState({ value: this.props.resolvedValue });
    }
  }

  public render() {
    const {
      allowDisabledSelection,
      attributeKey,
      label,
      getValueState,
      isRequired,
      isValid,
      validValues,
      type,
      metadata,
      attributeStateTooltipConfiguration,
      resourceAttributeIcon,
      styleClasses,
    } = this.props;
    const { value, tooltipVisible } = this.state;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    const unitOfMeasure: string =
      metadata && metadata.get(MetadataKeys.unitOfMeasure) ? metadata.get(MetadataKeys.unitOfMeasure) : '';

    const tooltipUOM = unitOfMeasure ? `<br/>${attributeKey} (${unitOfMeasure})` : '';
    const validClass =
      allowDisabledSelection && metadata && metadata.get(AMEXMetadata.isSelectionUpdatedByInvalidSelection)
        ? 'warning'
        : '';

    const bsStyle: any = isValid ? validClass : 'error';

    return (
      <AttributeInputContainer
        isDisabledSelectionMode={allowDisabledSelection}
        isValid={allowDisabledSelection && isValid && value}
      >
        <ValueStateTooltip
          direction="top"
          getValueState={getValueState}
          value={value}
          showOnExcluded={attributeStateTooltipConfiguration.showOnExcluded}
          showOnResolved={attributeStateTooltipConfiguration.showOnResolved}
        >
          <NumberTooltip
            showTooltip={tooltipVisible}
            values={validValues}
            type={type}
            additionalTooltipContent={tooltipUOM}
          >
            <div
              // Checking tooltipVisible here just to know if the focus is on the component or not.
              className={
                value || (tooltipVisible && value)
                  ? get(styleClasses, 'textInputWrapperActive', '')
                  : get(styleClasses, 'textInputWrapper', '')
              }
            >
              <TextField
                className="textInputClass"
                onChange={this.onChange}
                name={attributeKey}
                label={displayLabel}
                onFocus={this.showTooltip}
                onBlur={this.hideTooltip}
                value={value}
                required={isRequired}
                status={bsStyle}
              />
            </div>
          </NumberTooltip>
        </ValueStateTooltip>
      </AttributeInputContainer>
    );
  }

  private onChange(e: React.ChangeEvent<HTMLInputElement>) {
    const sanitizedValue = e.target.value.replace(/[^\d.]/g, '');

    this.setState({ value: sanitizedValue }, () => {
      if (this.isValidNumber(sanitizedValue)) {
        /* Disabled selection functionality is added within emitChange().
         * There is no separate emitChangeDisabled() added here to avoid 2 calls at the same time to AMEX (disabled and non disabled)
         */
        this.emitChange();
      }
    });
  }

  private isValueWithinRange(validValues: NumericValueConfiguration[]): boolean {
    const numberLiterals: string[] = [];
    const validRanges: any[] = [];
    const { value } = this.state;

    (validValues || []).forEach(validValue => {
      if (validValue.type === ValueType.NumberLiteral) {
        numberLiterals.push(validValue.numberLiteral);
      }

      if (validValue.type === ValueType.Range) {
        validRanges.push(validValue.range);
      }
    });

    return AttributeRangeHelper.validateValueAgainstRanges(Number(value), validRanges, numberLiterals);
  }

  private showTooltip() {
    this.setState({ tooltipVisible: true });
  }

  private hideTooltip() {
    this.setState({ tooltipVisible: false });
  }

  private isValidNumber(text: string): boolean {
    return text === '' || NUMBER_TEST_REGEX.test(text);
  }

  private isDisabledSelectionReverted(prevState: IState) {
    const { resolvedValue } = this.props;
    const { value: previousStateValue } = prevState;

    // selection must be reverted only when there is a difference between the provided resolved value and value stored in state
    return isEqual(prevState, this.state) && resolvedValue !== previousStateValue;
  }
}
