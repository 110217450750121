import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Tab, TabCard } from '@cimpress/react-components/';

import { setError } from '../features/alerts/alertContentSlice';
import { selectTabByIndex, selectTesterTab } from '../features/tabs/tabSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import EditRoutingConfigurationPage from './EditRoutingConfigurationPage';
import ConfigurationTesterContainer from './configurationTester/ConfigurationTesterContainer';
import styles from './tabContainer.module.scss';

export default function TabContainer() {
  const useTestTab = process.env.REACT_APP_USE_TEST_TAB === 'true';

  const navigate = useNavigate();
  const { accountId } = useParams();

  // These are in Redux state so that we can navigate the user
  // directly to one tab or another depending on where they're coming from
  const selectedTabIndex = useAppSelector((state) => state.tab.selectedTabIndex);
  const dispatch = useAppDispatch();
  const setSelectedIndex = (index: number) => dispatch(selectTabByIndex(index));
  const selectedRoutingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const selectedSkuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));

  const tabs: Tab[] = [
    {
      name: 'Route Builder',
      block: <EditRoutingConfigurationPage />,
    },
    {
      name: 'Route Tester',
      block: <ConfigurationTesterContainer />,
    },
  ];

  const navigateOnTabSelect = (event, index: number) => {
    setSelectedIndex(index);
    if (index === 0) {
      if (selectedRoutingConfiguration?.id && selectedRoutingConfiguration.id !== 'default') {
        navigate(`/accounts/${accountId}/configurations/edit/${selectedRoutingConfiguration.id}`);
      } else if (selectedSkuCode) {
        navigate(`/accounts/${accountId}/configurations/create?skuCode=${selectedSkuCode}`);
      } else {
        dispatchErrors({
          title: 'Navigation Error',
          messages: ['Not enough information; did you use an incomplete URL?'],
        });
        // Not enough information; navigate back to search page
        navigate(`/accounts/${accountId}/configurations`);
      }
    } else {
      navigate(`/accounts/${accountId}/configurations/evaluate`);
    }
  };

  let renderOutput;

  if (useTestTab && accountId) {
    if (selectedRoutingConfiguration) {
      renderOutput = (
        <TabCard
          tabs={tabs}
          className={styles.container}
          selectedIndex={selectedTabIndex}
          onSelect={navigateOnTabSelect}
        />
      );
    } else {
      renderOutput = <ConfigurationTesterContainer />;
      // If we navigated directly to the testing container,
      // we need to make sure that it stays selected once a routing
      // configuration gets selected.
      dispatch(selectTesterTab());
    }
  } else {
    renderOutput = <EditRoutingConfigurationPage />;
  }

  return <div>{renderOutput}</div>;
}
