// @ts-ignore
import { UserMetadata, centralizedAuth } from '@cimpress/simple-auth-wrapper';

export const CLIENT_ID = 'AIENla3sTDFMQ3Fjs6IccXYdyRn7jur9'; // Routing Configuration UI

const auth = new centralizedAuth({
  clientID: CLIENT_ID,
  redirectRoute: '/',
});

export const getAccessToken = () => {
  const userMetadata = new UserMetadata(auth);
  return userMetadata.auth.getAccessToken();
};

export default auth;
