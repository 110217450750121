import _ from 'lodash';
import moment, { Moment } from 'moment';

import { DeliveryConstraints, ItemFulfillmentCapability } from '../services/items';
import { Link, LinkWithName } from '../types';

type Item = {
  // This is on the type in Delivery Calculator
  mcpSku: string;
  destinationAddress?: DestinationAddress;
  deliveryConstraints: DeliveryConstraints;
  fulfillmentCapabilities: ItemFulfillmentCapability[];
  buyerAccountUrl: string;
  productConfigurationUrl: string;
  itemUrl: string;
  requestDate: Moment;

  // Below is what's in ORCA
  merchantId: string;
  itemId: string;
  orderId: string;
  orderedSkuCode: string;
  skuCode: string;
  quantity: number;
  localPromisedArrivalDate: string;
  promisedArrivalDate: string;
  documentReferenceUrl?: string;
  shipmentPickupInformation?: any;
  deliveryConfigurations: DeliveryConfiguration[];
  _links: {
    productConfiguration: Link;
    self: Link;
    routingRequest: LinkWithName;
  };
  statuses?: ItemStatuses;
  customsInformation?: {
    pricePaid?: {
      basePrice: number;
      currencyCode: string;
    };
  };
  deliveryRequest?: DeliveryRequest;
};

type DeliveryRequest = {
  destinationAddress: DestinationAddress;
  _links?: {
    pickupPoint?: {
      href: string;
    };
  };
};

type DestinationAddress = {
  city: string;
  street1?: string;
  street2?: string;
  country: string;
  postalCode?: string;
  stateOrProvince?: string;
  isPOBox?: boolean;
  isResidential?: boolean;
};

type ItemStatuses = {
  [statusName: string]: ItemStatus;
};

type ItemStatus = {
  name: string;
  state: 'notStarted' | 'current' | 'closed';
};

type DeliveryConfiguration = {
  quantity: number;
  deliveryRequestLink: Link;
  isSample: boolean;
};

const defaultItem: Item = {
  mcpSku: 'a',
  destinationAddress: {
    city: 'a',
    street1: 'a',
    street2: 'a',
    country: 'a',
    postalCode: 'a',
    stateOrProvince: 'a',
    isPOBox: true,
    isResidential: true,
  },
  deliveryConstraints: {
    carrierServiceCapabilities: ['a'],
    carrierServices: ['a'],
  },
  fulfillmentCapabilities: ['cashOnDelivery'],
  buyerAccountUrl: 'a',
  productConfigurationUrl: 'a',
  itemUrl: 'a',
  requestDate: moment(),

  merchantId: 'a',
  itemId: 'a',
  orderId: 'a',
  orderedSkuCode: 'a',
  skuCode: 'a',
  quantity: 1,
  localPromisedArrivalDate: 'a',
  promisedArrivalDate: 'a',
  documentReferenceUrl: 'a',
  shipmentPickupInformation: 'a',
  deliveryConfigurations: [
    {
      quantity: 1,
      deliveryRequestLink: { href: 'a' },
      isSample: true,
    },
  ],
  _links: {
    productConfiguration: { href: 'a' },
    self: { href: 'a' },
    routingRequest: { href: 'a', name: 'a' },
  },
  statuses: {},
  customsInformation: {
    pricePaid: {
      basePrice: 1,
      currencyCode: 'a',
    },
  },
  deliveryRequest: {
    destinationAddress: {
      city: 'a',
      street1: 'a',
      street2: 'a',
      country: 'a',
      postalCode: 'a',
      stateOrProvince: 'a',
      isPOBox: true,
    },
    _links: {
      pickupPoint: {
        href: 'a',
      },
    },
  },
};

export type FulfillmentOptionV1 = {
  optionId: string;
  _links: {
    fulfillmentConfiguration?: Link;
    baseFulfillmentConfiguration?: Link;
    productConfiguration?: Link;
    shipmentPlan?: Link[];
    fulfillmentExpectationsFeasibility?: Link;
  };
  _embedded: {
    shipmentPlan?: ShipmentPlan[];
    deliveryFeasibility?: {
      isApplicable?: boolean;
      isDeliverable?: boolean;
      isProducible?: boolean;
      isPriceable?: boolean;
      lateArrival?: boolean;
      inventoryAvailable?: boolean;
      cost?: { shipping: TotalAmount };
      price?: {
        product?: TotalAmount;
        shipping?: TotalAmount;
        combined?: TotalAmount;
      };
      _links?: {
        fulfillmentConfiguration: Link;
        item: Link;
        productConfiguration?: Link;
        shipmentPlans?: Link[];
      }[];
    };
  };
  isValid: boolean;

  problem?: Problem;
  capacityAvailability?: {
    isCapacityConfigured: boolean;
    hasAvailableCapacity: boolean;
    metMinCapacity: boolean;
    errorCalculatingCapacity: boolean;
  };
  // reason?: string; // How is this used?
  mcpSku?: string;
  isOutOfStock?: boolean;
  priceIncludingCostOfDelay?: string | number;
  priceIncludingAssumedInHouseCost?: string | number;
  fulfillerId: string;
  marginFilter?: {
    hasMarginFilter: boolean;
    passedMarginFilter: boolean;
  };
  modifiedPrices?: ModifiedPrices;
};

export type ShipmentPlan = {
  estimatedArrivalDate: string;
  _embedded?: object;
};

export type ModifiedPrices = {
  combined: TotalAmount;
  product: TotalAmount;
  shipping: TotalAmount;
};

export type TotalAmount = {
  currency: string;
  amount: number;
};

export type Problem = {
  title: string;
  detail: string;
  type?: string;
  causes?: Cause[];
};

export type Cause = {
  title: string;
  detail: string;
  type?: string;
};

const defaultOption: FulfillmentOptionV1 = {
  optionId: '',
  _links: {
    fulfillmentConfiguration: { href: '' },
    baseFulfillmentConfiguration: { href: '' },
    productConfiguration: { href: '' },
    shipmentPlan: [{ href: '' }],
    fulfillmentExpectationsFeasibility: { href: '' },
  },
  _embedded: {
    shipmentPlan: [
      {
        estimatedArrivalDate: '',
        _embedded: {},
      },
    ],
    deliveryFeasibility: {
      isApplicable: true,
      isDeliverable: true,
      isProducible: true,
      isPriceable: true,
      lateArrival: true,
      inventoryAvailable: true,
      cost: {
        shipping: {
          currency: 'USD',
          amount: 123,
        },
      },
      price: {
        product: {
          currency: 'USD',
          amount: 123,
        },
        shipping: {
          currency: 'USD',
          amount: 123,
        },
        combined: {
          currency: 'USD',
          amount: 123,
        },
      },
      _links: [
        {
          fulfillmentConfiguration: { href: '' },
          item: { href: '' },
          productConfiguration: { href: '' },
          shipmentPlans: [{ href: '' }],
        },
      ],
    },
  },
  isValid: true,
  problem: {
    title: '',
    detail: '',
    type: '',
    causes: [
      {
        title: '',
        detail: '',
        type: '',
      },
    ],
  },
  capacityAvailability: {
    isCapacityConfigured: true,
    hasAvailableCapacity: true,
    metMinCapacity: true,
    errorCalculatingCapacity: true,
  },
  mcpSku: '',
  isOutOfStock: true,
  priceIncludingCostOfDelay: 123,
  priceIncludingAssumedInHouseCost: 123,
  fulfillerId: '',
  marginFilter: {
    hasMarginFilter: true,
    passedMarginFilter: true,
  },
  modifiedPrices: {
    combined: {
      currency: 'USD',
      amount: 123,
    },
    product: {
      currency: 'USD',
      amount: 123,
    },
    shipping: {
      currency: 'USD',
      amount: 123,
    },
  },
};

export function isValidItemPath(path?: string | null) {
  return path ? _.has(defaultItem, path) : false;
}

export function isValidOptionPath(path?: string | null) {
  return path ? _.has(defaultOption, path) : false;
}
