/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useContext } from 'react';

import { IfThenContext } from '../../context/IfThenContext';
import { DisplayValue } from '../../types';
import PlusIconButton from '../styledComponents/PlusIconButton';
import IfThenCheckRow from './ifThenCheckRow';

const defaultValue: DisplayValue<string> = {
  label: '',
  value: '',
};

export default function IfThenCheckTable({
  conditionId,
  selectedIfThenIds,
  ifThenIdsWithinNode,
  onChange,
}: {
  conditionId: string;
  selectedIfThenIds: string[];
  ifThenIdsWithinNode: string[];
  onChange: (updatedValue: string[]) => void;
}) {
  const { ifThenMap } = useContext(IfThenContext);

  const unusedIfThens = _.filter(
    Object.values(ifThenMap),
    ({ value: ifThenId }) =>
      _.indexOf(selectedIfThenIds, ifThenId) === -1 &&
      _.indexOf(ifThenIdsWithinNode, ifThenId) === -1,
  ) as DisplayValue<string>[];

  const handleChange = (updatedSelection: DisplayValue<string>, index: number) => {
    const updatedIfThenIds = [...selectedIfThenIds];
    updatedIfThenIds[index] = updatedSelection.value;
    onChange(updatedIfThenIds);
  };

  const handleDelete = (index: number) => {
    const updatedIfThenIds = [...selectedIfThenIds];
    updatedIfThenIds.splice(index, 1);
    onChange(updatedIfThenIds);
  };

  const handleAddRow = () => {
    const updatedIfThenIds = [...selectedIfThenIds, ''];
    onChange(updatedIfThenIds);
  };

  return (
    <div css={ifThenCheckFlexTable}>
      {selectedIfThenIds.map((ifThenId, index) => (
        <IfThenCheckRow
          key={`ifThenCheckRow-${index}`}
          conditionId={conditionId}
          index={index}
          options={unusedIfThens}
          value={ifThenMap[ifThenId] || defaultValue}
          onChange={(updated: DisplayValue<string>) => handleChange(updated, index)}
          onDelete={handleDelete}
        />
      ))}
      <div css={plusButton}>
        <PlusIconButton onClick={handleAddRow} disabled={!unusedIfThens.length} />
      </div>
    </div>
  );
}

const ifThenCheckFlexTable = css`
  display: flex;
  flex-direction: column;
  grid-area: value;
  margin-left: 16px;
`;

const plusButton = css`
  grid-area: plus;
  margin-top: -8px;
  margin-bottom: 8px;
`;
