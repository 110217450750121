import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';

import { IAttributeSelectionMap, IProductConfiguration } from '../Models';

const CONFIGURATION_SERVICE_BASE_URL = 'https://configuration.products.cimpress.io';

interface IVariable {
  attributeKey: string;
  attributeValue: string;
}

/**
 * Given a Configuration's variables create an AttributeSelections object.
 * @param variables - Variables returned from the Configuration Service
 * @returns An Attribute Selections object.
 */
function convertVariablesToAttributeSelections(variables: IVariable[] = []): IAttributeSelectionMap {
  return variables.reduce((attributeSelections, variable) => {
    attributeSelections[variable.attributeKey] = variable.attributeValue;

    return attributeSelections;
  }, {});
}

function convertAttributeSelectionsToVariables(attributeSelections: IAttributeSelectionMap): IVariable[] {
  return Object.keys(attributeSelections).map(attributeKey => {
    return { attributeKey, attributeValue: attributeSelections[attributeKey] };
  });
}

/**
 * Client to interact with the Configuration Service
 */
export default class ConfigurationServiceClient {
  /**
   * @property authToken - The OAuth JWT to authenticate requests with.
   */
  private readonly authToken: string;
  private readonly configurationServiceBaseUrl: string;

  constructor(authToken: string, configurationServiceBaseUrl: string = CONFIGURATION_SERVICE_BASE_URL) {
    this.authToken = authToken;
    this.configurationServiceBaseUrl = configurationServiceBaseUrl;
  }

  /**
   * Create a configuration URL for the reference ID using the provided selections.
   * @param mcpSku {string} - MCP's SKU for the product. A reference Id for rule sets products; Product ID for other products.
   * @param version {string | undefined} - The version of the product, if available.
   * @param attributeSelections {IAttributeSelectionMap} - The selected values on the product described as an Object.
   * @throws When there is a network failure, a non-201 response code is received, or a 201 response code arrives without a 'Location' header.
   * @returns A Promise to return the Configuration URL as a string.
   */
  public async createConfigurationUrl(
    mcpSku: string,
    version: string | undefined,
    attributeSelections: IAttributeSelectionMap,
  ): Promise<string> {
    const variables: IVariable[] = convertAttributeSelectionsToVariables(attributeSelections);
    const requestBody = { variables, mcpSku, productVersion: version };

    const response = await fetch(`${this.configurationServiceBaseUrl}/v1/configurations`, {
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.authToken}`,
      },
      method: 'post',
    });

    switch (response.status) {
      case statusCodes.CREATED:
        const locationHeader = response.headers.get('Location');
        if (locationHeader === null) {
          throw new Error(errorAlertMessages.CONFIGURATION_URL.CREATION_ERROR);
        }

        return locationHeader;
      case statusCodes.NOT_FOUND:
        if (version === undefined) {
          throw new Error(`MCP SKU ${mcpSku} was not associated with a product.`);
        } else {
          throw new Error(`MCP SKU ${mcpSku} (version ${version}) was not associated with a product.`);
        }
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(errorAlertMessages.CONFIGURATION_URL.CREATION_ERROR);
    }
  }

  /**
   * Given a Configuration URL get the rule set and set of selections associated
   * @param configurationUrl - The Configuration URL to request.
   * @throws When there is a network failure or a non-200.
   * @returns A Promise to return the IConfiguration.
   */
  public async getConfiguration(configurationUrl: string): Promise<IProductConfiguration> {
    const response = await fetch(configurationUrl, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${this.authToken}`,
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return this.convertResponseToConfiguration(response);
      case statusCodes.NOT_FOUND:
        throw new Error(errorAlertMessages.CONFIGURATION_URL.NOT_FOUND);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`Error fetching configuration with the url ${configurationUrl}`);
    }
  }

  private async convertResponseToConfiguration(configurationResponseBody: Body): Promise<IProductConfiguration> {
    const configuration = await configurationResponseBody.json();

    const attributeSelections = convertVariablesToAttributeSelections(configuration.variables);

    let productConfiguration: IProductConfiguration;
    if (configuration.productVersion === undefined) {
      const referenceId = configuration.mcpFamily ? configuration.mcpFamily : configuration.mcpSku;
      productConfiguration = { attributeSelections, referenceId };
    } else {
      const productId = configuration.mcpSku;
      const { productVersion } = configuration;
      productConfiguration = { attributeSelections, productId, productVersion };
    }

    return productConfiguration;
  }
}
