/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

import { Select, SelectProps } from '@cimpress/react-components';

const StyledOperatorSelect = styled(Select)`
  min-width: 100px;
  max-width: 200px;
`;

const OperatorSelect: React.FC<SelectProps> = (props) => {
  return (
    <div css={operatorWrapperCss}>
      <StyledOperatorSelect {...props} />
    </div>
  );
};

export default OperatorSelect;

const operatorWrapperCss = css`
  grid-area: operator;
`;
