import {
  Attribute, AttributeType, IAttribute, NumericValueConfiguration, PivotAttribute, StringValueConfiguration,
} from '@cimpress-technology/attribute-model-explorer';
import { get, isEmpty } from 'lodash';
import React from 'react';

import { DetailCardGrid, IOnInputChange } from '../';
import { IAttributeCustomization, IAttributeSpecifications } from '../../../Models/ICustomizations';
import { v2ProductAttributeValueTypes } from '../../constants';
import { IAttributeStateTooltipConfiguration } from '../../Interfaces';
import RadioButton from '../DetailedComponents/RadioButton';
import { IValueLabelMap } from '../IInputProps';
import NumberInput from '../NumberInput';
import NumberMultiInput from '../NumberMultiInput';
import StringMultiInput from '../StringMultiInput';
import StringSelectInput from '../StringSelectInput';

/**
 * Generator that takes in an Attribute and based on a test functions determines which
 * Input component to use to represent that Attribute.
 */
export default class DetailedInputFactory {
  public static createInput(
    attribute: IAttribute,
    onInputChange: IOnInputChange,
    attributeStateTooltipConfiguration: IAttributeStateTooltipConfiguration,
    label: string | JSX.Element | JSX.Element[],
    valueLabelMap: IValueLabelMap,
    styleClasses: any,
    resourceAttributeIcon?: React.ComponentType<any>,
    attributeSpecifications?: IAttributeSpecifications,
    allowDisabledSelection?: boolean,
    isColorSwatch?: boolean,
  ): JSX.Element | undefined {
    const { getPivotInputComponent, getInputComponent } = DetailedInputFactory;

    if (attribute instanceof Attribute) {
      return getInputComponent(
        attribute,
        label,
        valueLabelMap,
        onInputChange,
        attributeStateTooltipConfiguration,
        styleClasses,
        resourceAttributeIcon,
        attributeSpecifications ? attributeSpecifications[attribute.key] : undefined,
        allowDisabledSelection,
        isColorSwatch,
      );
    }

    if (attribute instanceof PivotAttribute) {
      return getPivotInputComponent(attribute, label, valueLabelMap, onInputChange, resourceAttributeIcon);
    }

    throw new TypeError(`Attribute type "${typeof attribute}" has no corresponding input component.`);
  }

  private static getInputComponent(
    attribute: Attribute,
    label: string | JSX.Element | JSX.Element[],
    valueLabelMap: IValueLabelMap,
    onInputChange: IOnInputChange,
    attributeStateTooltipConfiguration: IAttributeStateTooltipConfiguration,
    styleClasses: any,
    resourceAttributeIcon?: React.ComponentType<any>,
    attributeCustomization?: IAttributeCustomization,
    allowDisabledSelection?: boolean,
    isColorSwatch?: boolean,
  ): JSX.Element {
    // tslint:disable-next-line: variable-name
    let InputComponent;
    if (DetailedInputFactory.canBeImageButtons(attribute.values, attributeCustomization)) {
      InputComponent = DetailCardGrid;
    } else if (attribute.type === AttributeType.Numeric) {
      InputComponent = NumberInput;
    } else if (attribute.type === AttributeType.String && DetailedInputFactory.canBeRadioButton(attribute.values)) {
      InputComponent = RadioButton;
    } else if (attribute.type === AttributeType.String) {
      InputComponent = StringSelectInput;
    } else {
      throw new TypeError(`Cannot create Input Component for Attribute with type ${attribute.type}.`);
    }

    return (
      <InputComponent
        attributeKey={attribute.key}
        label={label}
        isColorSwatch={isColorSwatch}
        resolvedValue={attribute.getResolvedValue()}
        onInputChange={onInputChange}
        attributeStateTooltipConfiguration={attributeStateTooltipConfiguration}
        resourceAttributeIcon={resourceAttributeIcon}
        attributeCustomization={attributeCustomization}
        valueLabelMap={valueLabelMap}
        styleClasses={styleClasses}
        allowDisabledSelection={allowDisabledSelection}
        {...attribute}
      />
    );
  }

  private static getPivotInputComponent(
    attribute: PivotAttribute,
    label: string | JSX.Element | JSX.Element[],
    valueLabelMap: IValueLabelMap,
    onInputChange: IOnInputChange,
    resourceAttributeIcon?: React.ComponentType<any>,
  ): JSX.Element {
    // tslint:disable-next-line: variable-name
    let InputComponent;
    if (attribute.type === AttributeType.String) {
      InputComponent = StringMultiInput;
    } else if (attribute.type === AttributeType.Numeric) {
      InputComponent = NumberMultiInput;
    } else {
      throw new TypeError(`Cannot create PivotInput Component for PivotAttribute with type ${attribute.type}.`);
    }

    return (
      <InputComponent
        attributeKey={attribute.key}
        label={label}
        onInputChange={onInputChange}
        resourceAttributeIcon={resourceAttributeIcon}
        valueLabelMap={valueLabelMap}
        {...attribute}
      />
    );
  }

  /**
   * Determine whether the value array should be displayed as buttons or an input dropdown.
   * Note: The constant values here are arbitrary based on past UX decisions.
   * @param values - The Values to test.
   * @returns True if the Values can be represented as buttons, otherwise false.
   */
  private static canBeRadioButton(values: StringValueConfiguration[]): boolean {
    const MAX_BUTTONS = 12;

    return values.length <= MAX_BUTTONS;
  }

  /**
   * Determine whether the value array should be displayed as buttons with images or not.
   *
   * @param values - The Values to test.
   * @param attributeCustomization - The customizations for values.
   * @returns True if the Values can be represented as buttons with images, otherwise false.
   */
  private static canBeImageButtons(
    values: StringValueConfiguration[] | NumericValueConfiguration[],
    attributeCustomization?: IAttributeCustomization,
  ): boolean {
    let hasImageConfigured = false;

    if (attributeCustomization) {
      for (const value of values) {
        const type = value.type;
        if (type === v2ProductAttributeValueTypes.RANGE || type === v2ProductAttributeValueTypes.FORMULA) {
          return false;
        }
        if (
          !hasImageConfigured &&
          !isEmpty(get(attributeCustomization, `attributeValues.${[value[type]]}.imageUrl`, null))
        ) {
          hasImageConfigured = true;
        }
      }
    }

    return hasImageConfigured;
  }
}
