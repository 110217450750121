/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button, Modal } from '@cimpress/react-components';

export default function ConfirmDeleteModal({
  showModal,
  onRequestHide,
  onConfirmDelete,
  nodeIsReachable,
}: {
  showModal: boolean;
  onRequestHide: () => void;
  onConfirmDelete: () => void;
  nodeIsReachable: boolean;
}) {
  const footer = (
    <div css={horizontalFlexStyles}>
      <Button variant="secondary" css={buttonStyles} onClick={onConfirmDelete}>
        {nodeIsReachable ? 'Unassign' : 'Delete'}
      </Button>
      <Button variant="primary" onClick={onRequestHide}>
        Cancel
      </Button>
    </div>
  );
  return (
    <Modal
      show={showModal}
      closeButton={true}
      onRequestHide={onRequestHide}
      title={nodeIsReachable ? 'Unassign' : 'Delete'}
      footer={footer}
    >
      <p>Are you sure you want to {nodeIsReachable ? 'unassign' : 'delete'} this Node?</p>
      {nodeIsReachable ? (
        <p css={topMargin}>
          It will be removed from any places where it appears in this Routing Configuration. You can
          still edit any unassigned Nodes from the Unassigned Nodes section, or reconnect them via
          the select box at any Node&lsquo;s default next Node or any Condition Group&lsquo;s next
          Node.
        </p>
      ) : (
        <p css={topMargin}>Once deleted, this Node will be gone forever.</p>
      )}
    </Modal>
  );
}

const horizontalFlexStyles = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyles = css`
  margin-right: 16px;
`;

const topMargin = css`
  margin-top: 10px;
`;
