import { IAttribute } from '@cimpress-technology/attribute-model-explorer';
import React, { Fragment } from 'react';

import { IInputListProps } from '../';
import { MetadataKeys } from '../../../Models';
import Utils from '../../../Utils';
import DetailedInputFactory from './DetailedInputFactory';

/**
 * Sort the given attributes such that:
 * 1. PivotAttributes come first
 * 2. Formula Attributes come last
 */
function sortAttributes(attributeA: IAttribute, attributeB: IAttribute) {
  let sortValue = 0;

  const displayPriorityA = attributeA.metadata.get(MetadataKeys.displayPriority);
  const displayPriorityB = attributeB.metadata.get(MetadataKeys.displayPriority);
  if (displayPriorityA === displayPriorityB) {
    sortValue = 0;
  } else if (displayPriorityA > displayPriorityB) {
    sortValue = -1;
  } else if (displayPriorityA < displayPriorityB) {
    sortValue = 1;
  }

  return sortValue;
}

// tslint:disable-next-line: variable-name
const DetailedInputList = ({
  attributes,
  onInputChange,
  attributeStateTooltipConfiguration = {},
  styleClasses,
  resourceAttributeIcon,
  attributeSpecifications,
  allowDisabledSelection,
  isColorSwatch,
}: IInputListProps) => (
  <div className="detailed-input-list">
    {attributes.sort(sortAttributes).map((attribute: IAttribute) => (
      <Fragment key={attribute.key}>
        {DetailedInputFactory.createInput(
          attribute,
          onInputChange,
          attributeStateTooltipConfiguration,
          Utils.getAttributeDisplayLabel(attribute.key, attributeSpecifications),
          Utils.getAttributeValueMap(attribute, attributeSpecifications),
          styleClasses,
          resourceAttributeIcon,
          attributeSpecifications,
          allowDisabledSelection,
          isColorSwatch,
        )}
      </Fragment>
    ))}
  </div>
);

export default DetailedInputList;
