import { AttributeConfigurationMap, IAttributeModelConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes } from '@cimpress-technology/selector-resource-formatter';
import { mapKeys } from 'lodash';

import { IConfigurationMapBuilder } from '../..';
import { findAttributesDependencies, isAttributeModelVariableInferable } from '../../../../AttributeModelUtils';
import { IAttributeConfigurationMap, MetadataKeys } from '../../../../Models';
import { convertRequiredResourceAttributeObjectToList } from '../../../../UtilsV2';

import { ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY, PIVOT_DEFAULT_DISPLAY_PRIORITY } from '../../../constants';

export default class AMandResourceConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!AMandResourceConfigurationMapBuilder.instance) {
      AMandResourceConfigurationMapBuilder.instance = new AMandResourceConfigurationMapBuilder();
    }

    AMandResourceConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return AMandResourceConfigurationMapBuilder.instance;
  }

  private static instance: AMandResourceConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  public buildConfigurationMap(
    attributeModel: IAttributeModelConfiguration,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ): AttributeConfigurationMap {
    const requiredAttributes = requiredResourceAttributes || { nonProductAttributes: [], productAttributes: [] };
    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());
    const requiredResourceAttributeKeys = convertRequiredResourceAttributeObjectToList(requiredAttributes);
    const requiredAttributesDependencies = findAttributesDependencies(
      attributeModel,
      requiredResourceAttributeKeys,
    ).map((attr: string) => attr.toLowerCase());

    attributeModel.attributes.forEach((attribute: any) => {
      const attributeKey = attribute.key.toLowerCase();
      const configuration: any = normalizedConfigurationMap[attributeKey] || {};
      const isResourceAttribute: boolean = requiredResourceAttributeKeys.includes(attributeKey);
      const isDeriverForRequiredAttribute = requiredAttributesDependencies.includes(attributeKey);

      if (configuration.displayPriority === undefined) {
        configuration.displayPriority = configuration.isPivot
          ? PIVOT_DEFAULT_DISPLAY_PRIORITY
          : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
      }

      configuration.isRequired = attribute.isNullable
        ? !((!isDeriverForRequiredAttribute && !isResourceAttribute) || isAttributeModelVariableInferable(attribute))
        : !isAttributeModelVariableInferable(attribute);

      if (configuration.isHidden === undefined) {
        configuration.isHidden =
          attribute.isNullable || (!this.displaySingleValuedAttributes && isAttributeModelVariableInferable(attribute));
      }

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
        [MetadataKeys.isResourceAttribute]: isResourceAttribute,
        [MetadataKeys.isResourceDerivedAttribute]: isDeriverForRequiredAttribute,
      };

      normalizedConfigurationMap[attributeKey] = configuration;
    });

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
