import { StringValueConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { colors, Select,Spinner } from '@cimpress/react-components';
import { debounce, get } from 'lodash';
import React, { Component, useState } from 'react';
// @ts-ignore
import { components } from 'react-select';

import { MetadataKeys } from '../../Models';
import { AMEXMetadata } from '../constants';
import { IAttributeStateTooltipConfiguration } from '../Interfaces';
import { IInputProps } from './';
import AttributeInputContainer from './AttributeInputContainer';
import ColorSwatch from './ColorSwatch';
import ResourceAttributeIconWithLabel from './ResourceAttributeIconWithLabel';
import './StringInput.css';
import ValueStateTooltip from './ValueStateTooltip';

const DISABLED_CLASS_NAME = 'disabled';

const DEBOUNCE_WAIT_IN_MS = 200;

const CLASS_NAME_PREFIX = 'string-select-input';

interface ISelectOption {
  className?: string;
  isDisabled?: boolean;
  label: string;
  value: string;
  getValueState: (value: string) => any;
  attributeStateTooltipConfiguration: IAttributeStateTooltipConfiguration;
}

const customOption = (props: any) => {
  const { children, className, data } = props;
  const [loading, setLoading] = useState(true);
  let optionContainer;

  const img = new Image();

  const onImageLoaded = () => {
    setLoading(false);
  };

  const imageUrl = get(data, 'imageUrl', null);

  const preLoadImage = () => {
    img.id = imageUrl;
    img.onload = onImageLoaded;
    img.onerror = onImageLoaded;
    img.src = imageUrl;
  };

  // tslint:disable-next-line: variable-name tslint:disable-next-line: no-shadowed-variable
  const RenderImage = (props: any) => {
    return (
      <React.Fragment>
        <img className={props.className} src={props.imageUrl} />{' '}
      </React.Fragment>
    );
  };

  if (imageUrl) {
    preLoadImage();
    optionContainer = (
      <span className="option-image">
        {children}
        {loading ? <Spinner size="medium" /> : <RenderImage className="option-data-image" imageUrl={imageUrl} />}
      </span>
    );
  } else {
    optionContainer = (
      <span
        className={`option-container ${get(data, 'styleClasses.dropdownOptionValue', '')} ${data.className === DISABLED_CLASS_NAME ? 'disabled' : ''
          }`} style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        {data.isColorSwatch ? <ColorSwatch hexCode={children} /> : null}
        {children}
      </span>
    );
  }

  return (
    <React.Fragment>
      {data.className === DISABLED_CLASS_NAME ? (
        <components.Option {...props}>
          <ValueStateTooltip
            className={`${className} ${data.className}`}
            getValueState={data.getValueState}
            value={data.label}
            showOnExcluded={data.attributeStateTooltipConfiguration.showOnExcluded}
            showOnResolved={data.attributeStateTooltipConfiguration.showOnResolved}
          >
            {optionContainer}
          </ValueStateTooltip>
        </components.Option>
      ) : (
        <components.Option {...props}>{optionContainer}</components.Option>
      )}
    </React.Fragment>
  );
};

interface ICustomValueProps {
  children: React.ReactNode;
  className: string;
  value: any;
  isColorSwatch?: boolean;
}

/**
 * Default Input Component for Value options with enough options to require a Select dropdown.
 */
// tslint:disable-next-line:max-classes-per-file
export default class StringSelectInput extends Component<IInputProps, any> {
  public static defaultProps = {
    attributeStateTooltipConfiguration: {},
    resolvedValue: undefined,
  };

  private readonly emitChange = debounce(
    () => this.props.onInputChange(this.props.attributeKey, this.state.value),
    DEBOUNCE_WAIT_IN_MS,
  );

  private readonly emitChangeDisabled = debounce(
    () => this.props.onInputChange(this.props.attributeKey, this.state.value, true),
    DEBOUNCE_WAIT_IN_MS,
  );

  constructor(props: IInputProps) {
    super(props);

    const { resolvedValue } = this.props;

    this.state = {
      value: resolvedValue,
    };

    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: any) {
    const { allowDisabledSelection } = this.props;

    // Second condition is added for reverting the selection whenever disabled selection is performed and it leads to an unrecoverable error
    if (this.props.resolvedValue !== prevProps.resolvedValue || (allowDisabledSelection && this.props.resolvedValue !== prevState.value)) {
      this.setState({ value: this.props.resolvedValue });
    }
  }

  public render() {
    const {
      attributeKey,
      label,
      valueLabelMap,
      getValueState,
      isRequired,
      isValid,
      validValues,
      values,
      attributeStateTooltipConfiguration,
      metadata,
      resourceAttributeIcon,
      allowDisabledSelection,
      attributeCustomization,
      styleClasses,
      isColorSwatch,
    } = this.props;

    const { value } = this.state;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    const options: ISelectOption[] = values.map((optionValue: StringValueConfiguration) => ({
      attributeStateTooltipConfiguration,
      getValueState,
      isColorSwatch,
      styleClasses,
      className: validValues.find((item: StringValueConfiguration) => optionValue.stringLiteral === item.stringLiteral)
        ? undefined
        : DISABLED_CLASS_NAME,
      imageUrl: get(attributeCustomization, `attributeValues[${optionValue.stringLiteral}].imageUrl`, undefined),
      isDisabled: validValues.find((item: StringValueConfiguration) => optionValue.stringLiteral === item.stringLiteral)
        ? false
        : true && !allowDisabledSelection,
      label: valueLabelMap[optionValue.stringLiteral],
      value: optionValue.stringLiteral,
    }));

    options.sort(this.sortSelectOptions);

    const selectedValue = {
      value,
      label: isColorSwatch ? (<span style={{ display: 'inline-flex', alignItems: 'center' }} > <ColorSwatch hexCode={valueLabelMap[value]} /> {valueLabelMap[value]} </span>) : valueLabelMap[value],
    };

    return (
      <AttributeInputContainer isDisabledSelectionMode={allowDisabledSelection} isValid={allowDisabledSelection && isValid && value}>
        <Select
          classNamePrefix={CLASS_NAME_PREFIX}
          name={attributeKey}
          label={displayLabel}
          required={isRequired}
          options={options}
          valueComponent={this.customValue}
          components={{ Option: customOption, SelectContainer: this.customValue }}
          // @ts-ignore
          value={['', undefined].includes(value) ? '' : selectedValue}
          style={isValid ? {} : { borderColor: colors.danger.base }}
          onChange={this.onChange}
          tether={true}
          isClearable={true}
          containerClassName={get(styleClasses, 'dropdownContainer', '')}
        />
      </AttributeInputContainer>
    );
  }

  /**
   * Custom Value component used to ensure that the ValueStateTooltip
   * only triggers when hovering over the input value box.
   */
  private customValue: React.FunctionComponent = ({ children, value }: ICustomValueProps) => {
    const { allowDisabledSelection, metadata } = this.props;

    let selectValueClass = this.props.isValid ? '' : 'has-error';

    if (allowDisabledSelection) {
      if (metadata && metadata.get(AMEXMetadata.isSelectionUpdatedByInvalidSelection)) {
        selectValueClass = this.props.isValid ? 'has-warning' : 'has-error';
      }
    }

    return (
      <ValueStateTooltip
        getValueState={this.props.getValueState}
        value={this.props.resolvedValue}
        direction="top"
        showOnExcluded={this.props.attributeStateTooltipConfiguration.showOnExcluded}
        showOnResolved={this.props.attributeStateTooltipConfiguration.showOnResolved}
      >
        <div
          className={`Select-value`} title={value ? value.title : undefined}>
          <span className={`Select-value-label  ${selectValueClass} ${get(this.props.styleClasses, 'dropdownSelectValue', '')}`}>{children}</span>
        </div>
      </ValueStateTooltip>
    );
  }; // tslint:disable-line semicolon

  private onChange(option?: any) {
    const isDisabled = option && option.className === DISABLED_CLASS_NAME;
    const { allowDisabledSelection } = this.props;

    if (!isDisabled) {
      const value = get(option, 'value', null);
      this.setState({ value }, () => {
        this.emitChange();
      });
    } else if (allowDisabledSelection && isDisabled) {
      const value = get(option, 'value', null);
      this.setState({ value }, () => {
        this.emitChangeDisabled();
      });
    }
  }

  /**
   * Sort the given options such that the disabled options are at the bottom.
   * @param optionA SelectionOption A
   * @param optionB SelectionOption B
   */
  private sortSelectOptions(optionA: ISelectOption, optionB: ISelectOption) {
    if (optionA.className === DISABLED_CLASS_NAME && optionB.className !== DISABLED_CLASS_NAME) {
      return 1;
    }

    if (optionA.className !== DISABLED_CLASS_NAME && optionB.className === DISABLED_CLASS_NAME) {
      return -1;
    }

    return 0;
  }
}
