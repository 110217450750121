import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getRoutingConfigurationByBuyerAndSku } from '../../services/routingConfigurationService';
import { RoutingConfigurationV3 } from '../../types';

interface selectedConfigurationState {
  skuCode: string;
  routingConfiguration: RoutingConfigurationV3 | null;
  etag: string;
  loading: boolean;
}

const initialState: selectedConfigurationState = {
  skuCode: '',
  routingConfiguration: null,
  etag: '',
  loading: false,
};

type SearchAndSetParams = {
  buyer: string;
  skuCode: string;
};

export const searchAndSetRoutingConfiguration = createAsyncThunk(
  'selectedConfiguration/searchAndSetConfig',
  async ({ buyer, skuCode }: SearchAndSetParams) => {
    const { configuration, etag } = await getRoutingConfigurationByBuyerAndSku({ buyer, skuCode });
    return { configuration, etag };
  },
);

const selectedConfigurationSlice = createSlice({
  name: 'selectedConfiguration',
  initialState,
  reducers: {
    setSkuCode(state, action: PayloadAction<string>) {
      state.skuCode = action.payload;
    },
    setRoutingConfiguration(state, action: PayloadAction<RoutingConfigurationV3>) {
      state.routingConfiguration = action.payload;
      state.loading = false;
    },
    resetRoutingConfiguration(state) {
      state.routingConfiguration = null;
    },
    setEtag(state, action: PayloadAction<string>) {
      state.etag = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchAndSetRoutingConfiguration.fulfilled, (state, action) => {
      state.routingConfiguration = action.payload.configuration;
      state.etag = action.payload.etag;
    });
  },
});

export const {
  setSkuCode,
  setRoutingConfiguration,
  resetRoutingConfiguration,
  setEtag,
  setLoading,
} = selectedConfigurationSlice.actions;
export default selectedConfigurationSlice.reducer;
