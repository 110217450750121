import React, { useEffect } from 'react';
import { ThemeProvider } from 'react-jss';
import Styles, { createStyles } from '../StyleUtils';
import { themeResolver } from './styles';

const classes = createStyles(themeResolver);


function generateDynamicCSS(color:any) {
  return (`
  .number-select-input__menu-portal * {
      color: ${color.primaryColor};
    }
  .number-select-input__menu-portal >div {
      border: 1px solid ${color.secondaryColor};
    }
  .string-select-input__menu-portal * {
      color: ${color.primaryColor};
    }
  .string-select-input__menu-portal >div{
      border: 1px solid ${color.secondaryColor};
    }
  `);
}
/* tslint:disable-next-line */
export default function ThemeApplier(props: any) {
  const theme = props.theme;
  useEffect(() => {
    // Generate the dynamic CSS
    const dynamicCSS = generateDynamicCSS(props.theme.globals);

    // Create a <style> element and set its content to the dynamic CSS
    const styleElement = document.createElement('style');
    styleElement.innerHTML = dynamicCSS;

    // Append the <style> element to the document's <head>
    document.head.appendChild(styleElement);

    // Cleanup: Remove the <style> element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, [props.theme.globals]);
  return (
    <ThemeProvider theme={theme}>
      <Styles styles={classes}>{styleClasses => props.children(styleClasses)}</Styles>
    </ThemeProvider>
  );
}
