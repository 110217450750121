/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { Select } from '@cimpress/react-components';

const DefaultNextNodeSelect = styled(Select)`
  width: 300px;
`;

export default DefaultNextNodeSelect;
