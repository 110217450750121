import { RangeConfiguration, ValueConfiguration, ValueType } from '@cimpress-technology/attribute-model-explorer';
import { Tooltip } from '@cimpress/react-components';
import parse from 'html-react-parser';
import React, { useEffect,useRef,useState } from 'react';
import './NumberTooltip.css';

interface IProps extends React.HTMLProps<HTMLDivElement> {
  values: ValueConfiguration[];
  showTooltip: boolean;
  additionalTooltipContent?: string;
}

function getRangeListItems(ranges: RangeConfiguration[]): JSX.Element[] {
  const listItems: JSX.Element[] = [];
  ranges.forEach((rangeConfiguration, index) => {
    const { range } = rangeConfiguration;

    if (range.minimum === range.maximum) return;

    listItems.push(
      <li className="toolTipContents" key={`${JSON.stringify(range)}${index}`}>
        {range.minimum} and {range.maximum || 'infinity'}
        {range.increment && ` in increments of ${range.increment}`}
        {index === ranges.length - 1 ? '.' : ','}
      </li>,
    );
  });

  return listItems;
}

function joinRanges(
  discreteValues: string[],
  ranges: JSX.Element[],
  additionalTooltipContent?: string,
): JSX.Element | undefined {
  let resultNode: JSX.Element | undefined;
  const additionalContent = additionalTooltipContent ? (
    <span style={{ textDecoration: 'none' }}>{parse(additionalTooltipContent)}</span>
  ) : (
    undefined
  );
  if (discreteValues.length > 0 && ranges.length > 0) {
    resultNode = (
      <ul className="listOfValues">
        <span className="toolTipContents">Must be </span>
        {discreteValues.join(', ')}
        <span className="toolTipContents"> or must be between </span>
        {ranges}
        {additionalContent}
      </ul>
    );
  } else if (discreteValues.length > 0) {
    resultNode = (
      <ul className="listOfValues">
        <span className="toolTipContents">Must be </span>
        {discreteValues.join(', ')}.<br />
        {additionalContent}
      </ul>
    );
  } else if (ranges.length > 0) {
    resultNode = (
      <ul className="listOfValues">
        <span className="toolTipContents">Must be between </span>
        {ranges}
        {additionalContent}
      </ul>
    );
  }

  return resultNode;
}

// tslint:disable-next-line: variable-name
const NumberTooltip = ({ showTooltip, values, children, additionalTooltipContent = '' }: IProps) => {
  const [childrenWidth,setChildrenWidth] = useState(0);
  const [spaceOnRight,setSpaceOnRight] = useState(0);
  const childRef = useRef<any>(null);

  useEffect(() => {
    if (childRef.current) {
      const { width,right } = childRef.current.getBoundingClientRect();
      setChildrenWidth(width);
      setSpaceOnRight(right);
    }
  }, [children]);

  let contents;
  if (values[0] && values[0].type === ValueType.Formula) {
    const formulaSpecification = values[0];
    const formula = formulaSpecification !== undefined ? formulaSpecification.formula : '';

    contents = `This field is calculated by:\n${formula}`;
    contents = additionalTooltipContent ? (
      <span className="tooltip-formula">{parse(`${contents}. <br />${additionalTooltipContent}`)}</span>
    ) : (
      contents
    );
  } else {
    const discreteValues = values.filter(v => v.type === ValueType.NumberLiteral).map(v => v.numberLiteral);
    const rangeValues = getRangeListItems(
      values.filter(v => v.type === ValueType.Range && v.range.minimum !== v.range.maximum),
    );
    const singleValueRanges = values.filter(v => v.type === ValueType.Range && v.range.minimum === v.range.maximum);

    discreteValues.push(...singleValueRanges.map(v => v.range.minimum));

    contents = joinRanges(discreteValues, rangeValues, additionalTooltipContent);
  }

  const hasEnoughSpace=(widthOnRight:any)=>{
    const windowWidth = window.innerWidth;
    return 200<windowWidth-widthOnRight;
  };

  const tooltipStyle = {
    maxWidth:hasEnoughSpace(spaceOnRight)?'none':`${childrenWidth*0.75}px`,
    visibility:showTooltip && contents?'visible':'hidden',
  };

  return (
    <Tooltip
      className="toolTip"
      containerClassName="rangeTooltip"
      direction="right"
      tooltipStyle={tooltipStyle}
      contents={contents}
      constraints={[
        {
          pin: ['left', 'right'],
          to: 'scrollParent',
        },
      ]}
    >
      <div ref={childRef}>
      {children}
      </div>
    </Tooltip>
  );
};

export default NumberTooltip;
