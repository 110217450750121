import { NumberLiteralConfiguration, StringValueConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { debounce, get } from 'lodash';
import React, { Component } from 'react';
import { IInputProps } from '../';
import { MetadataKeys } from '../../../Models';
import DetailViewContainer from '../DetailViewContainer';
import ResourceAttributeIconWithLabel from '../ResourceAttributeIconWithLabel';
import ValueStateTooltip from '../ValueStateTooltip';
import DetailCard from './DetailCard';

import './DetailCardGrid.css';

interface ICardState {
  value: string;
}

const DEBOUNCE_WAIT_IN_MS = 200;

/**
 * Default Input Component for Value Attributes that have few enough options to be represented
 * via Buttons
 */
export default class DetailCardGrid extends Component<IInputProps, ICardState> {
  public static defaultProps = {
    attributeStateTooltipConfiguration: {},
    resolvedValue: undefined,
  };

  constructor(props: IInputProps) {
    super(props);

    this.state = {
      value: this.props.resolvedValue || '',
    };

    this.getValueButtons = this.getValueButtons.bind(this);
    this.onClick = this.onClick.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: any) {
    const { allowDisabledSelection } = this.props;

    // Second condition is added for reverting the selection whenever disabled selection is performed and it leads to an unrecoverable error
    if (this.props.resolvedValue !== prevProps.resolvedValue || (allowDisabledSelection && this.props.resolvedValue !== prevState.value)) {
      this.setState({ value: this.props.resolvedValue });
    }
  }

  public render() {
    const { label, isValid, values, isRequired, metadata, styleClasses, resourceAttributeIcon } = this.props;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    return (
      <DetailViewContainer label={displayLabel} isRequired={isRequired} styleClasses={styleClasses} isValid={isValid}>
        <div style={{ marginBottom: '15px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            {this.getValueButtons(values)}
          </div>
        </div>
      </DetailViewContainer>
    );
  }

  private getValueButtons(values: StringValueConfiguration[] | NumberLiteralConfiguration[]): JSX.Element[] {
    const {
      valueLabelMap,
      getValueState,
      attributeStateTooltipConfiguration,
      attributeCustomization,
      styleClasses,
      allowDisabledSelection,
      isColorSwatch,
    } = this.props;

    return values.map(({ stringLiteral, numberLiteral }) => {
      const attributeValue = stringLiteral ? stringLiteral : numberLiteral;
      const isActive = attributeValue === this.state.value;
      let isDisabled: any = this.isDisabled(attributeValue);

      if (allowDisabledSelection && isDisabled && isActive) {
        isDisabled = false;
      }

      return (
        <div className="card-container" key={attributeValue}>
          <ValueStateTooltip
            key={attributeValue}
            getValueState={getValueState}
            value={attributeValue}
            showOnExcluded={attributeStateTooltipConfiguration.showOnExcluded}
            showOnResolved={attributeStateTooltipConfiguration.showOnResolved}
            style={{ display: 'block', width: '100%' }}
          >
            <DetailCard
              isColorSwatch = {isColorSwatch}
              label={valueLabelMap[attributeValue]}
              imageUrl={get(attributeCustomization, `attributeValues.${attributeValue}.imageUrl`, undefined)}
              isDisabled={isDisabled}
              isActive={isActive}
              key={attributeValue}
              onClick={this.onClick}
              attributeValue={attributeValue}
              styleClasses={styleClasses}
              allowDisabledSelection={allowDisabledSelection}
            />
          </ValueStateTooltip>
        </div>
      );
    });
  }

  private onClick(value: string) {
    const { allowDisabledSelection } = this.props;
    const isDisabled = this.isDisabled(value);

    if (value !== null && !isDisabled) {
      this.setState(
        {
          value,
        },
        () => this.props.onInputChange(this.props.attributeKey, value),
      );
    } else if (allowDisabledSelection && value !== null && isDisabled) {
      this.setState(
        {
          value,
        },
        debounce(() => this.props.onInputChange(this.props.attributeKey, value, true), DEBOUNCE_WAIT_IN_MS),
      );
    }
  }

  private isDisabled(value: string): boolean {
    return !this.props.validValues.find(item => value === item.stringLiteral || value === item.numberLiteral);
  }
}
