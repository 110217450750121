/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { colors } from '@cimpress/react-components';

import { SMALL_FONT } from '../../constants/stylingConstants';

const Tip = styled.div`
  color: ${colors.shale};
  font-size: ${SMALL_FONT};
  grid-area: list-tip;
  line-height: 1em;
  text-align: left;

  p:not(:only-child) {
    margin-bottom: 10px;
  }
`;

export default Tip;
