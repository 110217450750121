/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { Radio, RadioGroup, Tooltip, colors } from '@cimpress/react-components/';

import { SelectionStrategy } from '../../types';

const tooltipContents = (
  <p>
    If two or more fulfillment options share first place at the end of evaluation, the tie will be
    broken based on whichever one we saw first (which will be more deterministic between orders), or
    a winner will be selected randomly.
  </p>
);

export default function SelectionStrategySelector({
  selectionStrategy,
  onUpdateSelectionStrategy,
}: {
  selectionStrategy?: SelectionStrategy;
  onUpdateSelectionStrategy: (s: string) => void;
}) {
  const handleStrategySelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdateSelectionStrategy(e.target.value);
  };

  return (
    <div key={`strategy-selector-${selectionStrategy}`}>
      <div css={headerDivStyles}>
        <h5 css={headerStyles}>Tiebreak Strategy</h5>
        <div css={tooltipStyles}>
          <Tooltip contents={tooltipContents}>
            <IconInformationCircle color={colors.info.base} weight="fill" />
          </Tooltip>
        </div>
      </div>
      <RadioGroup
        name="SelectionStrategySelector"
        onChange={handleStrategySelect}
        defaultSelected={
          selectionStrategy?.toLowerCase() === SelectionStrategy.first
            ? SelectionStrategy.first
            : SelectionStrategy.random
        }
        inline
      >
        <Radio label="First" value={SelectionStrategy.first} />
        <Radio label="Random" value={SelectionStrategy.random} />
      </RadioGroup>
    </div>
  );
}

const tooltipStyles = css`
  margin: auto 10px auto 0;
  padding-top: 4px;
`;

const headerDivStyles = css`
  display: flex;
  flex-direction: row;
`;

const headerStyles = css`
  margin: auto 10px auto 0;
  justify-self: start;
`;
