import { v4 as uuid } from 'uuid';

import { Action, ActionOperator } from '../types';

export function newDefaultAction(): Action {
  return {
    id: uuid(),
    type: 'sort',
    path: 'priceIncludingCostOfDelay',
    operator: ActionOperator.valueAtPath,
    value: '',
    direction: 'asc',
  };
}
