import { IProduct } from '@cimpress-technology/attribute-model-explorer';
import {
  InputFormatterFactory,
  IRequiredAttributes,
  ISelection,
  OutputFormatterFactory,
  ProductModel,
  ResourceType,
} from '@cimpress-technology/selector-resource-formatter';
import { cloneDeep, get, uniqBy } from 'lodash';

import { IConfigurationMapBuilder, IProcessor } from '..';
import { IAttributeConfigurationMap } from '../../../Models';
import { convertResourceInputIntoV2ProductOptionsFormat } from '../../../UtilsV2';
import IModelExplorerRepo from '../../Interfaces/IModelExplorerRepo';
import ModelExplorerRepo from '../../Repository/ModelExplorerRepo';

export default class ProductV2Processor implements IProcessor<IProduct, IProduct, IProduct> {
  private static CALCULATED_SURFACE_SET = 'calculatedSurfaceSet';

  private configurationMapBuilder: IConfigurationMapBuilder;

  private resourceRequiredAttributes: IRequiredAttributes;

  private optimizePivot: boolean;

  public constructor(configurationMapBuilder: IConfigurationMapBuilder, optimizePivot: boolean = true) {
    this.configurationMapBuilder = configurationMapBuilder;
    this.optimizePivot = optimizePivot;
  }

  public async getResourceRequiredAttributes(
    authToken: string,
    product: IProduct,
    selectionResource: ResourceType,
  ): Promise<IRequiredAttributes> {
    const requiredAttributes = await InputFormatterFactory.getFormatter(
      selectionResource,
      ProductModel.V2,
    ).getRequiredInputs(authToken, product.productId, product.version);
    this.setResourceRequiredAttributes(cloneDeep(requiredAttributes));

    const keys = get(product, `relatedResources.${ProductV2Processor.CALCULATED_SURFACE_SET}[0].keys`, undefined);

    if (keys && keys.length > 0) {
      (requiredAttributes.productAttributes || []).forEach(productAttribute => {
        const mappingKey = keys.find((key: any) => key.token === productAttribute.attributeName);
        if (mappingKey && mappingKey.attribute) {
          productAttribute.attributeName = mappingKey.attribute;
        }
      });
    }

    if (requiredAttributes.productAttributes) {
      requiredAttributes.productAttributes = uniqBy(requiredAttributes.productAttributes, 'attributeName');
    }

    return requiredAttributes;
  }

  public getResource(
    product: IProduct,
    selectionResource: ResourceType,
    selections: ISelection[],
    requiredResourceAttributes: IRequiredAttributes,
  ): string {
    if (selectionResource === ResourceType.SURFACE) {
      const keys = get(product, `relatedResources.${ProductV2Processor.CALCULATED_SURFACE_SET}[0].keys`, []);
      const convertedSelections: ISelection[] = [];

      (this.resourceRequiredAttributes.productAttributes || []).forEach(productAttribute => {
        const mappingKey = keys.find((key: any) => key.token === productAttribute.attributeName);

        if (!mappingKey) {
          if (!productAttribute.allowUnresolved) {
            // tslint:disable-next-line:no-console
            console.error(
              `Surface Variable ${productAttribute.attributeName} is not mapped with the product and does not have a default value.`,
            );
          }
        } else {
          const selectedAttribute = selections.find(selection => selection.key === mappingKey.attribute);
          const resolvedValue = selectedAttribute ? selectedAttribute.resolvedValue : undefined;

          if (resolvedValue) {
            convertedSelections.push({ resolvedValue, key: productAttribute.attributeName });
          }
        }
      });

      return OutputFormatterFactory.getFormatter(selectionResource, ProductModel.V2).getResource(
        {
          definitionHref: get(
            product,
            `relatedResources.${ProductV2Processor.CALCULATED_SURFACE_SET}[0].definitionHref`,
            '',
          ),
          requiredAttributes: this.resourceRequiredAttributes || [],
        },
        convertedSelections,
      );
    }

    throw new Error('Resource not supported');
  }

  public createAttributeModelExplorer(
    product: IProduct,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes: IRequiredAttributes,
  ): IModelExplorerRepo {
    const updatedProduct = this.getUpdatedProduct(product, requiredResourceAttributes);
    const attributeConfigurationMap = this.createAttributeConfigurationMap(
      updatedProduct,
      configurationMap,
      requiredResourceAttributes,
    );

    return ModelExplorerRepo.createFromProduct(updatedProduct, attributeConfigurationMap, this.optimizePivot);
  }

  private createAttributeConfigurationMap(
    product: IProduct,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ) {
    return this.configurationMapBuilder.buildConfigurationMap(product, configurationMap, requiredResourceAttributes);
  }

  private getUpdatedProduct(product: IProduct, requiredResourceAttributes?: IRequiredAttributes) {
    if (requiredResourceAttributes && requiredResourceAttributes.nonProductAttributes) {
      const productClone = cloneDeep(product);
      const resourceNonProductRequiredAttributes = convertResourceInputIntoV2ProductOptionsFormat(
        requiredResourceAttributes.nonProductAttributes,
      );

      if (resourceNonProductRequiredAttributes.length > 0) {
        productClone.options = productClone.options.concat(resourceNonProductRequiredAttributes);
        return productClone;
      }
    }

    return product;
  }

  private setResourceRequiredAttributes(resourceRequiredAttributes: IRequiredAttributes) {
    this.resourceRequiredAttributes = resourceRequiredAttributes;
  }
}