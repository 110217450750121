import React from 'react';

import { Tooltip } from '@cimpress/react-components';

export default function NextNodeWrapperTooltip({
  enabled,
  children,
}: {
  enabled: boolean;
  children: React.ReactNode;
}) {
  return enabled ? (
    <Tooltip
      style={toolTipTopLevelStyle as any}
      contents={
        <p>
          There are no unused Nodes available to select. If you want to select a next Node, please
          create a Node and ensure that it is not used higher up in the Routing Configuration.
        </p>
      }
    >
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}

const toolTipTopLevelStyle = {
  zIndex: 6,
};
