import React from 'react';

import { Accordion } from '@cimpress/react-components';

import './collapsibleWrapper.scss';

export default function CollapsibleWrapper({
  title,
  isOpen,
  onAccordionClick,
  children,
}: {
  title: JSX.Element;
  isOpen: boolean;
  onAccordionClick: (s: string) => void;
  children: React.ReactNode;
}) {
  return (
    <div className="container">
      <Accordion variant="ghost" title={title} customOpen={isOpen} onHeaderClick={onAccordionClick}>
        {children}
      </Accordion>
    </div>
  );
}
