import { IAttribute } from '@cimpress-technology/attribute-model-explorer';
import React from 'react';
import { ICustomizations, LayoutType } from '../../Models';
import ThemeApplier from '../ThemeApplier';
import { CompactLayout, DetailedLayout } from './';
import  ILayoutProps  from './ILayoutProps';

export default class LayoutFactory {
  public static buildLayout(props: ILayoutProps, customizations?: ICustomizations, theme?: any) {
    let reorderedSelectionOptions = props.selectionOptions;
    if (customizations) {
      if (customizations.order) {
        reorderedSelectionOptions = this.reOrderOptions(customizations.order, reorderedSelectionOptions);
      }
      const layoutType = customizations.layout ? customizations.layout.type : undefined;

      switch (layoutType) {
        case LayoutType.COMPACT:
          return this.buildCompactLayout(props, reorderedSelectionOptions, customizations, theme);
        case LayoutType.DETAILED:
          return this.buildDetailedLayout(props, reorderedSelectionOptions, customizations, theme);
        default:
      }
    }

    return this.buildCompactLayout(props, reorderedSelectionOptions, customizations, theme);
  }

  private static reOrderOptions(requiredOrder: string[], selectionOptions: IAttribute[]) {
    const options: any[] = [];
    requiredOrder.forEach(attributeKey => {
      const index = selectionOptions.findIndex(option => option.key.toLowerCase() === attributeKey.toLowerCase());

      if (index !== -1) {
        options.push(selectionOptions[index]);
        selectionOptions.splice(index, 1);
      }
    });

    return [...options, ...selectionOptions];
  }

  private static buildCompactLayout(
    props: ILayoutProps,
    reorderedSelectionOptions: IAttribute[],
    customizations?: ICustomizations,
    theme?: any,
  ) {
    if (theme) {
      return (
        <ThemeApplier theme={theme}>
          {(classes: string[]) => (
            <CompactLayout
              {...props}
              selectionOptions={reorderedSelectionOptions}
              layoutConfiguration={
                customizations && customizations.layout ? customizations.layout.configuration : undefined
              }
              attributeSpecifications={customizations ? customizations.attributeSpecifications : undefined}
              styleClasses={classes}
            />
          )}
        </ThemeApplier>
      );
    }
    return (
      <ThemeApplier theme={{globals:{}}}>
          {(classes: string[]) => (
            <CompactLayout
              {...props}
              selectionOptions={reorderedSelectionOptions}
              layoutConfiguration={
                customizations && customizations.layout ? customizations.layout.configuration : undefined
              }
              attributeSpecifications={customizations ? customizations.attributeSpecifications : undefined}
              styleClasses={classes}
            />
          )}
        </ThemeApplier>
    );
  }

  private static buildDetailedLayout(
    props: ILayoutProps,
    reorderedSelectionOptions: IAttribute[],
    customizations?: ICustomizations,
    theme?: any,
  ) {
    if (theme) {
      return (
        <ThemeApplier theme={theme}>
          {(classes: string[]) => (
            <DetailedLayout
              {...props}
              selectionOptions={reorderedSelectionOptions}
              layoutConfiguration={
                customizations && customizations.layout ? customizations.layout.configuration : undefined
              }
              attributeSpecifications={customizations ? customizations.attributeSpecifications : undefined}
              styleClasses={classes}
            />
          )}
        </ThemeApplier>
      );
    }
    return (
      <ThemeApplier theme={{globals:{}}}>
          {(classes: string[]) => (
            <DetailedLayout
              {...props}
              selectionOptions={reorderedSelectionOptions}
              layoutConfiguration={
                customizations && customizations.layout ? customizations.layout.configuration : undefined
              }
              attributeSpecifications={customizations ? customizations.attributeSpecifications : undefined}
              styleClasses={classes}
            />
          )}
        </ThemeApplier>
    );
  }
}
