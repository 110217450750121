import React, { Dispatch, SetStateAction } from 'react';
import countryList from 'react-select-country-list';

import { Select } from '@cimpress/react-components';

import { DisplayValue } from '../../../types';
import styles from './countrySelector.module.scss';

const options: DisplayValue<string>[] = countryList()
  .getLabels()
  .map((countryName: string) => {
    const countryCode = countryList().getValue(countryName);
    return { value: countryCode, label: countryName };
  });

export default function CountrySelect({
  value,
  onChange,
  isDisabled,
}: {
  value: string | null;
  onChange: Dispatch<SetStateAction<string | null>>;
  isDisabled: boolean;
}) {
  const selectedValue = options.find((option) => option.value === value);
  const handleChange = (selection: DisplayValue<string> | null) =>
    onChange(selection?.value || null);

  return (
    <Select
      isClearable
      label="Country Code"
      options={options}
      value={selectedValue}
      onChange={handleChange}
      isDisabled={isDisabled}
      className={styles.countrySelect}
      required
    />
  );
}
