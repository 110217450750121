import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';

const RULE_SERVICE_URL = 'https://rule.products.cimpress.io';

/**
 * Client to interact with the Rule Service
 */
export default class RuleServiceClient {
  /**
   * @property authToken - The OAuth JWT to authenticate requests with.
   */
  private readonly authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  /**
   * Get the authoritative rule set that corresponds to the provided referenceId.
   * @param referenceId - The referenceId corresponding to the rule set.
   * @returns A Promise to return a rule set.
   */
  public async getRuleSet(referenceId: string): Promise<any> {
    const response = await fetch(`${RULE_SERVICE_URL}/v1/ruleSets:authoritative/${referenceId}`, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${this.authToken}`,
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Invalid Product ID ${referenceId} provided.`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`${errorAlertMessages.ERROR_FETCHING_PRODUCT} ${referenceId}.`);
    }
  }
}
