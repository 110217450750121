import _ from 'lodash';
import React from 'react';

import { Select } from '@cimpress/react-components';

import { DisplayValue } from '../../../types';
import styles from './fulfillmentCapabilitiesSelector.module.scss';

const FULFILLMENT_CAPABILITIES = [
  {
    value: 'cashOnDelivery',
    label: 'Cash on delivery',
  },
  {
    value: 'doNotProduce',
    label: 'Do not produce',
  },
  {
    value: 'doNotShip',
    label: 'Do not ship',
  },
  {
    value: 'moneyBackGuaranteedDelivery',
    label: 'Money back guaranteed delivery',
  },
  {
    value: 'pickupPoints',
    label: 'Pickup points',
  },
  {
    value: 'whiteLabelling',
    label: 'White labelling',
  },
];

export default function FulfillmentCapabilitiesSelector({ ...props }) {
  const selectedValue = _.map(
    props.value,
    (fulfillmentCap: string) =>
      _.find(FULFILLMENT_CAPABILITIES, (capability) => capability.value === fulfillmentCap)!,
  );

  const handleChange = (selections: DisplayValue<string>) => {
    const newValues = _.map(selections, 'value');
    props.onChange(newValues);
  };

  return (
    <Select
      {...props}
      className={styles.selectHeight}
      value={selectedValue}
      onChange={handleChange}
      label="Fulfillment capability"
      options={FULFILLMENT_CAPABILITIES}
      isClearable
      isMulti
    />
  );
}
