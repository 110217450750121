import { ActionOperator } from '../types';

export const ACTION_OPERATOR_SELECTION_VALUES = {
  [ActionOperator.equal]: {
    label: 'Equal to',
    value: ActionOperator.equal,
  },
  [ActionOperator.notEqual]: { label: 'Not equal to', value: ActionOperator.notEqual },
  [ActionOperator.greaterThan]: { label: 'Greater than', value: ActionOperator.greaterThan },
  [ActionOperator.greaterThanInclusive]: {
    label: 'Greater or equal to',
    value: ActionOperator.greaterThanInclusive,
  },
  [ActionOperator.lessThan]: { label: 'Less than', value: ActionOperator.lessThan },
  [ActionOperator.lessThanInclusive]: {
    label: 'Less or equal to',
    value: ActionOperator.lessThanInclusive,
  },
  [ActionOperator.in]: { label: 'In', value: ActionOperator.in },
  [ActionOperator.notIn]: { label: 'Not In', value: ActionOperator.notIn },
  [ActionOperator.valueAtPath]: { label: 'Value', value: ActionOperator.valueAtPath },
  [ActionOperator.valueInDictionary]: {
    label: 'Value in table',
    value: ActionOperator.valueInDictionary,
  },
};
