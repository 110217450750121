/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';

import { SelectWrapper } from '@cimpress/react-components';

import './valueSelectWrapper.scss';

const ValueSelectWrapper = styled(SelectWrapper)`
  max-width: 400px;
  min-width: 200px;
`;

// Note: This doesn't work if you give it options. I'm not sure why...
export default function Wrapper(props) {
  return <ValueSelectWrapper {...props} classNamePrefix="value-select" />;
}
