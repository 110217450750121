import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';

const VP_SERVICE_BASE_URL = 'https://catalog-transition.products.vpsvc.com/api';
const VP_SELECTOR = 'vp-selector';

/**
 * Client to interact with the Vistaprint Product Catalog Service
 */
export default class VPCatalogServiceClient {
  /**
   * Get the Vistaprint product that corresponds to the provided PRD Id.
   * @param productId - The productId corresponding to the vistaprint prd product.
   * @param requestor - The requestor field will default to constant VP_SELECTOR, requestor is used by VP catalog service only for logging purposes
   * @returns A Promise to return a rule set.
   */
  public async getVPPrdEquivalentMCPProductId(productId: string, requestor: string = VP_SELECTOR): Promise<any> {
    const requestUrl = `${VP_SERVICE_BASE_URL}/products/${productId}/mcpProductIdentifier?requestor=${requestor}`;

    const response = await fetch(requestUrl, {
      headers: {
        accept: 'application/json',
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Could not find mcp product for Vistaprint PRD identifier :  ${productId}`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`Error fetching mcp product for Vistaprint PRD identifier : ${productId}`);
    }
  }

  /**
   * Get the Vistaprint product that corresponds to the provided PRD Id and version. Current version of PRD product is fetched when version is not passed.
   * @param productId - The productId corresponding to the vistaprint prd product.
   * @param version - The product version corresponding to the vistaprint prd product.
   * @param requestor - The requestor field will default to constant VP_SELECTOR, requestor is used by VP catalog service only for logging purposes
   * @returns A Promise to return PRD product.
   */
  public async getVPProduct(productId: string, version?: string, requestor: string = VP_SELECTOR): Promise<any> {
    let requestUrl: string;

    if (version) {
      requestUrl = `${VP_SERVICE_BASE_URL}/v2/products/${productId}/${version}?includeInformationalAttributes=false&requestor=${requestor}`;
    } else {
      const prdVersionResponse = await this.getCurrentVPProduct(productId);
      requestUrl = `${VP_SERVICE_BASE_URL}/v2/products/${productId}/${prdVersionResponse.version}?includeInformationalAttributes=false&requestor=${requestor}`;
    }

    const response = await fetch(requestUrl, {
      headers: {
        accept: 'application/json',
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Could not find Vistaprint PRD identifier : ${productId} with version : ${version}`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`Error fetching Vistaprint PRD identifier : ${productId} with version : ${version}`);
    }
  }

  public async getCurrentVPProduct(productId: string, requestor: string = VP_SELECTOR): Promise<any> {
    const requestUrl: string = `${VP_SERVICE_BASE_URL}/v2/products/${productId}/versions/current?requestor=${requestor}`;

    const response = await fetch(requestUrl, {
      headers: {
        accept: 'application/json',
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Could not find Vistaprint PRD identifier : ${productId}`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`Error fetching Vistaprint PRD identifier : ${productId}`);
    }
  }
}
