import { v4 as uuid } from 'uuid';

export function newBlankIfThen() {
  return {
    id: uuid(),
    description: '',
    name: '',
    rule: {
      conditions: {
        all: [
          {
            id: uuid(),
            fact: undefined,
            path: undefined,
            operator: undefined,
            value: undefined,
          },
        ],
      },
    },
  };
}
