/** @jsxImportSource @emotion/react */
import React, { createContext, useState } from 'react';

import { RoutingConfigurationV3 } from '../types';

export const RoutingConfigurationContext = createContext<any>(null);

export const RoutingConfigurationProvider = ({ children }) => {
  const [selectedConfiguration, setSelectedConfiguration] = useState<RoutingConfigurationV3>();

  return (
    <RoutingConfigurationContext.Provider
      value={{ selectedConfiguration, setSelectedConfiguration }}
    >
      {children}
    </RoutingConfigurationContext.Provider>
  );
};
