/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { colors } from '@cimpress/react-components';

export default function AddNodeButton({ onClick, parentId }) {
  return (
    <button data-parent-id={parentId} css={[newNodeStyles, hoverStyles]} onClick={onClick}>
      +
    </button>
  );
}

const newNodeStyles = css`
  color: ${colors.ocean.base};
  background-color: ${colors.horizon};
  border: 2px dashed ${colors.sky.darker};
  font-size: 28px; // usually this is equal to 2em
  height: 50px;
  padding-bottom: 5px;
  width: 50px;
  // alignment
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const hoverStyles = css`
  &:hover {
    background-color: ${colors.sky.base};
    border: 2px dashed ${colors.sky.darkest};
    color: ${colors.ocean.base};
  }
`;
