/**
 * Error returned when selection is reverted
 */
export default class RevertSelectionError extends Error {
  public errorCode: number;
  public message: string;
  public additionalProperties: object;

  constructor(message: string, errorCode: number, additionalProperties: object) {
    super(message);

    this.message = message;
    this.errorCode = errorCode;
    this.additionalProperties = additionalProperties;
  }
}