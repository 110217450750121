import {
  AttributeConfigurationMap,
  AttributeModelExplorerFactory,
  V1AttributeTypes,
} from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes } from '@cimpress-technology/selector-resource-formatter';
import { cloneDeep, isNil, mapKeys } from 'lodash';
import { IConfigurationMapBuilder } from '../..';
import { IAttributeConfigurationMap, MetadataKeys } from '../../../../Models';
import Utils, { findAttributesDependencies } from '../../../../Utils';

import {
  ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY,
  FORMULA_DEFAULT_DISPLAY_PRIORITY,
  PIVOT_DEFAULT_DISPLAY_PRIORITY,
} from '../../../constants';
import { IRuleSet } from '../../../Interfaces';

export default class RuleSetResourceConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!RuleSetResourceConfigurationMapBuilder.instance) {
      RuleSetResourceConfigurationMapBuilder.instance = new RuleSetResourceConfigurationMapBuilder();
    }

    RuleSetResourceConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return RuleSetResourceConfigurationMapBuilder.instance;
  }

  private static instance: RuleSetResourceConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  public buildConfigurationMap(
    ruleSet: IRuleSet,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ): AttributeConfigurationMap {
    const requiredAttributes = !requiredResourceAttributes
      ? { nonProductAttributes: [], productAttributes: [] }
      : requiredResourceAttributes;

    const { requiredResourceAttributeKeys, optionalResourceAttributeKeys } = Utils.getRequiredResolutionAttributeKeys(
      requiredAttributes,
    );
    const resourceAttributes = [...requiredResourceAttributeKeys, ...optionalResourceAttributeKeys];

    const requiredResourceAttributeDependencies = findAttributesDependencies(
      ruleSet,
      requiredResourceAttributeKeys,
    ).map((attr: string) => attr.toLowerCase());
    const optionalResourceAttributeDependencies = findAttributesDependencies(
      ruleSet,
      optionalResourceAttributeKeys,
    ).map((attr: string) => attr.toLowerCase());

    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());

    if (isNil(ruleSet.whiteList)) {
      ruleSet.whiteList = [];
    }

    ruleSet.whiteList.forEach((attribute: any) => {
      const attributeName = attribute.attributeKey.toLowerCase();
      const configuration: any = cloneDeep(normalizedConfigurationMap[attributeName] || {});

      const isResourceAttribute: boolean = resourceAttributes.includes(attributeName);
      const isDeriverForOptionalResourceAttribute = optionalResourceAttributeDependencies.includes(attributeName);
      const isDeriverForRequiredResourceAttribute = requiredResourceAttributeDependencies.includes(attributeName);
      const isDeriverAttribute = isDeriverForOptionalResourceAttribute || isDeriverForRequiredResourceAttribute;
      const isAttributeInferable = Utils.isV1AttributeInferable(attribute);

      if (configuration.displayPriority === undefined) {
        if (configuration.isPivot) {
          configuration.displayPriority = PIVOT_DEFAULT_DISPLAY_PRIORITY;
        } else {
          configuration.displayPriority =
            attribute.type === V1AttributeTypes.formula
              ? FORMULA_DEFAULT_DISPLAY_PRIORITY
              : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
        }
      }

      configuration.isRequired = (attribute.required || isDeriverForRequiredResourceAttribute) && !isAttributeInferable;

      if (configuration.isHidden === undefined) {
        configuration.isHidden =
          (!this.displaySingleValuedAttributes && isAttributeInferable) ||
          attribute.derived ||
          (!Utils.isRuleSetFamilyRuleSet(ruleSet) && !attribute.required && !isDeriverAttribute);
      }

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
        [MetadataKeys.isResourceAttribute]: isResourceAttribute,
        [MetadataKeys.isResourceDerivedAttribute]: isDeriverAttribute,
      };

      normalizedConfigurationMap[attribute.attributeKey.toLowerCase()] = configuration;

      /**
       * Handle containered attributes.
       */
      if (attribute.containerDeclarations !== undefined) {
        attribute.containerDeclarations.forEach((containerDeclaration: string) => {
          if (normalizedConfigurationMap[containerDeclaration.toLowerCase()] === undefined) {
            const containerConfiguration: any = {};

            containerConfiguration.metadata = {
              [AttributeModelExplorerFactory.METADATA_KEYS.v1RuleSet.classes]: [],
              [MetadataKeys.isDisplayed]: false,
            };

            normalizedConfigurationMap[containerDeclaration.toLowerCase()] = containerConfiguration;
          }
        });
      }
    });

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
