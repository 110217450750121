import { NumberLiteral, Range, ValueType } from '@cimpress-technology/attribute-model-explorer';
import { difference, flatten, isEmpty, isNil, uniq } from 'lodash';
import { add, bignumber, ceil, divide, multiply, round, square } from 'mathjs';

import { attributeKeys } from './Components/constants';

const NUMBER_TO_GET_HIGHER_VALID_VALUE = bignumber(100);

export default class AttributeRangeHelper {
  /**
   * It will respond with an array of validOptions from the ranges if the number of options are less than the count.
   * @param validRanges array of attribute ranges passed
   * @param count number of valid options that we want to limit against
   * @param attributeKey string containing the attribute key
   */
  public static getValidValuesFromRanges(validRanges: Range[], count: number, attributeKey: string): string[] {
    const validOptions: Set<string> = new Set();

    const validRangesClone = isEmpty(validRanges) ? [] : [...validRanges];

    for (const validRange of validRangesClone) {
      if (validRange.type === ValueType.NumberLiteral) {
        validOptions.add(validRange.numberLiteral);
        if (validOptions.size > count) {
          return [];
        }
      } else {
        let { minimum, maximum, increment } = validRange.range || validRange;

        minimum = minimum ? Number(minimum) : minimum;
        maximum = maximum ? Number(maximum) : Number.MAX_VALUE;
        // In case of quantities, if the increment is absent, assume it is 1. Else, we assume it is undefined.
        increment = !isNil(increment)
          ? Number(increment)
          : attributeKey.toLowerCase() === attributeKeys.QUANTITY
          ? 1
          : undefined;

        if (!this.validateRangeForValidLimits(minimum, maximum, increment, count)) {
          return [];
        }

        // Handling increment zero
        if (minimum === maximum || increment === 0) {
          validOptions.add(minimum);
          if (validOptions.size > count) {
            return [];
          }
        } else {
          let min = minimum;
          let precision = 0;

          if (Math.floor(increment) !== increment) {
            precision = increment.toString().split('.')[1].length;
          }

          while (min <= maximum && increment > 0) {
            validOptions.add(parseFloat(min).toFixed(precision));
            if (validOptions.size > count) {
              return [];
            }
            min = min + increment;
          }
        }
      }
    }

    return (
      Array.from(validOptions)
        // @ts-ignore
        .sort(this.sortNumber)
        .map(String)
    );
  }

  /**
   * It will calculate valid values from the provided ranges
   * @param validRanges
   * @param count how many valid ranges we want
   * @return array of valid ranges
   */
  public static getIntelligentRangeValues(validRanges: Range[], count: number): string[] {
    if (count === 1) {
      return this.getValidRanges(validRanges, count);
    }
    const [minimum, maximum] = this.findSmallestMinimum(validRanges);
    const minValue = minimum;
    const maxValue = maximum === Infinity ? minValue * 10000 : maximum;

    const generatedSmartNumberFragments: number[][] = this.generateSmartNumbersFragments(minValue, maxValue);
    let validValuesCount = 0;

    for (let i = 0; i < generatedSmartNumberFragments.length; i = i + 1) {
      generatedSmartNumberFragments[i] = generatedSmartNumberFragments[i].filter(value =>
        this.validateValueAgainstRanges(value, validRanges),
      );

      validValuesCount += generatedSmartNumberFragments[i].length;
    }

    let finalValues: number[] = [];
    if (validValuesCount > Math.ceil(count * 0.5)) {
      const delta = Math.max(Math.floor(validValuesCount / count), 1);
      const finalValuesSet: Set<number> = new Set();

      for (const fragment of generatedSmartNumberFragments) {
        for (let i = 0; i < fragment.length && finalValuesSet.size < count; i = i + delta) {
          finalValuesSet.add(fragment[i]);
        }
      }

      finalValues = Array.from(finalValuesSet);
    } else {
      // Fallback to bruteforce creation of valid values from ranges.
      finalValues = uniq(flatten(generatedSmartNumberFragments));
      const restValues = this.getValidRangesNumeric(validRanges, count + finalValues.length);
      const valuesToAdd = difference(restValues, finalValues);

      if (valuesToAdd && valuesToAdd.length > 0) {
        finalValues = finalValues.concat(valuesToAdd.slice(0, count - finalValues.length));
      }
    }

    return finalValues.sort(this.sortNumber).map(String);
  }

  /**
   * It will calculate valid values from the provided ranges
   * @param validRanges
   * @param count how many valid ranges we want
   * @return array of valid ranges
   */
  public static getValidRanges(validRanges: any[], count: number): string[] {
    return this.getValidRangesNumeric(validRanges, count).map(String);
  }

  /**
   * This will validate value against provided ranges, whether value lies in ranges or not.
   * @param value To be validate
   * @param validRanges To be Validate against
   * @param numberLiterals
   * @return boolean
   */
  public static validateValueAgainstRanges(value: number, validRanges: any[], numberLiterals: string[] = []) {
    let allValidRanges = [...validRanges];

    if (numberLiterals.length !== 0) {
      const numberLiteralRanges = numberLiterals.map(numberLiteral => {
        return { range: { minimum: numberLiteral, maximum: numberLiteral } };
      });

      allValidRanges = [...allValidRanges, ...numberLiteralRanges];
    }

    return allValidRanges.some(validRange => {
      const { minimum, maximum, increment } = validRange.range || validRange;

      return this.validateValueAgainstRange(minimum, maximum, increment, value);
    });
  }

  /**
   * It will calculate valid values from the provided ranges
   * @param validRanges
   * @param count how many valid ranges we want
   * @return array of valid ranges
   */
  private static getValidRangesNumeric(validRanges: any[], count: number): number[] {
    const values: math.BigNumber[] = [];
    let rangeIteration = 1;

    while (rangeIteration <= count) {
      validRanges.forEach(validRange => {
        let { minimum, maximum, increment } = validRange.range || validRange;

        if (validRange.type === ValueType.NumberLiteral) {
          minimum = maximum = validRange.numberLiteral;
        }

        minimum = minimum ? bignumber(minimum) : minimum;
        maximum = maximum ? bignumber(maximum) : maximum;
        increment = increment ? bignumber(increment) : increment;
        const rangeIterationBigNumber = bignumber(rangeIteration);

        if (!values.includes(minimum)) {
          values.push(minimum);
        }

        if (maximum && maximum.toNumber() !== Infinity) {
          const value = this.getValidValueIfRangeHaveMaximum(
            minimum,
            maximum,
            increment,
            count,
            rangeIterationBigNumber,
          );
          values.push(value);
        } else {
          const value = this.getValidValueIfRangeDoesNotHaveMaximum(minimum, increment, rangeIterationBigNumber);
          values.push(value);
        }
      });

      rangeIteration = rangeIteration + 1;
    }

    // Filtering undefined values and converting to number.
    const filteredValues = values.filter(value => value).map(value => value.toNumber());
    // Getting `count` uniq sorted values.
    const uniqValues = uniq(filteredValues)
      .sort(this.sortNumber)
      .slice(0, count);
    // Validating every value, just to avoid error in selector
    const validValues = uniqValues.filter(value => this.validateValueAgainstRanges(value, validRanges));

    return validValues;
  }

  /**
   * This function will validate whether all the values for type of ranges suggested from the are within limits.
   * @param minimum Minimum value of a single range input.
   * @param maximum Maximum value of a single range input.
   * @param increment increment of the given range
   * @param count Maximum number of options allowed for the limits.
   */
  private static validateRangeForValidLimits(
    minimum: number,
    maximum: number,
    increment: number,
    count: number,
  ): boolean {
    // We need to evaluate whether its undefined specificially because 0 is evaluated as falsey.
    if (increment === undefined) {
      // In case of decimals being allowed, break the loop
      if (minimum !== maximum) {
        return false;
      }
    } else {
      // If range options exceed count, break the loop.
      if (increment !== 0 && (maximum - minimum) / increment > count) {
        return false;
      }
    }
    return true;
  }

  /**
   * This will validate value against provided range, whether value lies in range or not.
   * @param minimum
   * @param maximum
   * @param increment
   * @param value
   * @return boolean
   */
  private static validateValueAgainstRange(
    minimum: number,
    maximum: number,
    increment: number,
    value: number,
  ): boolean {
    let valid: boolean = false;

    try {
      const numberLiteral = new NumberLiteral(value);
      const range = new Range(minimum, maximum, increment);
      valid = range.contains(numberLiteral);
    } catch (error) {
      // tslint:disable:no-console
      console.error(
        `Error while checking, value lies in range or not,
          Value: ${value}, Minimum: ${minimum}, Increment: ${increment}`,
        error,
      );
    }
    return valid;
  }

  private static getValidValueIfRangeHaveMaximum(
    minimum: math.BigNumber,
    maximum: math.BigNumber,
    increment: math.BigNumber,
    count: number,
    rangeIteration: math.BigNumber,
  ): any {
    let value;

    value = increment
      ? this.getValidValueIfRangeHaveMaximumAndIncrement(minimum, maximum, increment, count, rangeIteration)
      : this.getValidValueIfRangeHaveMaximumNotIncrement(minimum, maximum, rangeIteration);

    return value;
  }

  private static getValidValueIfRangeHaveMaximumAndIncrement(
    minimum: math.BigNumber,
    maximum: math.BigNumber,
    increment: math.BigNumber,
    count: number,
    rangeIteration: math.BigNumber,
  ): any {
    let value;
    /*
            Lets assume
            maximum = 4000;
            count = 6;
            increment = 4;

            The value is dependent on the number of count (how many valid values)
            Lets say we want 6 valid values from the ranges. And maximum is 4000 by increment of 4.
            We are dividing maximum by count (or by maximum itself whichever is lesser (sometimes maximum is lesser than count)), so that we can get 6(=count) valid ranges
            by dividing the maximum(4000) by count(6) we are making sure that we will get
            6(=count) valid different-different values, but here is a clause-- if increment value is more than 1 then we will never get our expected count(6)
            because we have to multily quotient with the increment to make it a valid value, so to make sure that we
            will get our expected count, we need to further divide it with increment (4000/6/4) = 166.66,
            Math.ceil(166.66) = 167, now to make it valid, just multiplying it with increment (167 * 4 = 668)

            And on every iteration we are multiplying it with the iteration index to get higher value
          */

    // Dividing maximum by count and getting ranges, and we will get valid value from each range
    const numberOfRanges = divide(maximum, count < maximum.toNumber() ? count : maximum);

    // @ts-ignore
    // Dividng (and ceiling the result) `numberOfRanges` with `increment` to get more accurate ranges, and we will get valid value from each range
    const safeNumberOfRanges = ceil(divide(numberOfRanges, increment));

    // Calculating valid value within range
    const validValue = multiply(safeNumberOfRanges, increment);

    // Increasing valid value as per iteration
    const validValueAsPerIteration = multiply(validValue, rangeIteration);

    // Add `minimum` to make it valid
    const validValueAfterAddingMinimum = add(minimum, validValueAsPerIteration);

    // @ts-ignore
    const validValueAsPerIterationNumber = validValueAfterAddingMinimum.toNumber();

    if (validValueAsPerIterationNumber > minimum.toNumber() && validValueAsPerIterationNumber <= maximum.toNumber()) {
      value = validValueAfterAddingMinimum;
    }

    return value;
  }

  private static getValidValueIfRangeHaveMaximumNotIncrement(
    minimum: math.BigNumber,
    maximum: math.BigNumber,
    rangeIteration: math.BigNumber,
  ): any {
    let value;
    // @ts-ignore
    const newValue = add(minimum, round(divide(maximum, rangeIteration)));

    // @ts-ignore
    if (newValue.toNumber() <= maximum.toNumber()) {
      value = newValue;
    }

    return value;
  }

  private static getValidValueIfRangeDoesNotHaveMaximum(
    minimum: math.BigNumber,
    increment: math.BigNumber,
    rangeIteration: math.BigNumber,
  ): math.BigNumber {
    let value: math.BigNumber;

    value = increment
      ? this.getValidValueIfRangeHaveIncrementNotMaximum(minimum, increment, rangeIteration)
      : this.generateValidValueWithMinimumIncrementAndRangeIteration(minimum, bignumber(1), rangeIteration);

    return value;
  }

  private static getValidValueIfRangeHaveIncrementNotMaximum(
    minimum: math.BigNumber,
    increment: math.BigNumber,
    rangeIteration: math.BigNumber,
  ): any {
    // if increment is less than `NUMBER_TO_GET_HIGHER_VALID_VALUE`, then need to get higher value
    const value =
      increment.toNumber() < NUMBER_TO_GET_HIGHER_VALID_VALUE.toNumber()
        ? this.generateValidValueWithMinimumIncrementAndRangeIteration(minimum, increment, rangeIteration)
        : add(minimum, multiply(increment, rangeIteration));

    return value;
  }

  private static generateValidValueWithMinimumIncrementAndRangeIteration(
    minimum: math.BigNumber,
    increment: math.BigNumber,
    rangeIteration: math.BigNumber,
  ): any {
    // Doing (minimum + (increment * rangeIteration * rangeIteration * NUMBER_TO_GET_HIGHER_VALID_VALUE)) to get higher value
    // @ts-ignore
    return add(minimum, multiply(increment, square(rangeIteration), NUMBER_TO_GET_HIGHER_VALID_VALUE));
  }

  private static sortNumber(a: number, b: number): number {
    return a - b;
  }

  private static findSmallestMinimum(ranges: any[]) {
    let smallestMinimum = Number.MAX_VALUE;
    let highestMaximum = Number.MIN_VALUE;
    ranges.forEach(range => {
      const { minimum, maximum } = range.range || range;
      if (smallestMinimum > minimum) {
        smallestMinimum = minimum;
      }
      if (maximum === null) {
        highestMaximum = Infinity;
      } else if (highestMaximum < maximum) {
        highestMaximum = maximum;
      }
    });

    return [smallestMinimum, highestMaximum];
  }

  private static generateSmartNumbersFragments(min: number, max: number): number[][] {
    let digitCount = max.toString().length;
    let roundBuilder = 1;
    const fragments: number[][] = [];
    let fragment: number[] = [];

    while (digitCount > 1) {
      roundBuilder = roundBuilder * 10;
      digitCount = digitCount - 1;
    }

    const cachedMax = roundBuilder;
    let tempMax = roundBuilder;

    let increment10 = Math.max(Math.ceil(tempMax / 10), min);

    while (tempMax >= Math.ceil(cachedMax / (min * 10)) && increment10 >= min) {
      fragment = [];

      for (let i = increment10; i < tempMax; i = i + increment10) {
        fragment.push(i);
      }

      fragments.unshift(fragment);

      tempMax = increment10;
      increment10 = increment10 / 10;
    }

    fragment = [];
    for (let k = roundBuilder; k <= max; k = k + roundBuilder / 2) {
      fragment.push(k);
    }
    fragments.unshift(fragment);

    fragment = [];
    fragment.push(+max);
    fragments.unshift(fragment);

    fragment = [];
    fragment.push(+min);

    if (increment10 > min) {
      fragment.push(increment10);
    }
    if (tempMax / 2 > min) {
      fragment.push(tempMax / 2);
    }

    fragments.unshift(fragment);

    return fragments;
  }
}
