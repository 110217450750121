/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';

import { FactIdentifiers, IfThenRuleConditionWithId } from '../../types';

export function getConditionGridStyles({
  fact,
  attributeValue,
  condition,
}: {
  fact?: string;
  attributeValue: any;
  condition: IfThenRuleConditionWithId;
}): SerializedStyles {
  if (fact === FactIdentifiers.options) {
    return gridStylesAllOptions;
  }
  if (attributeValue === 'routedTo') {
    return gridStylesRoutedTo;
  }
  if (condition.customSource) {
    return gridStylesCustomSource;
  }
  if (attributeValue === 'geocode') {
    return gridStylesGeocode;
  }
  if (attributeValue === 'successfulIfThens') {
    return gridStylesPassingIfThens;
  }

  return gridStylesStandard;
}

const gridStylesStandard = css`
  column-gap: 10px;
  display: grid;

  grid-template-columns: minmax(150px, 200px) 10px minmax(100px, 160px) minmax(150px, 200px) auto 40px;
  grid-template-areas:
    'parameter is operator value . delete'
    'joiner . . list-tip . .';

  @media (max-width: 700px) {
    grid-template-columns: minmax(150px, 200px) minmax(100px, 200px) auto auto 40px;
    grid-template-areas:
      'parameter is . . delete'
      'operator value . . .'
      'joiner list-tip . . .';
  }
`;

const gridStylesGeocode = css`
  ${gridStylesStandard};

  grid-template-columns: minmax(150px, 200px) minmax(100px, 200px) auto 40px;
  grid-template-areas:
    'parameter . . delete'
    'value value value .'
    'plus . . .'
    'joiner . . .';

  @media (max-width: 700px) {
    grid-template-areas:
      'parameter . . delete'
      'value . . .'
      'plus . . .'
      'joiner . . .';
  }
`;

const gridStylesPassingIfThens = css`
  ${gridStylesStandard};

  grid-template-columns: minmax(150px, 200px) minmax(100px, 200px) auto 40px;
  grid-template-areas:
    'parameter operator . delete'
    'value value value .'
    'plus . . .'
    'joiner . . .';

  @media (max-width: 700px) {
    grid-template-areas:
      'parameter operator . delete'
      'value value value .'
      'plus . . .'
      'joiner . . .';
  }
`;

const gridStylesRoutedTo = css`
  ${gridStylesStandard};

  grid-template-columns: 200px 200px 200px auto 40px;
  grid-template-areas:
    'parameter ordered-value result-type result-value . delete'
    'tip tip . . . .'
    'joiner . . . . .';

  @media (max-width: 1025px) {
    grid-template-columns: 200px 200px auto 40px;
    grid-template-areas:
      'parameter ordered-value . delete'
      'result-type result-value . .'
      'tip . . .'
      'joiner . . .';
  }
`;

const gridStylesCustomSource = css`
  ${gridStylesStandard};

  grid-template-columns: 200px 150px 300px auto 40px;
  grid-template-areas:
    'parameter source path is delete'
    'operator value . . .'
    'joiner . . . .';

  @media (max-width: 700px) {
    grid-template-columns: 200px 150px auto 40px;
    grid-template-areas:
      'parameter source . delete'
      'path is . .'
      'operator value . .'
      'joiner . . .';
  }
`;

const gridStylesAllOptions = css`
  ${gridStylesStandard};

  grid-template-columns: minmax(150px, 200px) 10px minmax(100px, 160px) 150px auto 40px;
  grid-template-areas:
    'parameter is value operator . delete'
    'filter-path-value . . . . .'
    'joiner . . . . .';

  @media (max-width: 700px) {
    grid-template-columns: minmax(150px, 200px) minmax(150px, 200px) auto auto 40px;
    grid-template-areas:
      'parameter is . . delete'
      'value operator . . .'
      'filter-path-value . . . .'
      'joiner . . . .';
  }
`;
