export const PRODUCT_SUPPORT_EMAIL = 'CTProductCatalogSupport@cimpress.com';
export const PRODUCT_SUPPORT_NAME = 'CT Product Support';

export const errorAlertMessages = {
  ERROR_CONVERTING_MERCHANT_PRODUCT: 'Error while converting Merchant Product',
  ERROR_FETCHING_PRODUCT: 'Error while fetching product for',
  MISCONFIGURED_PRODUCT: 'This product is misconfigured.',
  NO_SELECTION_ATTRIBUTES: 'This product has no selectable attributes.',
  SOMETHING_WENT_WRONG: 'The last selection that was made caused an unrecoverable error.',
  MALFORMED_RULESET: 'The product ruleset seems to be malformed.',
  MALFORMED_PRODUCT: 'The product seems to be malformed.',
  PRODUCT_DEFINITION_NOT_FOUND: 'Could not find product definition',
  ONLY_HIDDEN_PIVOT_ATTRIBUTES: 'Only hidden pivot attributes can be updated programatically.',
  INVALID_AUTH: 'Invalid auth token provided.',
  ATTRIBUTE_MODEL_INVALID_INPUT: 'Please provide either an attribute model resource url or attribute model resource id and its version',
  MALFORMED_PRODUCT_MODEL: 'Could not recognize product model',
  MALFORMED_VISTAPRINT_PRD: `Not a valid Vistaprint product`,
  INVALID_PRODUCT_CONFIGURATION: 'Product Configuration could not be found for the given Props.',
  SELECTOR_INITIALIZATION_FAILED: 'Failed to initialize the Selector:',
  CONFIGURATION_URL: {
    CREATION_ERROR: 'Error creating a configuration URL.',
    ATTRIBUTE_MODEL_ERROR: 'Cannot get a configuration URL for Attribute Model',
    SELECTOR_UNINITIALIZED: 'Cannot get a configuration URL until the Generic Selector is completely initialized.',
    NO_REFERENCE_ID: 'Cannot create a configuration URL for a product without a reference ID.',
    INCOMPLETE_PRODUCT_SELECTION: 'Cannot create a configuration URL for incomplete product selection.',
    SELECTOR_CREATION_FAILED: 'Could not create Selector with this Configuration.',
    NOT_FOUND: 'Configuration not found.'
  },
  RESOURCE: {
    SELECTOR_UNINITIALIZED: 'Cannot get a resource URL until the Generic Selector is completely initialized.',
    REQUIRED_ATTRIBUTES_NEEDED: 'Please select required attributes for resource.',
    UNRESOLVED: 'Product not resolved.',
    NO_OUTPUT_RESOURCE: 'Output Resource not provided.',
    NOT_SUPPORTED: 'Resource not supported',
  },
  UNRESOLVABLE_STATE: 'There are violation of rules on the product.',
  UNSUPPORTED_PROCESSOR: 'Unsupported processor for factory',
  ATTRIBUTE_MODEL_RESOURCE_REQUIRED_ATTRIBUTE_ERROR: 'Cannot get a resource required attributes for Attribute Model.',
  ATTRIBUTE_MODEL_RESOURCE_URL_ERROR: 'Cannot get a resource URL for Attribute Model.',
  REVERTING_SELECTION: 'Reverting to previous selections as this selection cannot be resolved',
  GET_REQUIRED_ATTRIBUTE: {
    SELECTOR_UNINITIALIZED: 'Cannot get required attributes until the Generic Selector is completely initialized.',
    NO_REFERENCE_ID: 'Cannot get required attributes for a product without a reference ID.',
    ATTRIBUTE_MODEL_ERROR: 'Cannot get required attributes for Attribute Model',
  },
  GET_REMAINING_OPTIONS: {
    SELECTOR_UNINITIALIZED: 'Cannot get remaining options until the Generic Selector is completely initialized.',
    NO_REFERENCE_ID: 'Cannot get remaining options for a product without a reference ID.',
    ATTRIBUTE_MODEL_ERROR: 'Cannot get remaining options for Attribute Model',
  }
};

export const ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY = 0;
export const FORMULA_DEFAULT_DISPLAY_PRIORITY = -50;
export const PIVOT_DEFAULT_DISPLAY_PRIORITY = 50;
export const QUANTITY_ATTRIBUTE_KEY = 'Quantity';

export const TRANSFORMATION_FROM_STRINGLITERAL = 'string';

export const MAX_OPTIONS_IN_DROPDOWN = 20;

export const attributeKeys = {
  QUANTITY: 'quantity',
  MCPSKU: 'mcpsku',
  PRODUCTION_TIME: 'production time',
  COUNTRY: 'country',
};

export const v2ProductAttributeTypes = {
  NUMBER: 'number',
  STRING: 'string',
}

export const v2ProductAttributeValueTypes = {
  RANGE: 'range',
  VALUE: 'value',
  FORMULA: 'formula'
}

export const REVERTING_SELECTION_ERROR_CODE = 10;

export const AMEXMetadata = {
  isSelectionUpdatedByInvalidSelection: 'isSelectionUpdatedByInvalidSelection'
}
