// @ts-ignore
// tslint:disable-next-line:no-submodule-imports
import IconPictureSun from '@cimpress-technology/react-streamline-icons/lib/IconPictureSun';
import { Spinner } from '@cimpress/react-components';
import { get } from 'lodash';
import React, { Component } from 'react';
import ColorSwatch from '../ColorSwatch';

import './DetailCard.css';

interface IDetailCardProps {
  attributeValue: string;
  label: string;
  imageUrl: string;
  isActive: boolean;
  isDisabled: boolean;
  styleClasses?: any;
  isError?: boolean;
  onClick: (event: any) => void;
  allowDisabledSelection?: boolean;
  isColorSwatch?: boolean;
}

interface IDetailCardState {
  isLoading: boolean;
}

export default class DetailCard extends Component<IDetailCardProps, IDetailCardState> {
  constructor(props: IDetailCardProps) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  public render() {
    const {
      label,
      imageUrl,
      isActive,
      isDisabled,
      isError,
      styleClasses,
      allowDisabledSelection,
      isColorSwatch,
    } = this.props;
    const { isLoading } = this.state;

    return (
      <div
        className={`detail-card-container ${
          isActive
            ? `card-container-active ${get(styleClasses, 'detailActiveCardContainer', '')}`
            : get(styleClasses, 'detailCardContainer', '')
        } ${isError ? 'card-container-error' : ''} ${
          isDisabled ? `card-container-disabled${allowDisabledSelection ? '-allowed' : ''}` : ''
        }`}
        onClick={this.onButtonClick}
      >
        <div className="detail-card-header" />
        {imageUrl ? (
          <React.Fragment>
            <div className="card-image-placeholder" style={{ display: isLoading ? 'flex' : 'none' }}>
              <Spinner size="medium" />
            </div>
            <img
              style={{ display: !isLoading ? 'block' : 'none' }}
              src={imageUrl}
              className="detail-card-image"
              onError={this.onImageLoad}
              onLoad={this.onImageLoad}
            />
          </React.Fragment>
        ) : (
          <div className="card-image-placeholder">
            <IconPictureSun size="2x" />
          </div>
        )}
        <div className={`detail-card-label ${get(styleClasses, 'detailCardLabel', '')}`}>
          {isColorSwatch ? (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              {' '}
              <ColorSwatch hexCode={label} /> {label}{' '}
            </span>
          ) : (
            label
          )}
        </div>
        <div className="detail-card-footer" />
      </div>
    );
  }

  private onButtonClick = () => {
    this.props.onClick(this.props.attributeValue);
  }

  private onImageLoad = () => {
    if (this.state.isLoading) {
      this.setState({ isLoading: false });
    }
  }
}
