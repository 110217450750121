import React from 'react';

import { ItemWithOrderMetadata } from '../../../services/items';
import { useAppSelector } from '../../../store/hooks';
import styles from './itemSelectorLabel.module.scss';

const ItemSelectorLabel = ({
  item,
  isLoading,
}: {
  item?: ItemWithOrderMetadata;
  isLoading: boolean;
}) => {
  const product = useAppSelector((state) => state.productConfiguration.product);

  return (
    <div className={styles.container}>
      {isLoading && <div>Loading...</div>}
      {item && (
        <div>
          <div className={styles.productLabel}>{product?.name ?? item?.mcpSku}</div>
          <span className={styles.itemId}>{'(' + item?.itemId + ')'}</span>
        </div>
      )}
    </div>
  );
};

export default ItemSelectorLabel;
