import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface tabState {
  selectedTabIndex: number;
}

const initialState: tabState = {
  selectedTabIndex: 0,
};

const tabSlice = createSlice({
  name: 'skuCode',
  initialState,
  reducers: {
    selectTabByIndex(state, action: PayloadAction<number>) {
      state.selectedTabIndex = action.payload;
    },
    selectBuilderTab(state) {
      state.selectedTabIndex = 0;
    },
    selectTesterTab(state) {
      state.selectedTabIndex = 1;
    },
  },
});

export const { selectTabByIndex, selectBuilderTab, selectTesterTab } = tabSlice.actions;
export default tabSlice.reducer;
