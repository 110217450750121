export const themeResolver = themeData => {
  const themeObject = {
    buttonContainer: {
      marginBottom: '15px',
    },
    tooltipContainer: {
      '& .buttonClass': {
        border: `1px solid ${themeData.globals.secondaryColor}`,
        color: themeData.globals.primaryColor,
        fontFamily: themeData.globals.fontFamily,
        fontSize: themeData.globals.fontSize,
        '&:hover': {
          border: `1px solid ${themeData.globals.secondaryColor}`,
          color: themeData.globals.primaryColor,
          fontFamily: themeData.globals.fontFamily,
          fontSize: themeData.globals.fontSize,
          background: themeData.globals.primaryHoverColor,
        },
        '&:active': {
          background: themeData.globals.primaryActiveColor,
          border: `1px solid ${themeData.globals.secondaryColor}`,
          color: themeData.globals.primaryColor,
          fontFamily: themeData.globals.fontFamily,
          fontSize: themeData.globals.fontSize,
          '&:hover': {
            background: themeData.globals.primaryHoverColor,
          },
        },
      },
      '& .buttonClass.active': {
        background: themeData.globals.primaryActiveColor ? themeData.globals.primaryActiveColor : '#92C8DA',
        border: `1px solid ${themeData.globals.secondaryColor}`,
        color: themeData.globals.primaryColor,
        fontFamily: themeData.globals.fontFamily,
        fontSize: themeData.globals.fontSize,
        '&:hover': {
          background: themeData.globals.primaryHoverColor,
        },
      },
      '& .btn.btn-outline-danger.active': {
        background: '#b73721',
        border: '1px solid #b73721',
      },
    },
    attributeLabelText: {
      color: themeData.globals.primaryColor,
      fontFamily: themeData.globals.fontFamily,
      fontSize: themeData.globals.fontSize,
    },
    dropdownContainer: {
      fontFamily: themeData.globals.fontFamily,
      fontSize: themeData.globals.fontSize,

      '& .number-select-input__single-value': {
        color: themeData.globals.primaryColor,
        // Added as temporary fix
        overflow: 'visible',
      },
      '& *': {
        color: themeData.globals.primaryColor,
      },
    },
    dropdownSelectValue: {
      '& > *': {
        border: `1px solid ${themeData.globals.secondaryColor}`,
        color: themeData.globals.primaryColor,
      },
    },
    dropdownOptionValue: {
      color: themeData.globals.primaryColor,
      '&.disabled': {
        opacity: 0.5,
      },
    },
    textInputWrapper: {
      '& > .textInputClass': {
        '& > div': {
          '& > input': {
            border: `1px solid ${themeData.globals.secondaryColor}`,
            color: themeData.globals.primaryColor,
            fontFamily: themeData.globals.fontFamily,
            fontSize: themeData.globals.fontSize,
          },
          '& > label': {
            color: themeData.globals.primaryColor,
            fontFamily: themeData.globals.fontFamily,
            fontSize: themeData.globals.fontSize,
            // Added as temporary fix
            overflow: 'visible',
          },
        },
      },
    },
    textInputWrapperActive: {
      '& > .textInputClass': {
        '& > div': {
          '& > input': {
            border: `1px solid ${themeData.globals.secondaryColor}`,
            color: themeData.globals.primaryColor,
            fontFamily: themeData.globals.fontFamily,
            fontSize: themeData.globals.fontSize,
          },
          '& > label': {
            color: themeData.globals.primaryColor,
            fontFamily: themeData.globals.fontFamily,
            // Added as temporary fix
            overflow: 'visible',
          },
        },
      },
    },
    resetAllContainer: {
      color: themeData.globals.primaryColor,
      fontFamily: themeData.globals.fontFamily,
      fontSize: themeData.globals.fontSize,
      '&:hover': {
        background: `${themeData.globals.primaryHoverColor} !important`,
        border: `1px solid ${themeData.globals.primaryHoverColor} !important;`,
        color: `${themeData.globals.primaryColor} !important`,
      },
      '&:focus': {
        color: `${themeData.globals.primaryColor} !important`,
      },
    },
    detailCardContainer: {
      border: `1px solid ${themeData.globals.secondaryColor}`,
      borderRadius: '6px',
      cursor: 'pointer',
      height: '240px',
      marginBottom: '10px',
      marginRight: '10px',
      padding: '6px',

      '&:hover': {
        border: `1px solid ${themeData.globals.primaryHoverColor}`,
      },
    },
    detailActiveCardContainer: {
      border: `1px solid ${themeData.globals.primaryActiveColor}`,
      borderRadius: '6px',
      borderBottom: `5px solid ${themeData.globals.primaryActiveColor}`,
      cursor: 'pointer',
      height: '240px',
      marginBottom: '10px',
      marginRight: '10px',
      padding: '6px',

      '&:hover': {
        border: `1px solid ${themeData.globals.primaryHoverColor}`,
        borderBottom: `5px solid ${themeData.globals.primaryHoverColor}`,
      },
    },
    detailCardLabel: {
      color: themeData.globals.primaryColor,
      fontFamily: themeData.globals.fontFamily,
      fontSize: themeData.globals.fontSize,
    },
    radioButtonContainer: {
      '&.active': {
        border: `1px solid ${themeData.globals.primaryActiveColor}`,
        borderBottom: `5px solid ${themeData.globals.primaryActiveColor}`,
      },
      '&:hover': {
        border: `1px solid ${themeData.globals.primaryHoverColor}`,
        borderBottom: `5px solid ${themeData.globals.primaryHoverColor}`,
        borderRadius: '9px',
      },
    },
    radioLabel: {
      color: themeData.globals.primaryColor,
      fontFamily: themeData.globals.fontFamily,
      fontSize: themeData.globals.fontSize,
    },
    radioButtonWrapper: {
      '& input:checked ~ .customRadio': {
        border: `1px solid ${themeData.globals.primaryActiveColor}`,
      },
      '& .customRadio:after': {
        background: themeData.globals.primaryActiveColor,
      },
    },
    detailHeaderContainer: {
      border: `1px solid ${themeData.globals.secondaryColor}`,
      color: themeData.globals.primaryColor,
      fontFamily: themeData.globals.fontFamily,
      fontSize: themeData.globals.fontSize,
      '& .detail-view-header': {
        background: themeData.globals.secondaryColor,
      },
    },
  };

  return {
    genericSelectorWrapper: {
      '& $buttonContainer': { ...themeObject.buttonContainer },
      '& $tooltipContainer': { ...themeObject.tooltipContainer },
      '& $attributeLabelText': { ...themeObject.attributeLabelText },
      '& $dropdownContainer': { ...themeObject.dropdownContainer },
      '& $dropdownSelectValue': { ...themeObject.dropdownSelectValue },
      '& $dropdownOptionValue': { ...themeObject.dropdownOptionValue },
      '& $textInputWrapper': { ...themeObject.textInputWrapper },
      '& $textInputWrapperActive': { ...themeObject.textInputWrapperActive },
      '& $resetAllContainer': { ...themeObject.resetAllContainer },
      '& $detailCardContainer': { ...themeObject.detailCardContainer },
      '& $detailActiveCardContainer': { ...themeObject.detailActiveCardContainer },
      '& $detailCardLabel': { ...themeObject.detailCardLabel },
      '& $radioButtonContainer': { ...themeObject.radioButtonContainer },
      '& $radioLabel': { ...themeObject.radioLabel },
      '& $radioButtonWrapper': { ...themeObject.radioButtonWrapper },
      '& $detailHeaderContainer': { ...themeObject.detailHeaderContainer },
    },

    buttonContainer: {},
    tooltipContainer: {},
    attributeLabelText: {},
    dropdownContainer: {},
    dropdownSelectValue: {},
    dropdownOptionValue: {},
    textInputWrapper: {},
    textInputWrapperActive: {},
    resetAllContainer: {},
    detailCardContainer: {},
    detailActiveCardContainer: {},
    detailCardLabel: {},
    radioButtonContainer: {},
    radioLabel: {},
    radioButtonWrapper: {},
    detailHeaderContainer: {},
  };
};
