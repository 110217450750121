import { DeserializedAttributeModelData } from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes, ISelection, ResourceType } from '@cimpress-technology/selector-resource-formatter';

import { IConfigurationMapBuilder, IProcessor, } from '..';
import { IAttributeConfigurationMap, } from '../../../Models';
import { errorAlertMessages } from '../../constants';
import IModelExplorerRepo from '../../Interfaces/IModelExplorerRepo';
import ModelExplorerRepo from '../../Repository/ModelExplorerRepo';

export default class SerializedModelProcessor implements IProcessor<DeserializedAttributeModelData, DeserializedAttributeModelData, DeserializedAttributeModelData> {

    private configurationMapBuilder: IConfigurationMapBuilder;

    public constructor(configurationMapBuilder: IConfigurationMapBuilder) {
        this.configurationMapBuilder = configurationMapBuilder;
    }

    public async getResourceRequiredAttributes(
        authToken: string,
        product: DeserializedAttributeModelData,
        selectionResource: ResourceType,
    ): Promise<IRequiredAttributes> {
        throw new Error(errorAlertMessages.ATTRIBUTE_MODEL_RESOURCE_REQUIRED_ATTRIBUTE_ERROR);
    }

    public getResource(
        product: DeserializedAttributeModelData,
        selectionResource: ResourceType,
        selections: ISelection[],
        requiredResourceAttributes: IRequiredAttributes,
    ): string {
        throw new Error(errorAlertMessages.ATTRIBUTE_MODEL_RESOURCE_REQUIRED_ATTRIBUTE_ERROR);
    }

    public createAttributeModelExplorer(
        product: DeserializedAttributeModelData,
        configurationMap: IAttributeConfigurationMap,
        requiredResourceAttributes?: IRequiredAttributes,
    ): IModelExplorerRepo {

        const attributeConfigurationMap = this.createAttributeConfigurationMap(
            product,
            configurationMap,
            requiredResourceAttributes,
        );

        return ModelExplorerRepo.createFromSerializedModel(product, attributeConfigurationMap);
    }

    private createAttributeConfigurationMap(
        product: DeserializedAttributeModelData,
        configurationMap: IAttributeConfigurationMap,
        requiredResourceAttributes?: IRequiredAttributes,
    ) {
        return this.configurationMapBuilder.buildConfigurationMap(product, configurationMap, requiredResourceAttributes);
    }
}
