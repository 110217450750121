import auth from '../auth';

export class ExtensibleError extends Error {
  additionalInfo: any;
  constructor(message, additionalInfo = null) {
    super(message);
    this.name = this.constructor.name;
    this.additionalInfo = additionalInfo;

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

export class ServiceHttpResponseError extends ExtensibleError {
  response: any;
  responseMessage: any;

  constructor(message, response) {
    const msg = `${message} - ${response.status} ${response.statusText || ''}\n\t${
      response.additionalDetails || ''
    }`;
    super(msg);

    this.response = response;
    if (response && response.body) {
      if (typeof response.body === 'object') {
        this.responseMessage = response.body.message;
      } else {
        this.responseMessage = response.body;
      }
    }
  }

  toString() {
    return `${this.message} - ${this.response.status} (${this.response.statusText})\n\t${
      this.response.additionalDetails || ''
    }`;
  }
}

export const buildOptions = (method = 'GET', body, additionalHeaders) => {
  const headers = new Headers({
    Accept: 'application/json',
    Authorization: `Bearer ${auth.getAccessToken()}`,
    ...additionalHeaders,
  });

  if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
    headers.append('Content-Type', 'application/json');
  }

  if (body && typeof body !== 'string') {
    body = JSON.stringify(body);
  }

  return {
    method,
    mode: 'cors',
    headers,
    body,
  };
};

export const checkResponse = async (res, serviceComment, isSimpleResponse) => {
  let body;
  if (res.ok) {
    if (res.status === 204) {
      return isSimpleResponse ? null : { response: res };
    }
    body = await res.json();
    return isSimpleResponse ? body : { response: res, body };
  }

  body = await (/application\/json/.test(res.headers.get('content-type'))
    ? res.json()
    : res.text());

  const response: Record<string, any> = {
    status: res.status,
    errorReason: body.errorReason,
    additionalDetails: body.additionalDetails,
    ok: res.ok,
  };

  throw new ServiceHttpResponseError(`Error fetching ${serviceComment}`, response);
};
