import _ from 'lodash';
import React, { useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { Select, Tooltip, colors } from '@cimpress/react-components';

import { ACTION_CONFIGURATIONS } from '../../constants/actionConfigurations';
import { ACTION_OPERATOR_SELECTION_VALUES } from '../../constants/actionOperatorSelectionValues';
import {
  Action,
  ActionOperator,
  ActionV3,
  ComparisonValueOperator,
  ConditionConfiguration,
  DisplayValue,
} from '../../types';
import { attributesForAction, operatorAcceptsCommaSeparatedList } from '../../utils/conversions';
import { addIndexesToDictionaryValues } from '../../utils/dictionaryUtils';
import { newDefaultAction } from '../../utils/newDefaultAction';
import {
  getRequiredInputStatus,
  getRequiredInputStatusOnMaybeFalseValue,
} from '../../utils/validation';
import AddElementButton from '../AddElementButton';
import RankedDictionaryTable from '../rankedDictionaryTable/RankedDictionaryTable';
import NothingConfigured from '../styledComponents/NothingConfigured';
import OperatorSelect from '../styledComponents/OperatorSelect';
import StyledTextField from '../styledComponents/StyledTextField';
import Tip from '../styledComponents/Tip';
import TrashIconButton from '../styledComponents/TrashIconButton';
import ValueDropdownSelect from '../styledComponents/ValueDropdownSelect';
import ValueSelectWrapper from '../styledComponents/ValueSelectWrapper';
import LegacyAlsoRoutesDisplay from './LegacyAlsoRoutesDisplay';
import SortDirectionComponent from './SortDirectionComponent';
import SortOrFilter from './SortOrFilter';
import styles from './actionSelection.module.scss';

const matchPathToActionConfig = (path: string) => {
  return _.find(ACTION_CONFIGURATIONS, (config) => config.path === path)!;
};

const validParameters = _.map(ACTION_CONFIGURATIONS, (actionConfig) => ({
  label: actionConfig.parameterLabel,
  value: actionConfig,
}));

const defaultAttributes = {
  allowedOperators: [],
  parameterIdentifier: '',
  parameterLabel: '',
  allowedValues: [] as DisplayValue<string>[],
  requiresTextInput: false,
  inputType: 'text',
};

export default function ActionSelection({
  action,
  onActionUpdate,
  focusedInput,
  onFocus,
  onDelete,
  notActionConfiguredText,
  fulfillers,
  fulfillmentExpectations,
}: {
  action?: Action;
  onActionUpdate: (action: Action) => void;
  focusedInput: string | null;
  onFocus: (id: string | null) => void;
  onDelete: (actionId: string) => void;
  notActionConfiguredText: string;
  fulfillers?: Record<string, DisplayValue<string>>;
  fulfillmentExpectations?: Record<string, DisplayValue<string>>;
}) {
  const valueFocusId = `action-value-${action?.id}`;

  const {
    allowedOperators: operators,
    parameterIdentifier: actionValue,
    parameterLabel: label,
    requiresTextInput,
    inputType,
    allowedValues,
  } = action ? attributesForAction(action) || defaultAttributes : defaultAttributes;

  const validOperators = useMemo(
    () => _.map(operators, (operator) => ACTION_OPERATOR_SELECTION_VALUES[operator]),
    [JSON.stringify(operators)],
  );

  const formattedAllowedValues = useMemo(
    () =>
      _.map(allowedValues, (v) => ({
        label: `${v}`,
        value: v,
      })),
    [JSON.stringify(allowedValues)],
  );

  const updateParentWithChanges = (updates: Record<string, any>) =>
    onActionUpdate(_.merge(action, updates));

  const setSortOrFilter = (sortOrFilter: string) => {
    const updates: Record<string, any> = {
      type: sortOrFilter,
    };
    if (sortOrFilter === 'filter') {
      updates.cause = '';
    }
    updateParentWithChanges(updates);
  };

  const handleUserTypeIntoTextfield = (e: React.ChangeEvent<HTMLInputElement>) =>
    updateParentWithChanges({
      value: e.target.value,
    });

  const setSortDirection = (direction: string) => updateParentWithChanges({ direction });

  const setActionParameter = ({ value: actionConfig }: { value: ConditionConfiguration }) => {
    if (!action) {
      return;
    }
    updateParentWithChanges({
      path: actionConfig.path,
      value: initialValueForNewParameter(action, actionConfig),
      operator: initialOperatorForNewParameter(actionConfig),
    });
  };

  const handleSelectOperator = ({ value }: ComparisonValueOperator) => {
    if (!action) {
      return;
    }
    updateParentWithChanges({
      operator: value,
      value: initialValueForNewOperator(action),
    });
  };

  const initialOperatorForNewParameter = (actionConfig: ConditionConfiguration) => {
    if (action?.type === 'alsoRoutesLegacy') {
      return undefined;
    }
    if (
      (action as ActionV3)?.operator &&
      _.includes(actionConfig.allowedOperators, (action as ActionV3).operator)
    ) {
      return (action as ActionV3).operator;
    }

    return actionConfig.defaultOperator;
  };

  const addAction = () => updateParentWithChanges(newDefaultAction());

  const handleDictionaryUpdate = (dictionary: Record<string, any>) => {
    const updatedAction = _.cloneDeep(action)!;
    updatedAction.value = dictionary;
    onActionUpdate(updatedAction);
  };

  const handleSelectValue = ({ value }: { value: any }) => {
    updateParentWithChanges({
      value,
    });
  };

  const handleMultiSelectValue = (values: DisplayValue<string>[]) => {
    onActionUpdate({
      ...action,
      value: _.map(values, 'value'),
    } as Action);
  };

  const handleCauseUpdate = (value: DisplayValue<string>) =>
    onActionUpdate({
      ...action,
      cause: value?.value,
    } as Action);

  const createValueSelect = () => {
    // This gets handled separately
    if (action?.type === 'alsoRoutesLegacy') {
      return null;
    }

    if (action?.operator === ActionOperator.valueInDictionary) {
      const dictionary = addIndexesToDictionaryValues(action.value || {});
      return (
        <RankedDictionaryTable
          actionId={action.id}
          dictionary={dictionary}
          updateDictionary={handleDictionaryUpdate}
          focusedInput={focusedInput}
          onFocus={onFocus}
          allowTextInput={action.path === ACTION_CONFIGURATIONS.skuCode.path}
          fulfillers={fulfillers}
        />
      );
    }

    if (action?.operator === ActionOperator.valueAtPath) {
      // We don't need a value to compare against if we're using valueAtPath
      return <></>;
    }

    if (action?.path === ACTION_CONFIGURATIONS.fulfillerId.path) {
      const currentValue = _.isArray(action?.value) ? action.value : [];
      const [currentlySelected, remaining] = _.partition(_.map(fulfillers), (displayValue) =>
        _.includes(currentValue, displayValue.value),
      );

      return (
        <ValueDropdownSelect
          label="Value"
          value={currentlySelected}
          onChange={handleMultiSelectValue}
          status={getRequiredInputStatusOnMaybeFalseValue(actionValue)}
          options={remaining}
          isMulti
          isClearable
          required
        />
      );
    }

    if (action?.operator === ActionOperator.in && !requiresTextInput) {
      const currentValue = _.isArray(action?.value) ? action.value : [];
      const currentlySelected = _.map(currentValue, (selectedResource) => ({
        label: selectedResource,
        value: selectedResource,
      }));

      return (
        <ValueSelectWrapper
          selectedSelect={CreatableSelect}
          value={currentlySelected}
          components={{ DropdownIndicator: null }}
          label={currentValue.length === 0 ? 'Enter Value' : ''}
          onChange={handleMultiSelectValue}
          status={getRequiredInputStatusOnMaybeFalseValue(actionValue)}
          options={[]}
          isMulti
          isClearable
          required
        />
      );
    }

    if (requiresTextInput) {
      return (
        <>
          <StyledTextField
            size="lg"
            name="userInput"
            value={action?.value}
            label="Custom value"
            required
            onChange={handleUserTypeIntoTextfield}
            type={inputType as any}
            onFocus={() => onFocus(valueFocusId)}
            autoFocus={focusedInput === valueFocusId}
            onBlur={() => onFocus(null)}
            status={getRequiredInputStatusOnMaybeFalseValue(action?.value)}
          />
          {operatorAcceptsCommaSeparatedList(action?.operator) && (
            <Tip className={styles.listTip}>You can enter multiple values separated by commas</Tip>
          )}
        </>
      );
    }

    return (
      <ValueDropdownSelect
        label="Value"
        value={{ label: `${action?.value}`, value: action?.value }}
        onChange={handleSelectValue}
        required
        status={getRequiredInputStatusOnMaybeFalseValue(actionValue)}
        options={formattedAllowedValues}
      />
    );
  };

  const selectedCause =
    action && action.type === 'filter' && action.cause
      ? _.find(fulfillmentExpectations, (fe) => fe.value === action.cause) || {
          label: action.cause,
          value: action.cause,
        }
      : undefined;

  return (
    <>
      {!action && (
        <>
          <NothingConfigured>{notActionConfiguredText}</NothingConfigured>
          <AddElementButton onClick={addAction} text="Create action" />
        </>
      )}

      {!!action && action.type === 'alsoRoutesLegacy' && (
        <div>
          <Select
            className={styles.parameter}
            label="Parameter"
            value={{ label, value: actionValue }}
            status={getRequiredInputStatus(actionValue)}
            isDisabled
            required
          />
          <LegacyAlsoRoutesDisplay value={action?.value || {}} />
        </div>
      )}

      {!!action && action.type !== 'alsoRoutesLegacy' && (
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <SortOrFilter actionType={action?.type || 'sort'} setActionType={setSortOrFilter} />
            <Select
              className={styles.parameter}
              label="Parameter"
              value={{ label, value: actionValue }}
              options={validParameters}
              onChange={setActionParameter}
              required
              status={getRequiredInputStatus(actionValue)}
              minMenuHeight={10}
            />
            <OperatorSelect
              label="Comparison"
              value={ACTION_OPERATOR_SELECTION_VALUES[action.operator] || ''}
              options={validOperators}
              onChange={handleSelectOperator}
              status={getRequiredInputStatus(action.operator)}
              minMenuHeight={10}
            />
            {action?.type === 'sort' && (
              <SortDirectionComponent
                sortDirection={action.direction || 'asc'}
                setSortDirection={setSortDirection}
              />
            )}
            {createValueSelect()}
            {action?.type === 'filter' && (
              <div className={styles.flexContainer}>
                <Select
                  value={selectedCause}
                  label={action?.cause ? '' : 'Cause'}
                  onChange={handleCauseUpdate}
                  status={getRequiredInputStatusOnMaybeFalseValue(actionValue)}
                  options={fulfillmentExpectations ? Object.values(fulfillmentExpectations) : []}
                  isClearable
                  required={action?.type === 'filter'}
                />
                <Tooltip
                  style={tooltipStyle as any}
                  direction="bottom"
                  contents={
                    <p>
                      &quot;Cause&quot; is typically a{' '}
                      <a
                        href="https://cimpress-support.atlassian.net/wiki/spaces/CI/pages/305794459/Fulfillment+Expectations"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Fulfillment Expectation
                      </a>{' '}
                    </p>
                  }
                >
                  <IconInformationCircle
                    weight="fill"
                    color={colors.info.base}
                    className={styles.icon}
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div className={styles.deleteContainer}>
            <TrashIconButton onDelete={() => onDelete(action.id)} />
          </div>
        </div>
      )}
    </>
  );
}

// This will not run for legacy also routes, since the fields are readonly
// and cannot be updated
const initialValueForNewOperator = (action: Action) => {
  if (!action) {
    return;
  }
  const actionConfig = matchPathToActionConfig((action as ActionV3)!.path);
  // If the new parameter requires text input, and we have text, just keep it
  if (actionConfig.requiresTextInput) {
    if (actionConfig.inputType === 'text') {
      return typeof action.value === 'string' ? action.value : actionConfig.defaultValue;
    }
    if (actionConfig.inputType === 'number') {
      return _.isNaN(Number(action.value)) ? actionConfig.defaultValue : Number(action.value);
    }
  }

  return actionConfig.defaultValue;
};

const initialValueForNewParameter = (action: Action, conditionConfig: ConditionConfiguration) => {
  if (!action) {
    return;
  }

  // If there are no allowed values... then there shouldn't be any value!
  if (!conditionConfig?.allowedValues?.length) {
    return '';
  }

  // For boolean choices, just pick one; we might save some clicks
  if (conditionConfig?.allowedValues?.length === 2) {
    return conditionConfig.allowedValues[0];
  }

  // If the new parameter requires text input, and we have text, just keep it
  if (conditionConfig.requiresTextInput) {
    if (conditionConfig.inputType === 'text') {
      return typeof action.value === 'string' ? action.value : conditionConfig.defaultValue;
    }
    if (conditionConfig.inputType === 'number') {
      return _.isNaN(Number(action.value)) ? conditionConfig.defaultValue : Number(action.value);
    }
  }

  return '';
};

const tooltipStyle = {
  height: '14px',
  padding: '0 !important',
  position: 'relative',
  top: 'calc((50px - 14px) / 2)',
  zIndex: 6,
};
