/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Select } from '@cimpress/react-components';

export default function ConditionJoiner({
  joiner,
  setJoiner,
  enabled,
}: {
  joiner: 'all' | 'any';
  setJoiner: (joiner: 'all' | 'any') => void;
  enabled: boolean;
}) {
  const options = [
    { label: 'And', value: 'all' },
    { label: 'Or', value: 'any' },
  ];
  const selectedOption = options.find((opt) => opt.value === joiner);
  const handleChange = ({ value }) => {
    setJoiner(value);
  };
  return (
    <Select
      label="Join with"
      value={selectedOption}
      options={options}
      onChange={handleChange}
      css={selectStyles}
      isDisabled={!enabled}
    />
  );
}

const selectStyles = css`
  grid-area: joiner;
  height: 50px;
  width: 100px;
`;
