import {
  IfThenRuleCondition,
  Operator,
  RoutingConfigurationPostBody,
  RoutingConfigurationPutBody,
  RoutingConfigurationV3,
  Rule,
} from '../types';
import fetchWithAuth from '../utils/fetchWithAuth';

const baseUrl = process.env.REACT_APP_ROUTING_CONFIGURATION_URL as string;

type RoutingConfigurationResponseWithEtag = {
  configuration: RoutingConfigurationV3;
  etag: string;
};

const formatCondition = (condition: IfThenRuleCondition): IfThenRuleCondition => {
  if ((condition.operator as any) === 'cmrd') {
    return {
      ...condition,
      operator: Operator.equal,
    };
  }
  return condition;
};

const formatRule = (rule: Rule): Rule => {
  const ruleToReturn = {
    ...rule,
  };

  if (rule.conditions.all) {
    ruleToReturn.conditions.all = rule.conditions.all.map((condition) =>
      formatCondition(condition),
    );
  }
  if (rule.conditions.any) {
    ruleToReturn.conditions.any = rule.conditions.any.map((condition) =>
      formatCondition(condition),
    );
  }
  if (rule.conditions.not) {
    ruleToReturn.conditions.not = rule.conditions.not.map((condition) =>
      formatCondition(condition),
    );
  }
  return ruleToReturn;
};

const formatRoutingConfig = (
  unformattedRoutingConfig: RoutingConfigurationV3,
): RoutingConfigurationV3 => ({
  ...unformattedRoutingConfig,
  ifThens: unformattedRoutingConfig.ifThens.map((ifThen) => ({
    ...ifThen,
    rule: formatRule(ifThen.rule),
  })),
});

export async function getRoutingConfigurationById(
  id: string,
): Promise<RoutingConfigurationResponseWithEtag> {
  const endpointUrl = new URL(`/v3/routingConfigurations/${id}`, baseUrl);

  const res = await fetchWithAuth<any>({
    endpointUrl: endpointUrl.toString(),
    method: 'GET',
    giveSimpleResponse: false,
    additionalHeaders: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

  // There's no default configuration supplied
  // if we search by ID and get a 404
  const configuration = res.body;

  return {
    configuration: formatRoutingConfig(configuration),
    etag: res.response.headers.get('etag'),
  };
}

export async function getRoutingConfigurationByBuyerAndSku({
  buyer,
  skuCode,
}): Promise<RoutingConfigurationResponseWithEtag> {
  const endpointUrl = new URL('/v3/routingConfigurations', baseUrl);
  endpointUrl.searchParams.set('accountId', buyer);
  endpointUrl.searchParams.set('referenceId', skuCode);
  // This will get us a default configuration
  // if there isn't one for buyer + sku
  endpointUrl.searchParams.set('onlyCurrent', 'true');

  const res = await fetchWithAuth<any>({
    endpointUrl: endpointUrl.toString(),
    method: 'GET',
    giveSimpleResponse: false,
    additionalHeaders: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

  const configuration = res.body._embedded.item[0];

  return {
    configuration: formatRoutingConfig(configuration),
    etag: res.response.headers.get('etag'),
  };
}

export async function createRoutingConfiguration(
  body: RoutingConfigurationPostBody,
): Promise<RoutingConfigurationResponseWithEtag> {
  const endpointUrl = new URL('/v3/routingConfigurations:createWithMapping', baseUrl).toString();

  const res = await fetchWithAuth<any>({
    endpointUrl,
    method: 'POST',
    body,
    giveSimpleResponse: false,
  });

  return {
    configuration: res.body,
    etag: res.response.headers.get('etag'),
  };
}

export async function updateRoutingConfiguration({
  id,
  body,
  entityTag,
}: {
  id: string;
  body: RoutingConfigurationPutBody;
  entityTag: string;
}): Promise<RoutingConfigurationResponseWithEtag> {
  const endpointUrl = new URL(`/v3/routingConfigurations/${id}`, baseUrl).toString();

  const res = await fetchWithAuth<any>({
    endpointUrl,
    method: 'PUT',
    body,
    giveSimpleResponse: false,
    additionalHeaders: {
      'If-Match': entityTag,
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

  return {
    configuration: res.body,
    etag: res.response.headers.get('etag'),
  };
}
