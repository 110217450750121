import React from 'react';

import StyledTextField from '../styledComponents/StyledTextField';
import styles from './testResults.module.scss';

export default function TestResults() {
  return (
    <div className={styles.container}>
      <StyledTextField value="" onChange={() => {}} disabled />
    </div>
  );
}
