import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';

const MODEL_COMPOSER_SERVICE_CLIENT_URL = 'https://composed.products.cimpress.io';

/**
 * Client to interact with the Model Composer Service Client
 */
export default class ModelComposerServiceClient {
    /**
     * @property authToken - The OAuth JWT to authenticate requests with.
     */
    private readonly authToken: string;

    constructor(authToken: string) {
        this.authToken = authToken;
    }

    /**
     * Gets the serialized model for the input provided
     * @param  {string} productId? Product id for serialized model that can be straight away fetched
     * @param  {string} version? version of the product
     * @returns Promise
     */
    public async getSerializedProduct(productId: string, version: string): Promise<any> {
        let requestUrl: string;

        if (productId && version) {
            requestUrl = `${MODEL_COMPOSER_SERVICE_CLIENT_URL}/v1/products/${productId}/versions/${version}:compose`;
        } else {
            throw new Error(`${errorAlertMessages.ATTRIBUTE_MODEL_INVALID_INPUT}`);
        }

        const response = await fetch(requestUrl, {
            headers: {
                accept: 'application/octet-stream',
                authorization: `Bearer ${this.authToken}`,
                origin: 'https://developer.cimpress.io',
            },
            method: 'get',
        });

        switch (response.status) {
            case statusCodes.OK:
                return response;
            case statusCodes.NOT_FOUND:
                throw new Error(`Could not find attribute model resource ${requestUrl}`);
            case statusCodes.UNAUTHORIZED:
                throw new Error(errorAlertMessages.INVALID_AUTH);
            default:
                throw new Error(`${errorAlertMessages.ATTRIBUTE_MODEL_RESOURCE_URL_ERROR} ${requestUrl}`);
        }
    }
}
