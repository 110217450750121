import parse from 'html-react-parser';
import React from 'react';
import './GroupView.css';

export interface IGroupViewProps {
  name?: string;
  description?: string;
  children?: React.ReactNode;
}

// tslint:disable-next-line: variable-name
const GroupView = ({ name, description, children }: IGroupViewProps) => (
  <div className="group-container">
    <div className="group-title">{name ? parse(name) : ''}</div>
    <div className="group-description">{description ? parse(description) : ''}</div>
    <div>{children}</div>
  </div>
);

export default GroupView;
