import React from 'react';

import { IfThenProvider } from './IfThenContext';
import { RoutingConfigurationProvider } from './RoutingConfigurationContext';

export function AppContextProvider({ children }) {
  return (
    <RoutingConfigurationProvider>
      <IfThenProvider>{children}</IfThenProvider>
    </RoutingConfigurationProvider>
  );
}
