import findAttributeDependencies from '@cimpress-technology/attribute-dependencies';
import { IAttribute, V1AttributeTypes, ValueType } from '@cimpress-technology/attribute-model-explorer';
// @ts-ignore
import { constants } from '@cimpress-technology/math-evaluation-helper';
import { IRequiredAttributes } from '@cimpress-technology/selector-resource-formatter';
import parse from 'html-react-parser';
import { get } from 'lodash';
import { attributeKeys, errorAlertMessages, v2ProductAttributeValueTypes } from './Components/constants';
import { IGenericSelectorProps, ProductDefinition } from './Components/Interfaces/';
import IModelExplorerRepo from './Components/Interfaces/IModelExplorerRepo';
import { ProductModel } from './Components/Processors/AttributesProcessorFactory';
import {
  IAttributeConfiguration,
  IAttributeConfigurationMap,
  IAttributeSpecifications,
  IResourceRequiredAttributes,
  MetadataKeys,
} from './Models';

function getUniqueValues(arr: string[]): string[] {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

/**
 * Find all dependencies for a given attribute
 * @param attribute - The attribute to find dependencies for
 * @param ruleSet - The ruleSet to search within.
 * @return Array of dependencies need to calculate the given attribute, including the
 *  attribute itself
 */

/**
 * Given a rule set and set of custom required attributes populate the custom required list with
 * those attribute's dependencies.
 * @param ruleSet The v1 rule set to pull attributes from
 * @param customRequiredAttributes The original set of custom required attributes
 * @return Full string array of custom required attributes
 */
function populateCustomRequired(ruleSet: any, customRequiredAttributes: string[] = []): string[] {
  const fullCustomRequired: string[] = [];
  customRequiredAttributes.forEach(customRequiredAttribute => {
    fullCustomRequired.push(...findAttributeDependencies(customRequiredAttribute, ruleSet));
  });

  return getUniqueValues(fullCustomRequired);
}

/**
 * Given a rule set and find dependencies of all the provided attributes
 * @param ruleSet The v1 rule set to pull attributes from
 * @param attributes The set of attributes for those dependencies need to find
 * @return Full string array of dependendent attributes
 */
export function findAttributesDependencies(ruleSet: any, attributes: string[] = []): string[] {
  const fullCustomRequired: string[] = [];

  attributes.forEach(attribute => {
    fullCustomRequired.push(...findAttributeDependencies(attribute, ruleSet, ProductModel.V1));
  });

  return getUniqueValues(fullCustomRequired);
}

/**
 * Get Array of selectable selections
 * @param attributeModelExplorer
 * @return array of IAttribute
 */
function getSelectableAttributes(attributeModelExplorer: IModelExplorerRepo): IAttribute[] {
  const selectableAttributes = attributeModelExplorer
    .getAttributes()
    .filter((a: any) => a.metadata.get(MetadataKeys.isDisplayed));

  return selectableAttributes;
}

/**
 * Get Array of selectable pivot attribute selections
 * @param attributeModelExplorer
 * @return array of IAttribute
 */
function getSelectablePivotAttributes(attributeModelExplorer: IModelExplorerRepo): IAttribute[] {
  const selectablePivotAttributes = attributeModelExplorer
    .getPivotAttributes()
    .filter((a: any) => a.metadata.get(MetadataKeys.isDisplayed));

  return selectablePivotAttributes;
}

export default class Utils {
  /**
   * Take a set of custom required attributes, find those attribute's dependencies, and modify the v1 rule set
   * to have all of those attributes as required.
   * @param v1RuleSet The v1 rule set to pull attributes from
   * @param customRequiredAttributes The original set of custom required attributes
   * @return The modified v1 rule set
   */
  public static applyCustomRequiredAttributesToV1RuleSet(v1RuleSet: any = {}, customRequiredAttributes: string[] = []) {
    const fullNormalizedRequiredAttributeKeys = populateCustomRequired(v1RuleSet, customRequiredAttributes).map(key =>
      key.toLowerCase(),
    );

    v1RuleSet.whiteList.forEach((attribute: any) => {
      attribute.required = fullNormalizedRequiredAttributeKeys.includes(attribute.attributeKey.toLowerCase());
    });

    return v1RuleSet;
  }

  public static isV1AttributeInferable(v1Attribute: any): boolean {
    let isAttributeInferable = false;

    if (v1Attribute.type === V1AttributeTypes.listOfValues) {
      isAttributeInferable = v1Attribute.attributeValues && v1Attribute.attributeValues.length === 1;
    } else if (v1Attribute.type === V1AttributeTypes.listOfRanges) {
      const ranges = v1Attribute.attributeRanges;

      isAttributeInferable = ranges && ranges.length === 1 && ranges[0].minimum === ranges[0].maximum;
    } else if (v1Attribute.type === V1AttributeTypes.formula) {
      isAttributeInferable = true;
    }

    return isAttributeInferable;
  }

  public static isSerializedAttributeInferable(attributeValue: any[]): boolean {

    if (attributeValue.length === 1) {
      const value = attributeValue[0];
      const isValueRange = value.type === v2ProductAttributeValueTypes.RANGE;
      if (isValueRange && value.range.hasOwnProperty('minimum') && value.range.minimum !== value.range.maximum) {
        return false;
      }

      return true;
    }

    return false;
  }

  /**
   * This function will check that given ruleSet is family ruleset or not.
   * If ruleset is having `mcpsku` attribute in its whitelist then we are considering it as family ruleset.
   * @param ruleSet
   * @return boolean
   */
  public static isRuleSetFamilyRuleSet(ruleSet: any): boolean {
    let mcpSkuAttribute;
    if (ruleSet && ruleSet.whiteList) {
      mcpSkuAttribute = ruleSet.whiteList.find(
        (attribute: any) => attribute.attributeKey.toLowerCase() === attributeKeys.MCPSKU,
      );
    }

    return mcpSkuAttribute !== undefined;
  }

  /**
   * This function will create AttributeConfigurationMap from AttributeModelExplorer using the selectable attributes.
   * @param attributeModelExplorer
   * @return AttributeModelExplorer
   */
  public static createAttributeConfigurationMapFromAMEx(attributeModelExplorer: IModelExplorerRepo) {
    const attributeConfigurationMap: IAttributeConfigurationMap = {};

    const selectableAttributes = getSelectableAttributes(attributeModelExplorer);
    const selectablePivotAttributes = getSelectablePivotAttributes(attributeModelExplorer);

    selectableAttributes.forEach((selectableAttribute: any) => {
      const attributeConfiguration: IAttributeConfiguration = {
        displayPriority: selectableAttribute.metadata.get(MetadataKeys.displayPriority),
        initialSelection: selectableAttribute.getResolvedValue(),
        isHidden: false,
        isPivot: false,
      };
      attributeConfigurationMap[selectableAttribute.key] = attributeConfiguration;
    });

    selectablePivotAttributes.forEach((selectablePivotAttribute: any) => {
      const attributeConfiguration: IAttributeConfiguration = {
        displayPriority: selectablePivotAttribute.metadata.get(MetadataKeys.displayPriority),
        initialSelection: selectablePivotAttribute.assignedValues,
        isHidden: false,
        isPivot: true,
      };
      attributeConfigurationMap[selectablePivotAttribute.key] = attributeConfiguration;
    });

    return attributeConfigurationMap;
  }

  /**
   * It will extract MCPSKU id from the ruleset only if rule set is mcpsku rule set or if it is family rule set then it should have
   * only one mcpsku
   * @param ruleSet
   * @return mcpsku reference id
   */
  public static getMcpSkuFromRuleSet(ruleSet?: any): string {
    const mcpsku = ruleSet.whiteList.find(
      (attribute: any) => attribute.attributeKey.toLowerCase() === attributeKeys.MCPSKU,
    );

    if (mcpsku) {
      return mcpsku.attributeValues.length === 1 ? mcpsku.attributeValues[0].value : undefined;
    }

    return ruleSet.referenceId;
  }

  public static isRequiredAttribute(resourceRequiredAttributes: IRequiredAttributes, attributeKey: string) {
    if (resourceRequiredAttributes) {
      if (
        (resourceRequiredAttributes.productAttributes || []).find(
          attribute =>
            !attribute.allowUnresolved && attribute.attributeName.toLowerCase() === attributeKey.toLowerCase(),
        ) ||
        (resourceRequiredAttributes.nonProductAttributes || []).find(
          attribute => attribute.toLowerCase() === attributeKey.toLowerCase(),
        )
      ) {
        return true;
      }
    }

    return false;
  }

  public static generatePseudoUniqueKey(value: string) {
    return `${value}-${Math.floor(Math.random() * 0xffff)}`;
  }

  public static identifyProductModel(product: ProductDefinition): ProductModel {
    if (product.hasOwnProperty('serializedData') && product.hasOwnProperty('attributeMetadata') ||
      product.hasOwnProperty('attributeInformations') && product.hasOwnProperty('serializedAttributeModel')) {
      return ProductModel.SerializedModel;
    }

    if (
      product.hasOwnProperty('quantity') ||
      product.hasOwnProperty('options') ||
      product.hasOwnProperty('properties')
    ) {
      return ProductModel.V2;
    }

    if (product.hasOwnProperty('whiteList')) {
      return ProductModel.V1;
    }

    if (product.hasOwnProperty('attributes')) {
      return ProductModel.AttributeModel;
    }

    throw new Error(errorAlertMessages.MALFORMED_PRODUCT_MODEL);
  }

  public static getProductAffectingFromProps(props: IGenericSelectorProps) {
    return {
      authToken: props.authToken,
      configurationUrl: props.configurationUrl,
      enableSerialization: props.enableSerialization,
      product: props.product,
      productId: props.productId,
      productVersion: props.productVersion,
    };
  }

  public static getAMExAffectingConfigProps(props: IGenericSelectorProps) {
    return {
      attributeConfigurations: props.attributeConfigurations,
      authToken: props.authToken,
      displaySingleValuedAttributes: props.displaySingleValuedAttributes,
      enableSerialization: !!props.enableSerialization,
      resourceRequiredAttributes: props.resourceRequiredAttributes,
      selectWithProductAttributes: props.selectWithProductAttributes,
      selectionResource: props.selectionResource,
    };
  }

  public static getAttributeDisplayLabel(
    attributeName: string,
    attributeSpecifications?: IAttributeSpecifications,
  ): string | JSX.Element | JSX.Element[] {
    const attributeNameTemplate: any = get(
      attributeSpecifications,
      [`${attributeName}`, 'attributeNameTemplate'],
      undefined,
    );

    return attributeNameTemplate ? parse(attributeNameTemplate) : attributeName;
  }

  public static getAttributeValueMap(
    attribute: IAttribute,
    attributeSpecifications?: IAttributeSpecifications,
  ): object {
    const attributeValueMap = {};

    attribute.values.forEach(value => {
      if (value.type === ValueType.StringLiteral) {
        const valueString = value.stringLiteral;
        const attributeValueTemplate: any = get(
          attributeSpecifications,
          [`${attribute.key}`, 'attributeValues', `${valueString}`, 'attributeValueTemplate'],
          undefined,
        );
        attributeValueMap[valueString] = attributeValueTemplate ? parse(attributeValueTemplate) : valueString;
      }
    });

    return attributeValueMap;
  }

  public static getRequiredResolutionAttributes(requiredAttributes: IRequiredAttributes) {
    const requiredResolutionAttributes: string[] = [];
    const optionalResolutionAttributes: string[] = [];

    (requiredAttributes.productAttributes || []).forEach(productAttribute => {
      if (productAttribute.allowUnresolved) {
        optionalResolutionAttributes.push(productAttribute.attributeName.toLowerCase());
      } else {
        requiredResolutionAttributes.push(productAttribute.attributeName.toLowerCase());
      }
    });

    return {
      optionalResolutionAttributes,
      requiredResolutionAttributes,
    };
  }

  public static getRequiredResolutionAttributeKeys(requiredAttributes: IRequiredAttributes) {
    const requiredAndOptionsResourceAttributes = Utils.getRequiredResolutionAttributes(requiredAttributes);

    const nonProductAttributes = (requiredAttributes.nonProductAttributes || []).map((nonProductAttribute: any) =>
      nonProductAttribute.attributeKey.toLowerCase(),
    );

    const requiredResourceAttributeKeys = [
      ...requiredAndOptionsResourceAttributes.requiredResolutionAttributes,
      ...nonProductAttributes,
    ];
    const optionalResourceAttributeKeys = [...requiredAndOptionsResourceAttributes.optionalResolutionAttributes];

    return {
      optionalResourceAttributeKeys,
      requiredResourceAttributeKeys,
    };
  }

  public static convertResourceAttributes(
    requiredResourceAttributes?: IResourceRequiredAttributes,
  ): IRequiredAttributes | undefined {
    if (requiredResourceAttributes) {
      return {
        nonProductAttributes: requiredResourceAttributes.nonProductAttributes,
        productAttributes: (requiredResourceAttributes.productAttributes || []).map((productAttribute: string) => ({
          allowUnresolved: false,
          attributeName: productAttribute,
        })),
      };
    }

    return undefined;
  }

  public static convertRequiredAttributes(requiredAttributes?: IRequiredAttributes): IResourceRequiredAttributes {
    if (requiredAttributes) {
      return {
        nonProductAttributes: requiredAttributes.nonProductAttributes,
        productAttributes: (requiredAttributes.productAttributes || []).map(
          productAttribute => productAttribute.attributeName,
        ),
      };
    }

    return {
      nonProductAttributes: [],
      productAttributes: [],
    };
  }
}
