export default function parseUrlSections(urlPath: string) {
  // URL will have 3 sections of variable length:

  const urlSections = urlPath.split('/');

  // Detect if the path starts with /review/commit-slug/index.html
  const isReviewPath = urlSections[1] === 'review' && urlSections[3] === 'index.html';
  const trailingCharacter = urlPath[urlPath.length - 1] === '/' ? '/' : '';

  const basePath = isReviewPath
    ? `/${urlSections[1]}/${urlSections[2]}/${urlSections[3]}${trailingCharacter}`
    : '';
  // /account/accountId or empty string; try to identify by starting index
  const indexOfAccountSection = urlSections.indexOf('accounts');

  const accounts =
    indexOfAccountSection === -1 ? '' : `/accounts/${urlSections[indexOfAccountSection + 1]}`;

  let restOfUrl = '';

  // identify the rest of the URL
  if (accounts.length) {
    restOfUrl = urlPath.substring(basePath.length + accounts.length + 1);
  } else {
    restOfUrl = urlPath.substring(basePath.length + 1);
  }

  return {
    basePath,
    accounts,
    restOfUrl,
  };
}
