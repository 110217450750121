import { AttributeConfigurationMap, IProduct } from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes } from '@cimpress-technology/selector-resource-formatter';
import { cloneDeep, get, mapKeys } from 'lodash';
import { IConfigurationMapBuilder } from '../..';
import { IAttributeConfigurationMap, MetadataKeys  } from '../../../../Models';
import Utils from '../../../../Utils';
import { findAttributesDependencies, getRangeWrappedAttribute, isV2AttributeInferable } from '../../../../UtilsV2';
import { ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY, attributeKeys, PIVOT_DEFAULT_DISPLAY_PRIORITY } from '../../../constants';

/**
 * This class builds configurationMap only for the resources or the requiredAttributes passed.
 * Situtaion when user wants selection experience only on required attributes or a particular
 * resource and excluding other product attributes in that case.
 */
export default class V2ResourceConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!V2ResourceConfigurationMapBuilder.instance) {
      V2ResourceConfigurationMapBuilder.instance = new V2ResourceConfigurationMapBuilder();
    }

    V2ResourceConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return V2ResourceConfigurationMapBuilder.instance;
  }

  private static instance: V2ResourceConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  private constructor() { }

  public buildConfigurationMap(
    product: IProduct,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ): AttributeConfigurationMap {
    const requiredAttributes = !requiredResourceAttributes
      ? { nonProductAttributes: [], productAttributes: [] }
      : requiredResourceAttributes;

    const { requiredResourceAttributeKeys, optionalResourceAttributeKeys } = Utils.getRequiredResolutionAttributeKeys(
      requiredAttributes,
    );
    const resourceAttributes = [...requiredResourceAttributeKeys, ...optionalResourceAttributeKeys];

    const requiredResourceAttributeDependencies = findAttributesDependencies(
      product,
      requiredResourceAttributeKeys,
    ).map((attr: string) => attr.toLowerCase());
    const optionalResourceAttributeDependencies = findAttributesDependencies(
      product,
      optionalResourceAttributeKeys,
    ).map((attr: string) => attr.toLowerCase());

    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());

    product.options.forEach((option: any) => {
      const attributeName = option.name.toLowerCase();

      const configuration: any = cloneDeep(normalizedConfigurationMap[attributeName] || {});

      const isResourceAttribute: boolean = resourceAttributes.includes(attributeName);
      const isDeriverForOptionalResourceAttribute = optionalResourceAttributeDependencies.includes(attributeName);
      const isDeriverForRequiredResourceAttribute = requiredResourceAttributeDependencies.includes(attributeName);
      const isDeriverAttribute = isDeriverForOptionalResourceAttribute || isDeriverForRequiredResourceAttribute;
      const isInferable = isV2AttributeInferable(option);

      if (configuration.displayPriority === undefined) {
        configuration.displayPriority = configuration.isPivot
          ? PIVOT_DEFAULT_DISPLAY_PRIORITY
          : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
      }

      configuration.isRequired = isDeriverForRequiredResourceAttribute && !isInferable;

      // This condition addresses scenario when an attribute is inferable
      // We hide inferable attributes until and unless consumer
      // doesn't enforce to display it through attributeConfiguration.
      if (configuration.isHidden === undefined) {
        configuration.isHidden = (!this.displaySingleValuedAttributes && isInferable) || !isDeriverAttribute;
      }

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
        [MetadataKeys.isResourceAttribute]: isResourceAttribute,
        [MetadataKeys.isResourceDerivedAttribute]: isDeriverAttribute,
      };

      normalizedConfigurationMap[attributeName] = configuration;
    });

    product.properties?.forEach((property: any) => {
      const configuration: any = get(normalizedConfigurationMap, property.name.toLowerCase(), {});
      const isResourceAttribute: boolean = requiredResourceAttributeKeys.includes(property.name.toLowerCase());

      configuration.metadata = {
        // If the user has explicitly passed configuration to display the property
        [MetadataKeys.isDisplayed]: configuration.isHidden === false,
        [MetadataKeys.isResourceAttribute]: isResourceAttribute,
      };

      normalizedConfigurationMap[property.name.toLowerCase()] = configuration;
    });

    const isQuantityResourceAttribute: boolean = requiredResourceAttributeKeys.includes(attributeKeys.QUANTITY);
    const isQuantityDeriverRequired =
      requiredResourceAttributeDependencies.includes(attributeKeys.QUANTITY) ||
      optionalResourceAttributeDependencies.includes(attributeKeys.QUANTITY);

    const quantityConfiguration: any = cloneDeep(normalizedConfigurationMap[attributeKeys.QUANTITY] || {});

    if (quantityConfiguration.displayPriority === undefined) {
      quantityConfiguration.displayPriority = quantityConfiguration.isPivot
        ? PIVOT_DEFAULT_DISPLAY_PRIORITY
        : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
    }

    quantityConfiguration.isRequired = isQuantityDeriverRequired
      ? !isV2AttributeInferable(
        getRangeWrappedAttribute(
          product[Object.keys(product).find((key: string) => key.toLowerCase() === attributeKeys.QUANTITY) || 0],
        ),
      )
      : false;

    if (quantityConfiguration.isHidden === undefined) {
      quantityConfiguration.isHidden = !quantityConfiguration.isRequired;
    }

    quantityConfiguration.metadata = {
      [MetadataKeys.displayPriority]: quantityConfiguration.displayPriority,
      [MetadataKeys.isDisplayed]: !quantityConfiguration.isHidden,
      [MetadataKeys.isResourceAttribute]: isQuantityResourceAttribute,
      [MetadataKeys.isResourceDerivedAttribute]: isQuantityDeriverRequired,
    };

    normalizedConfigurationMap[attributeKeys.QUANTITY] = quantityConfiguration;

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
