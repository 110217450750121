import { find } from 'lodash';
import IProductConverter from '../Interfaces/IProductConverter';
import IRuleSet, { IWhiteList } from '../Interfaces/IRuleSet';
import IVPProduct from '../Interfaces/IVPProduct';

export class VPPrdToRulesetConverter implements IProductConverter<IWhiteList[]> {
  public vpProduct: IVPProduct;
  public mcpProduct: IRuleSet;

  constructor(vpProduct: IVPProduct, v1Ruleset: IRuleSet) {
    this.vpProduct = vpProduct;
    this.mcpProduct = v1Ruleset;
  }

  public convert(): IWhiteList[] {
    const prdList = this.vpProduct.options.list;
    const prdRange = this.vpProduct.options.range;

    return this.mcpProduct.whiteList.map((v1RulesetAttribute: any) => {
      const prdAttributeList = prdList.find((prdAttributeObj: any) => {
        return v1RulesetAttribute.attributeKey.toLowerCase() === prdAttributeObj.name.toLowerCase();
      });

      if (prdAttributeList && v1RulesetAttribute.attributeValues) {
        v1RulesetAttribute.attributeValues = v1RulesetAttribute.attributeValues.filter(
          (v1RulesetAttributeValue: any) => {
            return prdAttributeList.values.includes(v1RulesetAttributeValue.value);
          },
        );

        return v1RulesetAttribute;
      }

      const prdAttributeRange = prdRange.find((prdAttributeObj: any) => {
        return v1RulesetAttribute.attributeKey.toLowerCase() === prdAttributeObj.name.toLowerCase();
      });

      if (prdAttributeRange && v1RulesetAttribute.attributeRanges) {
        v1RulesetAttribute.attributeRanges = v1RulesetAttribute.attributeRanges.filter(
          (v1RulesetAttributeRange: any) => {
            return find(prdAttributeRange.ranges, prdAttributeRangeObject => {
              return (
                prdAttributeRangeObject.minimum === v1RulesetAttributeRange.minimum &&
                prdAttributeRangeObject.maximum === v1RulesetAttributeRange.maximum &&
                prdAttributeRangeObject.increment === v1RulesetAttributeRange.increment
              );
            });
          },
        );

        return v1RulesetAttribute;
      }

      return v1RulesetAttribute;
    });
  }
}
