/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { colors } from '@cimpress/react-components/';

import StyledTextField from '../styledComponents/StyledTextField';

const defaultValues = {
  orderedSku: '',
  resultSku: '',
  parentResultSku: undefined,
};

export default function LegacyAlsoRoutesDisplay({
  value,
}: {
  value: {
    orderedSku: string;
    resultSku: string;
    parentResultSku?: string;
  };
}) {
  const { orderedSku, resultSku, parentResultSku } = value || defaultValues;
  return (
    <div css={containerStyles}>
      <StyledTextField
        label="Ordered Sku"
        onChange={() => {}}
        value={orderedSku}
        inputStyles={inputStyles}
        readOnly
      />
      <StyledTextField
        label="Result Sku"
        onChange={() => {}}
        value={resultSku}
        inputStyles={inputStyles}
        readOnly
      />
      {parentResultSku && (
        <StyledTextField
          label="Parent Result Sku"
          onChange={() => {}}
          value={parentResultSku}
          inputStyles={inputStyles}
          readOnly
        />
      )}
    </div>
  );
}

const containerStyles = css`
  display: flex;
  gap: 10px;
`;

const inputStyles = {
  backgroundColor: colors.horizon,
  border: `1px solid ${colors.success.base}`,
};
