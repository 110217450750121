import { Classes } from 'jss';
import { ReactElement } from 'react';
import { createUseStyles } from 'react-jss';

export function createStyles(classes: any) {
  return createUseStyles(classes as any);
}

/* tslint:disable-next-line */
export default function Styles<T extends string | number | symbol>(props: {
  styles: () => Classes<T>;
  children: (styles: Classes<T>) => ReactElement;
}) {
  const classes = props.styles();
  return props.children(classes);
}
