import moment, { Moment } from 'moment-timezone';

import { Environment } from '../features/resourceSearch/resourceSearchSlice';
import { Link } from '../types';
import fetchWithAuth from '../utils/fetchWithAuth';

export type DeliveryConstraints = {
  carrierServiceCapabilities: string[];
  carrierServices: string[];
};

export type ItemFulfillmentCapability =
  | 'cashOnDelivery'
  | 'whiteLabelling'
  | 'moneyBackGuaranteedDelivery';

export type DeliveryRequest = {
  destinationAddress: {
    city: string;
    street1: string;
    country: string;
    postalCode: string;
    isPOBox?: boolean;
    isResidential?: boolean;
  };
  _links?: {
    pickupPoint?: Link;
  };
};

export type DestinationAddress = {
  country: string;
  postalCode: string;
  isPOBox: boolean;
  isResidential: boolean;
};

export type DeliveryConfiguration = {
  quantity: number;
  deliveryRequestLink: Link;
  isSample: boolean;
  destinationAddress: DestinationAddress | null;
};

export type Item = {
  itemId: string;
  orderId: string;
  merchantId: string;
  mcpSku: string;
  orderedSkuCode: string;
  globalFulfillerId: string;
  destinationAddress?: DestinationAddress;
  deliveryConfigurations: DeliveryConfiguration[];
  deliveryConstraints: DeliveryConstraints;
  fulfillmentCapabilities: ItemFulfillmentCapability[];
  buyerAccountUrl: string;
  productConfigurationUrl: string;
  itemUrl: string;
  requestDate: Moment | string;
  statuses?: {
    [key: string]: {
      name: string;
      updatedDate: string;
    };
  };
  _links: {
    productConfiguration: Link;
    buyerAccount: Link;
    self: Link;
    cartEvaluation?: Link;
  };
};

export type ItemWithPromisedDate = Item & {
  localPromisedArrivalDate?: string;
};

export type ItemWithOrderMetadata = ItemWithPromisedDate & {
  deliveryRequest: DeliveryRequest;
};

export type Product = {
  mcpSku: string;
  name: string;
  description: string;
};

export type ItemWithDeliveryDetails = Item & {
  deliveryRequest: DeliveryRequest;
  productConfigurationUrl: string;
  requestDate?: Moment | string | undefined;
  mcpSku?: string;
};

export type ItemResponse = {
  skuCode: string;
  itemId: string;
  orderId: string;
  merchantId: string;
  globalFulfillerId: string;
  destinationAddress?: DestinationAddress;
  deliveryConfigurations: DeliveryConfiguration[];
  deliveryConstraints: DeliveryConstraints;
  fulfillmentCapabilities: ItemFulfillmentCapability[];
  requestDate: Moment | string;
  createdDate: string;
  orderedSkuCode: string;
  _links: {
    productConfiguration: Link;
    buyerAccount: Link;
    self: Link;
    cartEvaluation?: Link;
  };
};

type Attribute = {
  class: string;
  attributeKey: string;
  attributeValue: string;
};

type ProductConfiguration = {
  mcpSku: string;
  attributes: Attribute[];
};

type IntroductionResponse = {
  referenceId: string;
  name: string;
  description: string;
};

export const getItem = async (
  itemId: string,
  timezone: string | undefined,
  env: Environment,
): Promise<Item> => {
  const url =
    env === 'prod'
      ? 'https://item-service.commerce.cimpress.io'
      : 'https://int-item-service.commerce.cimpress.io';

  const response = await fetchWithAuth<ItemResponse>({
    endpointUrl: url,
    route: `/v1/items/${itemId}`,
    method: 'GET',
  });

  return {
    ...response,
    mcpSku: response.skuCode,
    requestDate: timezone
      ? moment(response.createdDate).tz(timezone).toISOString()
      : response.createdDate,
    buyerAccountUrl: response._links.buyerAccount.href,
    productConfigurationUrl: response._links.productConfiguration.href,
    itemUrl: response._links.self.href,
    fulfillmentCapabilities: response.fulfillmentCapabilities,
  };
};

export const getProduct = async ({
  productConfigurationUrl,
  mcpSku,
}: {
  productConfigurationUrl: string;
  mcpSku?: string;
}): Promise<Product> => {
  const productConfiguration = await fetchWithAuth<ProductConfiguration>({
    endpointUrl: productConfigurationUrl,
  });

  const introductionServiceUrl = process.env.REACT_APP_INTRODUCTION_SERVICE_URL as string;
  const introductionResponse = await fetchWithAuth<IntroductionResponse>({
    endpointUrl: introductionServiceUrl,
    route: `/v1/products/${mcpSku || productConfiguration?.mcpSku}`,
  });

  return {
    mcpSku: introductionResponse.referenceId,
    name: introductionResponse.name,
    description: introductionResponse.description,
  };
};

export const getItemWithDeliveryRequest = async (
  itemId: string,
  timezone: string | undefined,
  env: Environment,
): Promise<ItemWithDeliveryDetails> => {
  const item = await getItem(itemId, timezone, env);
  if (item) {
    const deliveryRequest = await fetchWithAuth<DeliveryRequest>({
      endpointUrl: item.deliveryConfigurations[0].deliveryRequestLink.href,
      method: 'GET',
    });
    return {
      ...item,
      deliveryRequest,
    };
  }
  return item;
};
