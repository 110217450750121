import { AttributeConfigurationMap, IAttributeModelConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes, ISelection, ResourceType } from '@cimpress-technology/selector-resource-formatter';
import { IAttributeConfigurationMap, ISKURequiredAttributes } from '../../../Models';

import { cloneDeep } from 'lodash';
import { IConfigurationMapBuilder, IProcessor } from '..';
import { convertRequiredResourceAttributesIntoAttributeModel } from '../../../AttributeModelUtils';
import { errorAlertMessages } from '../../constants';
import IModelExplorerRepo from '../../Interfaces/IModelExplorerRepo';
import ModelExplorerRepo from '../../Repository/ModelExplorerRepo';

export default class AttributeModelProcessor
  implements IProcessor<IAttributeModelConfiguration, string, ISKURequiredAttributes|IAttributeModelConfiguration> {

  private configurationMapBuilder: IConfigurationMapBuilder;

  private optimizePivot: boolean;

  public constructor(configurationMapBuilder: IConfigurationMapBuilder, optimizePivot: boolean = true) {
    this.configurationMapBuilder = configurationMapBuilder;
    this.optimizePivot = optimizePivot;
  }

  /* tslint:disable:variable-name */
  public getResourceRequiredAttributes(
    authToken: string,
    referenceId: string,
    selectionResource: ResourceType,
  ): Promise<IRequiredAttributes> {
    throw new Error(errorAlertMessages.ATTRIBUTE_MODEL_RESOURCE_REQUIRED_ATTRIBUTE_ERROR);
  }

  public getResource(
    product: IAttributeModelConfiguration,
    selectionResource: ResourceType,
    selections: ISelection[],
  ): string {
    throw new Error(errorAlertMessages.ATTRIBUTE_MODEL_RESOURCE_URL_ERROR);
  }
  /* tslint:enable: variable-name */

  public createAttributeModelExplorer(
    attributeModel: IAttributeModelConfiguration,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes: IRequiredAttributes,
  ): IModelExplorerRepo {
    const updatedAttributeModel = this.getUpdatedAttributeModel(attributeModel, requiredResourceAttributes);
    const attributeConfigurationMap = this.createAttributeConfigurationMap(
      updatedAttributeModel,
      configurationMap,
      requiredResourceAttributes,
    );

    return ModelExplorerRepo.createFromAttributeModel(updatedAttributeModel, attributeConfigurationMap, this.optimizePivot);
  }

  private createAttributeConfigurationMap(
    attributeModel: IAttributeModelConfiguration,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ): AttributeConfigurationMap {
    return this.configurationMapBuilder.buildConfigurationMap(
      attributeModel,
      configurationMap,
      requiredResourceAttributes,
    );
  }

  private getUpdatedAttributeModel(
    attributeModel: IAttributeModelConfiguration,
    requiredResourceAttributes?: IRequiredAttributes,
  ): IAttributeModelConfiguration {
    if (requiredResourceAttributes && requiredResourceAttributes.nonProductAttributes) {
      const attributeModelClone = cloneDeep(attributeModel);
      const nonProductAttributes = convertRequiredResourceAttributesIntoAttributeModel(
        requiredResourceAttributes.nonProductAttributes,
      );

      if (nonProductAttributes.length > 0) {
        attributeModelClone.attributes = attributeModelClone.attributes.concat(nonProductAttributes);
      }

      return attributeModelClone;
    }

    return attributeModel;
  }
}
