import { IProduct } from '@cimpress-technology/attribute-model-explorer';
import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';
/**
 * Client to interact with the Product Service
 */
export default class ProductServiceClient {
  /**
   * @property authToken - The OAuth JWT to authenticate requests with.
   */
  private readonly authToken: string;
  private readonly productServiceUrl: string;

  constructor(authToken: string, productServiceUrl: string = 'https://api.products.cimpress.io') {
    this.authToken = authToken;
    this.productServiceUrl = productServiceUrl;
  }

  /**
   * Retrieve the Product matching the provided Product ID and, optionally, the version.
   * When version is not provided, then we retrieve the current version.
   * @param {string} productId - an identifier for a Product.
   * @param {string} [version] - the version of the Product to retrieve. When not provided, retrieve current.
   * @returns {Promise<any>} The requested Product.
   */
  public async getV2Product(productId: string, version?: string): Promise<IProduct> {
    let requestUrl: string;
    let requestedVersion: string;
    if (version === undefined) {
      requestedVersion = 'current';
      requestUrl = `${this.productServiceUrl}/v0/products/${productId}:current`;
    } else {
      requestedVersion = version;
      requestUrl = `${this.productServiceUrl}/v0/products/${productId}/versions/${version}`;
    }

    const response = await fetch(requestUrl, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${this.authToken}`,
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Could not find Product ID ${productId} with version ${requestedVersion}`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`${errorAlertMessages.ERROR_FETCHING_PRODUCT} ${productId} with version ${requestedVersion}`);
    }
  }
}
