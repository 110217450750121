import findAttributeDependencies from '@cimpress-technology/attribute-dependencies';
import {
  IAttribute,
  IAttributeModelConfiguration,
  V1AttributeTypes,
  V1RuleSetConverter,
  ValueType,
} from '@cimpress-technology/attribute-model-explorer';
// @ts-ignore
import { constants } from '@cimpress-technology/math-evaluation-helper';
import { cloneDeep } from 'lodash';
import { ProductModel } from './Components/Processors/AttributesProcessorFactory';

import {
  getUniqueValues,
} from './UtilsV2';

/**
 * Given an attribute model, and find dependencies of all the provided attributes
 * @param attributeModel The attribute model to pull attributes from
 * @param attributes The set of attributes for those dependencies need to find
 * @return Full string array of dependendent attributes
 */
export function findAttributesDependencies(
  attributeModel: IAttributeModelConfiguration,
  attributes: string[] = [],
): string[] {
  const fullCustomRequired: string[] = [];

  attributes.forEach(attribute => {
    fullCustomRequired.push(...findAttributeDependencies(attribute, attributeModel, ProductModel.AttributeModel));
  });

  return getUniqueValues(fullCustomRequired);
}

export function convertRequiredResourceAttributesIntoAttributeModel(requiredAttributes: any[]): any[] {
  const attributes: any[] = [];
  let nonProductAttributes: any[] = [];

  requiredAttributes.forEach(attribute => {
    const nonProductAttribute = cloneDeep(attribute);

    if (
      (nonProductAttribute.type === V1AttributeTypes.listOfValues &&
        nonProductAttribute.attributeValues &&
        nonProductAttribute.attributeValues.length > 0) ||
      (nonProductAttribute.type === V1AttributeTypes.listOfRanges &&
        nonProductAttribute.attributeRanges &&
        nonProductAttribute.attributeRanges.length > 0)
    ) {
      nonProductAttribute.required = true;
      attributes.push(nonProductAttribute);
    }
  });

  if (attributes.length > 0) {
    const attributeModelConfiguration = V1RuleSetConverter.toAttributeModelConfiguration({
      whiteList: attributes,
    });
    nonProductAttributes = attributeModelConfiguration.attributes;
  }

  return nonProductAttributes;
}

export function isAttributeModelVariableInferable(attribute: IAttribute): boolean {
  let isAttributeInferable = false;

  if (attribute.values && attribute.values.length === 1) {
    isAttributeInferable = true;
    if (
      attribute.values[0].type === ValueType.Range &&
      attribute.values[0].range.minimum !== attribute.values[0].range.maximum
    ) {
      isAttributeInferable = false;
    }
  } else {
    isAttributeInferable = attribute.values.some(value => value.type === ValueType.Formula);
  }

  return isAttributeInferable;
}
