import { NumberLiteralConfiguration, StringValueConfiguration } from '@cimpress-technology/attribute-model-explorer';
// @ts-ignore
// tslint:disable-next-line:no-submodule-imports
import IconPictureSun from '@cimpress-technology/react-streamline-icons/lib/IconPictureSun';
import { debounce, get } from 'lodash';
import React, { Component } from 'react';
import { IInputProps } from '../';
import { MetadataKeys } from '../../../Models';
import ColorSwatch from '../ColorSwatch';
import DetailViewContainer from '../DetailViewContainer';
import ResourceAttributeIconWithLabel from '../ResourceAttributeIconWithLabel';
import ValueStateTooltip from '../ValueStateTooltip';

import './DetailCard.css';
import './RadioButton.css';

interface IRadioButtonState {
  selected: string;
}

const DEBOUNCE_WAIT_IN_MS = 200;

export default class RadioButton extends Component<IInputProps, IRadioButtonState> {
  public static defaultProps = {
    attributeStateTooltipConfiguration: {},
    resolvedValue: undefined,
  };

  constructor(props: IInputProps) {
    super(props);
    this.state = {
      selected: this.props.resolvedValue || '',
    };
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: any) {
    const selection = this.props.resolvedValue || '';
    const { allowDisabledSelection } = this.props;

    if (!allowDisabledSelection) {
      if (this.state.selected !== selection) {
        this.setState({
          selected: selection,
        });
      }
    } else {
      if (prevState.selected !== selection) {
        this.setState({
          selected: selection,
        });
      }
    }
  }

  public render() {
    const {
      attributeKey,
      label,
      isValid,
      values,
      isRequired,
      metadata,
      resourceAttributeIcon,
      styleClasses,
      isColorSwatch,
    } = this.props;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    return (
      <DetailViewContainer label={displayLabel} isRequired={isRequired} isValid={isValid} styleClasses={styleClasses}>
        <div className="radio-group" id={attributeKey}>
          {this.getValueButtons(attributeKey, values, styleClasses, isColorSwatch)}
          {values.length % 2 === 0 ? '' : <div className="radio-layout-arrangement" />}
        </div>
      </DetailViewContainer>
    );
  }

  private getValueButtons(
    attributeKey: string,
    values: StringValueConfiguration[] | NumberLiteralConfiguration[],
    styleClasses: any,
    isColorSwatch?: boolean,
  ): JSX.Element[] {
    return values.map(({ stringLiteral }) => {
      const { allowDisabledSelection } = this.props;
      let isDisabled = this.isDisabled(stringLiteral);
      const isSelected = this.state.selected === stringLiteral;

      if (allowDisabledSelection && isDisabled && isSelected) {
        isDisabled = false;
      }

      const disabledClass = isDisabled ? (allowDisabledSelection ? ' disabled-allowed' : ' disabled') : '';

      return (
        <div
          className={`radio-layout-arrangement ${get(
            styleClasses,
            'radioButtonContainer',
            '',
          )} radio-button${disabledClass} ${isSelected ? 'active' : ''}`}
          key={stringLiteral}
          data-value={stringLiteral}
          onClick={
            isDisabled
              ? allowDisabledSelection
                ? this.onRadioContainerClicked
                : () => {}
              : this.onRadioContainerClicked
          }
        >
          <div className="radio-block">
            <div className={`radioContainer ${get(styleClasses, 'radioButtonWrapper', '')}`}>
              <input
                type="radio"
                value={stringLiteral}
                name={`${attributeKey}-${stringLiteral}`}
                checked={isSelected}
                disabled={isDisabled}
              />
              <span className="customRadio" />
            </div>
          </div>
          <div className={`radio-description ${get(styleClasses, 'radioLabel', '')}`}>
            {this.getRadioButtonLabel(stringLiteral, isColorSwatch)}
          </div>
        </div>
      );
    });
  }

  private getRadioButtonLabel(attributeValue: any, isColorSwatch?: boolean) {
    const { getValueState, attributeStateTooltipConfiguration, valueLabelMap } = this.props;

    return (
      <ValueStateTooltip
        key={attributeValue}
        getValueState={getValueState}
        value={attributeValue}
        showOnExcluded={attributeStateTooltipConfiguration.showOnExcluded}
        showOnResolved={attributeStateTooltipConfiguration.showOnResolved}
        style={{ display: 'inline-block' }}
      >
        {isColorSwatch ? (
          <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <ColorSwatch hexCode={attributeValue} /> {valueLabelMap[attributeValue]}
          </span>
        ) : (
          valueLabelMap[attributeValue]
        )}
      </ValueStateTooltip>
    );
  }

  private onRadioContainerClicked = (event: any) => {
    const value = event.currentTarget.getAttribute('data-value');
    const { allowDisabledSelection } = this.props;

    if (value) {
      if (this.state.selected !== value) {
        const isDisabled = this.isDisabled(value);

        if (!isDisabled) {
          this.setState(
            {
              selected: value,
            },
            () => this.props.onInputChange(this.props.attributeKey, value),
          );
        } else if (allowDisabledSelection && isDisabled) {
          this.setState(
            {
              selected: value,
            },
            debounce(() => this.props.onInputChange(this.props.attributeKey, value, true), DEBOUNCE_WAIT_IN_MS),
          );
        }
      } else if (this.props.validValues.length > 1) {
        this.props.onInputChange(this.props.attributeKey, '');
        this.setState({
          selected: '',
        });
      }
    }
  }

  private isDisabled(value: string): boolean {
    return !this.props.validValues.find(item => value === item.stringLiteral);
  }
}
