import {
  Attribute,
  AttributeConfigurationMap,
  AttributeModelExplorer,
  DeserializedAttributeModelData,
  IAttributeModelConfiguration,
  IAttributeModelExplorerState,
  IDoublePivotState,
  IModelExplorer,
  IProduct,
  ISinglePivotState,
  ModelExplorerDecorator,
  ModelExplorerDecoratorFactory,
  PivotAttribute,
} from '@cimpress-technology/attribute-model-explorer';
import { cloneDeep } from 'lodash';
import RevertSelectionError from '../../Models/RevertSelectionError';
import { errorAlertMessages, REVERTING_SELECTION_ERROR_CODE } from '../constants';
import { IRuleSet } from '../Interfaces';
import IModelExplorerRepo from '../Interfaces/IModelExplorerRepo';

export default class ModelExplorerRepo implements IModelExplorerRepo {
  public static createFromProduct(product: IProduct, attributeConfigurationMap?: AttributeConfigurationMap, optimizePivot?: boolean) {
    return new ModelExplorerRepo(ModelExplorerDecoratorFactory.createFromProduct(product, attributeConfigurationMap, optimizePivot));
  }

  public static createFromRuleset(ruleSet: IRuleSet, attributeConfigurationMap?: AttributeConfigurationMap, optimizePivot?: boolean) {
    return new ModelExplorerRepo(ModelExplorerDecoratorFactory.createFromV1RuleSet(ruleSet, attributeConfigurationMap, optimizePivot));
  }

  public static createFromSerializedModel(serializedModel: DeserializedAttributeModelData, attributeConfigurationMap?: AttributeConfigurationMap) {
    return new ModelExplorerRepo(ModelExplorerDecoratorFactory.createFromSerializedModel(serializedModel, attributeConfigurationMap));
  }

  public static createFromAttributeModel(
    attributeModel: IAttributeModelConfiguration,
    attributeConfigurationMap?: AttributeConfigurationMap,
    optimizePivot?: boolean,
  ) {
    return new ModelExplorerRepo(
      ModelExplorerDecoratorFactory.createFromAttributeModel(attributeModel, attributeConfigurationMap, optimizePivot),
    );
  }

  private modelExplorer: IModelExplorer;
  private userSelectionMap: { [key: string]: string | string[] };

  private constructor(modelExplorer: IModelExplorer) {
    this.modelExplorer = modelExplorer;
    this.userSelectionMap = {};
  }

  public getAttributeModelExplorer(): AttributeModelExplorer {
    return this.modelExplorer.getAttributeModelExplorer();
  }

  public getAMExClone(): IModelExplorerRepo {
    return new ModelExplorerRepo(new ModelExplorerDecorator(cloneDeep(this.modelExplorer.getAttributeModelExplorer())));
  }

  public getAttributes(): Attribute[] {
    return this.modelExplorer.getAttributes();
  }

  public getAttribute(key: string): Attribute {
    return this.modelExplorer.getAttribute(key);
  }

  public getPivotAttributes(): PivotAttribute[] {
    return this.modelExplorer.getPivotAttributes();
  }

  public getPivotAttribute(key: string): PivotAttribute {
    return this.modelExplorer.getPivotAttribute(key);
  }

  public selectValue(attributeKey: string, value: string | string[], isDisabledSelection?: boolean, isUserSelection?: boolean): void {
    const attribute = this.getAttribute(attributeKey);

    // Pivot attributes are excluded from user selection map when the value is of type array
    if (isUserSelection && !Array.isArray(value)) {
      this.userSelectionMap[attributeKey] = value;
    }

    if (isDisabledSelection) {
      try {
        this.modelExplorer.selectDisabledValue(attributeKey, value);
      } catch (err) {
        throw new RevertSelectionError(errorAlertMessages.REVERTING_SELECTION, REVERTING_SELECTION_ERROR_CODE, { attributeKey });
      }
    } else {
      if (attribute && !attribute.impliedValue && !attribute.assignedValue) {
        this.modelExplorer.selectValueViaAssignment(attributeKey, value);
      } else {
        this.modelExplorer.selectValue(attributeKey, value);
      }
    }
  }

  public selectValues(selectionMap: { [key: string]: string | string[] }): void {
    this.modelExplorer.selectValues(selectionMap);
  }

  public deselect(attributeKey: string): void {
    this.modelExplorer.deselect(attributeKey);
  }

  public reset(): void {
    this.modelExplorer.reset();
    this.userSelectionMap = {};
  }

  public resetWithConfiguration(): void {
    this.modelExplorer.resetWithConfiguration();
    this.userSelectionMap = {};
  }

  public getState(): IAttributeModelExplorerState | ISinglePivotState | IDoublePivotState {
    return this.modelExplorer.getState();
  }

  public getUserSelections(): { [key: string]: string | string[] } {
    for (const [attributeKey, value] of Object.entries(this.userSelectionMap)) {
      const attribute = this.getAttribute(attributeKey);

      if (attribute) {
        const assignedValue = attribute.assignedValue;

        if (!value || assignedValue !== value) {
          delete this.userSelectionMap[attributeKey];
        }
      }
    }

    return this.userSelectionMap;
  }
}
