import { Alert, colors } from '@cimpress/react-components';
import React from 'react';

interface IProps {
  attributeKey: string;
}

const createAlertMessage = (attributeKey: string) => (
  <span>
    Currently Selected disabled option :  <span style={{ color: colors.ocean.base }}>{attributeKey}</span>
    <br />
    <span style={{ color: colors.warning.base }}>All the fields in yellow have been resolved based on your {attributeKey} input</span>
  </span>
);

// tslint:disable-next-line: variable-name
const DisabledSelectionAlert = ({ attributeKey }: IProps) => (
  <Alert
    dismissible={false}
    message={createAlertMessage(attributeKey)}
    title=""
    status={'info'}
  />
);

export default DisabledSelectionAlert;
