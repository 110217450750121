import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { OrderItem } from '../../components/configurationTester/inputs/OrderItems';
import { Item } from '../../services/items';

interface TestInputState {
  skuCode: string;
  quantity: number;
  attributes?: any[];
  country: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  capabilities: string[]; // I'm not positive about this
  orderDate: string; // this will probably need a different name
  promisedArrivalDate: string;
  items: Item[];
  orderItems: OrderItem[];
}

const initialState: TestInputState = {
  skuCode: '',
  quantity: 0,
  country: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  capabilities: [],
  orderDate: '',
  promisedArrivalDate: '',
  items: [],
  orderItems: [],
};

const testInputSlice = createSlice({
  name: 'testInput',
  initialState,
  reducers: {
    setItemFromUrl(state, action: PayloadAction<string>) {
      state.skuCode = action.payload;
    },
  },
});

export const { setItemFromUrl } = testInputSlice.actions;
export default testInputSlice.reducer;
