import { StringValueConfiguration } from '@cimpress-technology/attribute-model-explorer';
// @ts-ignore
// tslint:disable-next-line:no-submodule-imports
import IconAlertTriangle from '@cimpress-technology/react-streamline-icons/lib/IconAlertTriangle';

import { Button, colors } from '@cimpress/react-components';
import { debounce, get } from 'lodash';
import React, { Component } from 'react';
import ValidIcon from '../../Icons/ValidIcon';
import { MetadataKeys } from '../../Models';
import Utils from '../../Utils';
import { AMEXMetadata } from '../constants';
import { IInputProps } from './';
import ColorSwatch from './ColorSwatch';
import ResourceAttributeIconWithLabel from './ResourceAttributeIconWithLabel';
import './StringButtonInput.css';
import ValueStateTooltip from './ValueStateTooltip';

interface IStringButtonState {
  value: string;
}

const DEBOUNCE_WAIT_IN_MS = 200;

/**
 * Default Input Component for Value Attributes that have few enough options to be represented
 * via Buttons
 */
export default class StringButtonInput extends Component<IInputProps, IStringButtonState> {
  public static defaultProps = {
    attributeStateTooltipConfiguration: {},
    resolvedValue: undefined,
  };

  constructor(props: IInputProps) {
    super(props);

    const { resolvedValue } = this.props;

    this.state = {
      value: resolvedValue,
    };

    this.getValueButtons = this.getValueButtons.bind(this);
    this.onClick = this.onClick.bind(this);
    this.isDisabled = this.isDisabled.bind(this);
  }

  public render() {
    const {
      label,
      isValid,
      values,
      isRequired,
      metadata,
      resourceAttributeIcon,
      styleClasses,
      isColorSwatch,
      allowDisabledSelection,
    } = this.props;
    const { value } = this.state;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    let icon: JSX.Element | undefined;
    if (allowDisabledSelection && isValid && value) {
      icon = (
        <span>
          {' '}
          <ValidIcon />
        </span>
      );
    }

    return (
      <div className={`button-container ${get(styleClasses, 'buttonContainer', '')}`}>
        <label className={`button-header-label ${get(styleClasses, 'attributeLabelText', '')}`}>{displayLabel}</label>
        {icon}
        {isRequired && <span style={{ color: colors.persimmon.base }}> *</span>}
        {!isValid ? <i className="fa fa-exclamation-triangle text-danger" /> : null}
        <div className="tooltipContainerClass" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {this.getValueButtons(values, get(styleClasses, 'tooltipContainer', ''), isColorSwatch)}
        </div>
      </div>
    );
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: IStringButtonState) {
    const { allowDisabledSelection } = this.props;

    // Second condition is added for reverting the selection whenever disabled selection is performed and it leads to an unrecoverable error
    if (
      this.props.resolvedValue !== prevProps.resolvedValue ||
      (allowDisabledSelection && this.props.resolvedValue !== prevState.value)
    ) {
      this.setState({ value: this.props.resolvedValue });
    }
  }

  private getValueButtons(values: StringValueConfiguration[], className: any, isColorSwatch?: boolean): JSX.Element[] {
    const {
      isValid,
      metadata,
      getValueState,
      attributeStateTooltipConfiguration,
      valueLabelMap,
      allowDisabledSelection,
    } = this.props;

    return values.map(({ stringLiteral }) => {
      const isDisabled = this.isDisabled(stringLiteral);
      const isActive = stringLiteral === this.state.value;
      let disabledClass: any = isDisabled && 'disabled';
      let warningClass: any;

      if (allowDisabledSelection) {
        if (isActive && isDisabled) {
          disabledClass = false;
        }

        if (metadata && metadata.get(AMEXMetadata.isSelectionUpdatedByInvalidSelection)) {
          warningClass = 'outline-warning';
        }
      }

      return (
        <ValueStateTooltip
          key={stringLiteral}
          getValueState={getValueState}
          value={stringLiteral}
          showOnExcluded={attributeStateTooltipConfiguration.showOnExcluded}
          showOnResolved={attributeStateTooltipConfiguration.showOnResolved}
          className={className}
        >
          <Button
            className={`${!isValid ? 'buttonClass outline-danger' : 'buttonClass default'}
            ${isActive && 'active'}
              ${isActive && !isValid && 'activeDangerButton'}
              ${warningClass}
              ${disabledClass}
              `}
            style={{ marginRight: '10px', marginBottom: '10px' }}
            data-value={stringLiteral}
            key={Utils.generatePseudoUniqueKey(stringLiteral)}
            onClick={this.onClick}
            variant="default"
          >
            {isColorSwatch ? (
              <span style={{ display: 'inline-flex', alignItems: 'center', pointerEvents: 'none' }}>
                <ColorSwatch hexCode={stringLiteral} />
                {valueLabelMap[stringLiteral]}{' '}
              </span>
            ) : (
              valueLabelMap[stringLiteral]
            )}
          </Button>
        </ValueStateTooltip>
      );
    });
  }

  private onClick(event: React.MouseEvent<HTMLButtonElement>) {
    // @ts-ignore
    const value = event.target.getAttribute('data-value');
    const { allowDisabledSelection } = this.props;
    const isDisabled = this.isDisabled(value);

    if (value !== null && !isDisabled) {
      this.setState({ value }, () => {
        this.props.onInputChange(this.props.attributeKey, value);
      });
    } else if (allowDisabledSelection && value !== null && isDisabled) {
      this.setState(
        { value },
        debounce(() => this.props.onInputChange(this.props.attributeKey, value, true), DEBOUNCE_WAIT_IN_MS),
      );
    }
  }

  private isDisabled(value: string): boolean {
    return !this.props.validValues.find(item => value === item.stringLiteral);
  }
}
