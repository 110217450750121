/** @jsxImportSource @emotion/react */
import React, { createContext, useState } from 'react';

import { DisplayValue } from '../types';

export const IfThenContext = createContext<any>(null);

export const IfThenProvider = ({ children }) => {
  // This record is of the format { id: { label: name or id, value: id} },
  // Since name may be empty
  const [ifThenMap, setIfThenMap] = useState<Record<string, DisplayValue<string>>>();

  return (
    <IfThenContext.Provider value={{ ifThenMap, setIfThenMap }}>{children}</IfThenContext.Provider>
  );
};
