/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { Spinner } from '@cimpress/react-components';

import auth from './auth';
import ConfigurationSearchPage from './components/ConfigurationSearchPage';
import Shell from './components/Shell';
import TabContainer from './components/TabContainer';
import { AppContextProvider } from './context/AppContextProvider';
import { store } from './store/store';
import BasePathWrapper from './utils/basePathWrapper';

auth.on('tokenExpired', () => auth.login({ nextUri: window.location.pathname }));

export default function App() {
  const [isAuthorizing, setIsAuthorizing] = useState(false);
  const [authorizationError, setAuthorizationError] = useState<Error | undefined>(undefined);

  useEffect(() => {
    const tryToAuth = () => {
      if (!auth.isLoggedIn() && !isAuthorizing) {
        setIsAuthorizing(true);
        auth
          .ensureAuthentication({
            nextUri: window.location.pathname + window.location.search,
          })
          .then(() => {
            setIsAuthorizing(false);
          })
          .catch((err) => {
            setAuthorizationError(err);
          });
      }
    };

    tryToAuth();
  }, []);

  const renderIfLoggedIn = () => (
    <AppContextProvider>
      <Provider store={store}>
        <BasePathWrapper>
          {(basePath) => (
            <Routes>
              <Route path={`${basePath}/`} element={<Shell />}>
                <Route path="accounts/:accountId">
                  <Route index element={<ConfigurationSearchPage />} />
                  <Route path="configurations" element={<ConfigurationSearchPage />} />
                  <Route path="configurations/evaluate" element={<TabContainer />} />
                  <Route path="configurations/create" element={<TabContainer />} />
                  <Route path="configurations/edit/:id" element={<TabContainer />} />
                </Route>
              </Route>
              <Route
                path={`${basePath}/`}
                element={<Shell requireAccount={false} showAccountSelector={false} />}
              >
                <Route path="configurations/:id" element={<TabContainer />} />
              </Route>
            </Routes>
          )}
        </BasePathWrapper>
      </Provider>
    </AppContextProvider>
  );

  const renderLogic = () => {
    if (isAuthorizing) {
      return <Spinner fullPage={true} />;
    }
    if (auth.isLoggedIn()) {
      return renderIfLoggedIn();
    }
    if (authorizationError) {
      return <div>Unexpected error encountered. {authorizationError.message}</div>;
    }
  };

  return <Fragment>{renderLogic()}</Fragment>;
}
