import styled from '@emotion/styled';
import React from 'react';
import countryList from 'react-select-country-list';

import { Select } from '@cimpress/react-components';

import { DisplayValue } from '../../types';

const StyledSelect = styled(Select)`
  min-width: 200px;
  max-width: 300px;
  margin-right: 10px;
`;

const options: DisplayValue<string>[] = countryList()
  .getLabels()
  .map((countryName: string) => {
    const countryCode = countryList().getValue(countryName);
    return { value: countryCode, label: countryName };
  });

export default function CountrySelect({
  value,
  onChange,
}: {
  value: string;
  onChange: (selection: DisplayValue<string>) => void;
}) {
  const selectedValue = options.find((option) => option.value === value);

  return (
    <StyledSelect
      isClearable
      label="Country Code"
      options={options}
      value={selectedValue}
      onChange={onChange}
      required
    />
  );
}
