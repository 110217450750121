import {
  Attribute,
  DeserializedAttributeModelData,
  Errors as AttributeModelExplorerErrors,
  IAttribute,
  IAttributeModelConfiguration,
  IProduct,
  ModelUtilities,
  ResolutionState,
  RULES_ENGINE_VERSION,
  ValueType,
} from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes, ISelection, ResourceType } from '@cimpress-technology/selector-resource-formatter';
import { Alert, Spinner } from '@cimpress/react-components';
import { cloneDeep, get, isEmpty, isEqual } from 'lodash';
import React, { Component } from 'react';
// @ts-ignore
import pkg from '../../package.json';
import Utils from '../Utils';
import './common.css';

import {
  IAMExProductInputs,
  IAttributeConfiguration,
  IAttributeConfigurationMap,
  IAttributeSelectionMap,
  IProductConfiguration,
  IResourceRequiredAttributes,
  ISKURequiredAttributes,
  MetadataKeys,
  RuleSetError,
} from '../Models';
import RevertSelectionError from '../Models/RevertSelectionError';
import StateDescriptor from '../Models/StateDescriptor';

import ISerializedData from '../Models/ISerializedData';
import { ConfigurationServiceClient, ProductServiceClient, RuleServiceClient } from '../Services';
import {
  attributeKeys,
  errorAlertMessages,
  PRODUCT_SUPPORT_EMAIL,
  PRODUCT_SUPPORT_NAME,
  TRANSFORMATION_FROM_STRINGLITERAL,
} from './constants';
import DefaultResourceAttributeIcon from './DefaultResourceAttributeIcon';
import DisabledSelectionAlert from './DisabledSelectionAlert';
import EmailUsAlert from './EmailUsAlert';
import { IChangeMetadata, IGenericSelectorProps, IGenericSelectorState, IRuleSet, validateProps } from './Interfaces';
import IModelExplorerRepo from './Interfaces/IModelExplorerRepo';
import { ILayoutProps, LayoutFactory } from './Layouts';
import { AttributesProcessorFactory, IProcessor, SelectorCustomResourceType } from './Processors';
import { ProductModel } from './Processors/AttributesProcessorFactory';
import ProductRepository from './Repository/ProductRepository';

export function isProperty(v1Attribute: any, attributeConfiguration: IAttributeConfiguration) {
  const { isHidden, isPivot } = attributeConfiguration;

  return !isPivot && (v1Attribute.derived || isHidden || Utils.isV1AttributeInferable(v1Attribute));
}

function getSelectionAttributes(attributeModelExplorer: IModelExplorerRepo): IAttribute[] {
  const selectableAttributes = attributeModelExplorer
    .getAttributes()
    .filter((a: any) => a.metadata.get(MetadataKeys.isDisplayed));
  const selectablePivotAttributes = attributeModelExplorer
    .getPivotAttributes()
    .filter((a: any) => a.metadata.get(MetadataKeys.isDisplayed));
  return [...selectableAttributes, ...selectablePivotAttributes];
}

function isStateUnresolvableWithAllValidDisplayedAttributes(
  selectionOptions: IAttribute[],
  isStateUnresolvable: boolean,
): boolean {
  return isStateUnresolvable && selectionOptions.every((a: any) => a.isValid);
}

function transformStringLiteralToString(attributes: IAttribute[]): IAttribute[] {
  const transformedAttributes = cloneDeep(attributes);

  transformedAttributes.forEach((attribute: IAttribute) => {
    attribute.values.forEach(value => {
      if (value && value.type === ValueType.StringLiteral) {
        value.type = TRANSFORMATION_FROM_STRINGLITERAL;
        value.string = value.stringLiteral;
        delete value.stringLiteral;
      }
    });

    attribute.validValues.forEach(value => {
      if (value && value.type === ValueType.StringLiteral) {
        value.type = TRANSFORMATION_FROM_STRINGLITERAL;
        value.string = value.stringLiteral;
        delete value.stringLiteral;
      }
    });
  });

  return transformedAttributes;
}

function getNonSelectionAttributes(attributeModelExplorer: IModelExplorerRepo): Attribute[] {
  return attributeModelExplorer.getAttributes().filter((a: any) => !a.metadata.get(MetadataKeys.isDisplayed));
}

const DUPLICATE_SELECTION_WARN_THRESHOLD = 3;

export default class GenericSelector extends Component<IGenericSelectorProps, IGenericSelectorState> {
  public static defaultProps = {
    attributeConfigurations: {},
    attributeStateTooltipConfiguration: {
      showOnExcluded: true,
      showOnResolved: true,
    },
    displaySingleValuedAttributes: false,
    enableSerialization: true,
    errorAlertConfiguration: {
      showErrorAlert: true,
    },
    isColorSwatch: false,
    onChange: () => {},
    onError: () => {},
    onLoad: () => {},
    onReset: () => {},
    optimizePivot: true,
    resourceAttributeIcon: DefaultResourceAttributeIcon,
    selectWithProductAttributes: true,
  };

  private attributeProcessor: IProcessor<IProduct | IRuleSet, IProduct | string, IProduct | ISKURequiredAttributes>;
  private resourceRequiredAttributes?: IRequiredAttributes;

  constructor(props: IGenericSelectorProps) {
    super(props);

    validateProps(props);

    this.state = {
      duplicateSelectionCount: 0,
      errorMessage: '',
      isInfoAlert: false,
      isRuleSetFamilyRuleSet: false,
      isStateUnresolvable: false,
      previousSelections: undefined,
      rulesEngineRunningVersion: RULES_ENGINE_VERSION,
      selectorRunningVersion: pkg.version,
      showErroredOutSelector: false,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.getConfigurationUrl = this.getConfigurationUrl.bind(this);
    this.getRemainingOptions = this.getRemainingOptions.bind(this);
    this.getRequiredAttribute = this.getRequiredAttribute.bind(this);
    this.resetExplorer = this.resetExplorer.bind(this);
    this.buildSelectorState = this.buildSelectorState.bind(this);
    this.getErrorAlert = this.getErrorAlert.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.incrementSelectionCount = this.incrementSelectionCount.bind(this);
    this.updateHiddenPivotAttribute = this.updateHiddenPivotAttribute.bind(this);
    this.getResourceRequiredAttributes = this.getResourceRequiredAttributes.bind(this);
    this.getResource = this.getResource.bind(this);
    this.getUserSelections = this.getUserSelections.bind(this);
    this.resetSelector = this.resetSelector.bind(this);
  }

  public async componentDidMount() {
    await this.initializeSelector();
  }

  public async componentDidUpdate(prevProps: IGenericSelectorProps) {
    await this.reintializeSelector(prevProps);
  }

  /**
   * Updates the value for a hidden pivot attribute.
   * The `onError` prop gets called in case of an error or when provided a non pivot and/or a non attribute.
   * The `errorAlertConfiguration` configuration also applies while displaying the error message.
   * @param {string} attributeKey - The case sensitive key for the Pivot attribute. Should be the same as the one used in the attributeConfigurations prop initially.
   * @param {string[]} value - The value(s) that you want to assign to the pivot attribute. The value provided are replaced and not merged.
   */
  public async updateHiddenPivotAttribute(attributeKey: string, value: string[]) {
    const { attributeModelExplorer } = this.state;
    if (attributeModelExplorer !== undefined) {
      const pivotAttribute = attributeModelExplorer.getPivotAttribute(attributeKey);
      if (pivotAttribute && !pivotAttribute.metadata.get(MetadataKeys.isDisplayed)) {
        await this.onInputChange(attributeKey.toLowerCase(), value, false, false);
      } else {
        const error = new Error(errorAlertMessages.ONLY_HIDDEN_PIVOT_ATTRIBUTES);
        this.props.onError(error);
        this.setErrorMessage(error.message, true);
      }
    }
  }

  public async onInputChange(
    attributeKey: string,
    value: string | string[],
    isDisabledSelection: boolean = false,
    isUserSelected: boolean = true,
  ) {
    try {
      const { attributeModelExplorer, isRuleSetFamilyRuleSet, mcpSkuReferenceId, ruleSet } = this.state;
      const { optimizePivot } = this.props;
      let mcpSku: string | undefined;

      if (attributeModelExplorer !== undefined) {
        let amexReference = attributeModelExplorer;
        try {
          amexReference.selectValue(attributeKey, value, isDisabledSelection, isUserSelected);
        } catch (error) {
          if (error instanceof RevertSelectionError) {
            this.props.onError(error);
            const disabledAttributeKey = undefined;
            this.setErrorMessage(errorAlertMessages.REVERTING_SELECTION, true, true, {
              disabledSelectionKey: disabledAttributeKey,
            });
            return;
          }

          throw error;
        }

        if (isRuleSetFamilyRuleSet) {
          const mcpSkuAttribute = amexReference.getAttribute(attributeKeys.MCPSKU);
          mcpSku = mcpSkuAttribute && mcpSkuAttribute.getResolvedValue();

          // re-creating AMEx only if mcpsku is getting resolved and its not the same sku
          // selection which is not effecting resolved sku like `country`
          if (mcpSkuReferenceId !== mcpSku && mcpSku) {
            const attributeConfigurationMap = Utils.createAttributeConfigurationMapFromAMEx(amexReference);
            amexReference = await this.createAttributeModelExplorer(
              { v1Ruleset: ruleSet },
              attributeConfigurationMap,
              true,
              mcpSku,
            );
          }
        }

        const { stateDescriptor, metadata } = StateDescriptor.createStateFromAttributeModelExplorer(
          amexReference,
          !!optimizePivot,
        );
        const onChangeMetadata: IChangeMetadata = {
          isUserInvoked: true,
        };

        if (stateDescriptor.state === ResolutionState.partiallyResolved) {
          const hasHiddenAttributes =
            amexReference.getAttributes().some((a: IAttribute) => a.metadata.get('isHidden')) || false;

          if (!hasHiddenAttributes) {
            const selectionAttributes = getSelectionAttributes(amexReference);
            const areAllAttributesResolved = selectionAttributes
              .filter((a: IAttribute): a is Attribute => a instanceof Attribute)
              .every(a => a.getResolvedValue() !== undefined);

            if (areAllAttributesResolved) {
              this.props.onChange(stateDescriptor, onChangeMetadata);
              const missingPropertyKeys = getNonSelectionAttributes(amexReference)
                .filter((a: Attribute) => a.isRequired && a.getResolvedValue() === undefined)
                .map((a: Attribute) => a.key);

              throw new RuleSetError(
                `${
                  errorAlertMessages.MISCONFIGURED_PRODUCT
                } The following required attributes are missing values: ${missingPropertyKeys.join(', ')}`,
                {
                  missingAttributeKeys: missingPropertyKeys,
                },
              );
            }
          }
        }

        let duplicateSelection: { selections: { [key: string]: string }; isDuplicateSelection: boolean };

        if (amexReference && amexReference.getPivotAttribute(attributeKey) === undefined) {
          duplicateSelection = this.incrementSelectionCount();
        }

        let disabledSelectionKey: string | undefined;
        if (isDisabledSelection) {
          disabledSelectionKey = attributeKey;
        }

        this.setState(
          prevState => ({
            disabledSelectionKey,
            attributeModelExplorer: amexReference,
            duplicateSelectionCount:
              duplicateSelection && duplicateSelection.isDuplicateSelection ? prevState.duplicateSelectionCount + 1 : 0,
            errorMessage: '',
            isInfoAlert: false,
            isStateUnresolvable: metadata.isStateUnresolvable,
            mcpSkuReferenceId: mcpSku,
            previousSelections:
              duplicateSelection && duplicateSelection.selections
                ? duplicateSelection.selections
                : prevState.previousSelections,
            showErroredOutSelector: false,
          }),
          () => {
            this.props.onChange(stateDescriptor, onChangeMetadata);
          },
        );
      }
    } catch (error) {
      this.props.onError(error);
      if (error instanceof AttributeModelExplorerErrors.EngineError) {
        this.setErrorMessage(errorAlertMessages.SOMETHING_WENT_WRONG, true);
      } else {
        this.setErrorMessage(error.message, true);
      }
    }
  }

  /**
   * Currently a workaround for guiding the user out of a stuck state. See RAD-136 for more details.
   */
  public incrementSelectionCount(): { selections: { [key: string]: string }; isDuplicateSelection: boolean } {
    const { attributeModelExplorer, previousSelections } = this.state;
    let selections = cloneDeep(previousSelections);

    if (attributeModelExplorer !== undefined) {
      selections = attributeModelExplorer
        .getAttributes()
        .reduce((map: { [key: string]: string }, attribute: Attribute) => {
          const assignedValue = attribute.assignedValue;
          if (assignedValue !== undefined) {
            map[attribute.key] = assignedValue;
          }

          return map;
        }, {});

      if (!isEmpty(selections) && !isEmpty(previousSelections) && isEqual(selections, previousSelections)) {
        return {
          selections,
          isDuplicateSelection: true,
        };
      }
    }

    return {
      selections,
      isDuplicateSelection: false,
    };
  }

  public getUserSelections() {
    const { attributeModelExplorer } = this.state;
    let userSelections = {};

    if (attributeModelExplorer !== undefined) {
      userSelections = attributeModelExplorer.getUserSelections();
    }

    return userSelections;
  }

  public getErrorAlert(message: string) {
    const { errorAlertConfiguration } = this.props;
    const { isInfoAlert } = this.state;

    if (errorAlertConfiguration && errorAlertConfiguration.showErrorAlert !== false && message) {
      const { supportContact } = errorAlertConfiguration;

      return (
        <EmailUsAlert
          message={message}
          email={supportContact ? supportContact.email : PRODUCT_SUPPORT_EMAIL}
          emailText={supportContact ? supportContact.name : PRODUCT_SUPPORT_NAME}
          isInfoAlert={isInfoAlert}
        />
      );
    }

    return null;
  }

  public componentDidCatch(error: any, info: any) {
    this.props.onError(error);
    this.setErrorMessage(`${errorAlertMessages.SELECTOR_INITIALIZATION_FAILED} ${error.message}`, false);
  }

  public render() {
    const {
      attributeStateTooltipConfiguration,
      resourceAttributeIcon,
      customizations,
      allowDisabledSelection,
      selectorUI,
      selectorUIConfigurations,
      isColorSwatch,
    } = this.props;
    const {
      attributeModelExplorer,
      isStateUnresolvable,
      duplicateSelectionCount,
      errorMessage,
      showErroredOutSelector,
    } = this.state;

    if (selectorUI) {
      // tslint:disable-next-line
      const SelectorUI = selectorUI;
      if (attributeModelExplorer === undefined) {
        return <Spinner />;
      }

      const transformedOptions = transformStringLiteralToString(getSelectionAttributes(attributeModelExplorer));
      const customSelectorUIProps = {
        selectorUIConfigurations,
        onInputChange: this.onInputChange,
        resetExplorer: this.resetExplorer,
        selectionOptions: transformedOptions,
      };

      return <SelectorUI {...customSelectorUIProps} />;
    }

    if (errorMessage && !showErroredOutSelector) {
      return this.getErrorAlert(errorMessage);
    }

    const misconfiguredProductAlert = this.getErrorAlert(errorMessage);

    const disabledSelectionInfoAlert = this.getDisabledSelectionsInfoAlert();

    if (attributeModelExplorer === undefined) {
      return <Spinner />;
    }

    const selectionOptions = getSelectionAttributes(attributeModelExplorer);

    const isUnResolvableWithValidDisabledAttributes = isStateUnresolvableWithAllValidDisplayedAttributes(
      selectionOptions,
      isStateUnresolvable,
    );

    let unresolvableStateAlert = null;
    if (isUnResolvableWithValidDisabledAttributes) {
      unresolvableStateAlert = <Alert message={errorAlertMessages.UNRESOLVABLE_STATE} dismissible={false} />;
    }

    const layoutProps: ILayoutProps = {
      allowDisabledSelection,
      attributeStateTooltipConfiguration,
      disabledSelectionInfoAlert,
      isColorSwatch,
      misconfiguredProductAlert,
      resourceAttributeIcon,
      selectionOptions,
      unresolvableStateAlert,
      onInputChange: this.onInputChange,
      resetExplorer: this.resetExplorer,
      showTooltip: duplicateSelectionCount > DUPLICATE_SELECTION_WARN_THRESHOLD,
      styleClasses: {},
    };

    return LayoutFactory.buildLayout(layoutProps, customizations, this.props.theme);
  }

  public getResourceRequiredAttributes(): IResourceRequiredAttributes {
    if (this.resourceRequiredAttributes) {
      return Utils.convertRequiredAttributes(this.resourceRequiredAttributes);
    }

    return {
      nonProductAttributes: [],
      productAttributes: [],
    };
  }

  /**
   * Creates a Product Configuration URL using the resolved attributes.
   * @param additionalSelectedPropertyKeys - Properties to include when creating the configuration URL. Only resolved properties will be included. Primarily used as a work-around for Family Rule Sets.
   * @throws When either: this method is called before the Product Explorer initializes; the configured rule set has no reference ID; or the Configuration Service call errors.
   * @returns The Product Configuration URL if it was creatable.
   */
  public async getConfigurationUrl(additionalSelectedPropertyKeys: string[] = []): Promise<string> {
    const { authToken } = this.props;
    const { attributeModel, attributeModelExplorer, referenceId, version } = this.state;

    let productConfigurationUrl: string;

    if (attributeModel !== undefined) {
      throw new Error(errorAlertMessages.CONFIGURATION_URL.ATTRIBUTE_MODEL_ERROR);
    }

    if (attributeModelExplorer === undefined) {
      throw new Error(errorAlertMessages.CONFIGURATION_URL.SELECTOR_UNINITIALIZED);
    } else {
      if (referenceId === undefined) {
        throw new Error(errorAlertMessages.CONFIGURATION_URL.NO_REFERENCE_ID);
      }

      const pivotAttributeSelections = attributeModelExplorer.getPivotAttributes();
      const pivotSelections = {};
      if (pivotAttributeSelections.length > 0) {
        pivotAttributeSelections.forEach(pivotAttribute => {
          if (
            pivotAttribute.metadata.get(MetadataKeys.isDisplayed) ||
            additionalSelectedPropertyKeys.some(k => k.toLowerCase() === pivotAttribute.key.toLowerCase())
          ) {
            if (pivotAttribute.assignedValues.length > 0) {
              pivotSelections[pivotAttribute.key] = pivotAttribute.assignedValues[0];
            } else {
              throw new Error(errorAlertMessages.CONFIGURATION_URL.INCOMPLETE_PRODUCT_SELECTION);
            }
          }
        });
      }
      const attributeSelections: IAttributeSelectionMap = attributeModelExplorer
        .getAttributes()
        .reduce((map: { [key: string]: string }, attribute: Attribute) => {
          /**
           * If the attribute is displayed or included in additionalSelectedPropertyKeys then include it's resolved value
           * else only include it if there's an assigned value.
           */
          if (
            attribute.metadata.get(MetadataKeys.isDisplayed) ||
            additionalSelectedPropertyKeys.some(k => k.toLowerCase() === attribute.key.toLowerCase())
          ) {
            const resolvedValue = attribute.getResolvedValue();
            if (resolvedValue !== undefined) {
              map[attribute.key] = resolvedValue;
            }
          } else {
            const assignedValue = attribute.assignedValue;
            if (assignedValue !== undefined) {
              map[attribute.key] = assignedValue;
            }
          }

          return map;
        }, {});

      const configurationServiceClient = new ConfigurationServiceClient(authToken);
      productConfigurationUrl = await configurationServiceClient.createConfigurationUrl(referenceId, version, {
        ...pivotSelections,
        ...attributeSelections,
      });
    }

    return productConfigurationUrl;
  }

  public async getRequiredAttribute(): Promise<any> {
    const { attributeModel, attributeModelExplorer, referenceId } = this.state;

    if (attributeModel !== undefined) {
      throw new Error(errorAlertMessages.GET_REQUIRED_ATTRIBUTE.ATTRIBUTE_MODEL_ERROR);
    }

    if (attributeModelExplorer === undefined) {
      throw new Error(errorAlertMessages.GET_REQUIRED_ATTRIBUTE.SELECTOR_UNINITIALIZED);
    } else {
      if (referenceId === undefined) {
        throw new Error(errorAlertMessages.GET_REQUIRED_ATTRIBUTE.NO_REFERENCE_ID);
      }

      const pivotAttributeSelections = attributeModelExplorer.getPivotAttributes();
      const pivotSelections = {};

      if (pivotAttributeSelections.length > 0) {
        pivotAttributeSelections.forEach(pivotAttribute => {
          if (pivotAttribute.isRequired) {
            pivotSelections[pivotAttribute.key] = pivotAttribute.assignedValues;
          }
        });
      }

      const attributeSelections: IAttributeSelectionMap = attributeModelExplorer
        .getAttributes()
        .reduce((map: { [key: string]: any }, attribute: Attribute) => {
          if (attribute.isRequired) {
            map[attribute.key] = attribute.getResolvedValue();
          }
          return map;
        }, {});

      return {
        ...attributeSelections,
        ...pivotSelections,
      };
    }
  }

  public async getRemainingOptions(attributeKey: string): Promise<any> {
    const { attributeModel, attributeModelExplorer, referenceId } = this.state;

    if (attributeModel !== undefined) {
      throw new Error(errorAlertMessages.GET_REMAINING_OPTIONS.ATTRIBUTE_MODEL_ERROR);
    }

    if (attributeModelExplorer === undefined) {
      throw new Error(errorAlertMessages.GET_REMAINING_OPTIONS.SELECTOR_UNINITIALIZED);
    } else {
      if (referenceId === undefined) {
        throw new Error(errorAlertMessages.GET_REMAINING_OPTIONS.NO_REFERENCE_ID);
      }

      const attribute = attributeModelExplorer.getAttribute(attributeKey);

      const remainingOptions: any = {};

      remainingOptions[attributeKey] = attribute.validValues;

      return remainingOptions;
    }
  }

  public getResource(): string {
    const { attributeModelExplorer, isRuleSetFamilyRuleSet, product, ruleSet } = this.state;
    const { selectionResource } = this.props;

    if (attributeModelExplorer === undefined) {
      throw new Error(errorAlertMessages.RESOURCE.SELECTOR_UNINITIALIZED);
    }

    if (selectionResource !== undefined && selectionResource in ResourceType) {
      const { stateDescriptor } = StateDescriptor.createStateFromAttributeModelExplorer(
        attributeModelExplorer,
        !!this.props.optimizePivot,
      );
      const selections: ISelection[] = stateDescriptor.attributes as ISelection[];

      selections.forEach(selection => {
        if (
          this.resourceRequiredAttributes &&
          Utils.isRequiredAttribute(this.resourceRequiredAttributes, selection.key)
        ) {
          if (selection.resolvedValue === undefined) {
            throw new Error(errorAlertMessages.RESOURCE.REQUIRED_ATTRIBUTES_NEEDED);
          }
        }
      });

      if (ruleSet) {
        let mcpSKU = ruleSet.referenceId;

        if (isRuleSetFamilyRuleSet) {
          const mcpSkuAttribute = selections.find(
            (selection: ISelection) => selection.key.toLowerCase() === attributeKeys.MCPSKU.toLowerCase(),
          );
          mcpSKU = mcpSkuAttribute ? mcpSkuAttribute.resolvedValue : undefined;
        }

        if (mcpSKU && this.resourceRequiredAttributes) {
          const skuResourceAttributes: ISKURequiredAttributes = {
            mcpSKU,
            resourceRequiredAttributes: this.resourceRequiredAttributes,
          };

          return this.attributeProcessor.getResource(
            skuResourceAttributes,
            selectionResource,
            selections,
            this.resourceRequiredAttributes,
          );
        }
      } else {
        if (this.resourceRequiredAttributes && product) {
          return this.attributeProcessor.getResource(
            product,
            selectionResource,
            selections,
            this.resourceRequiredAttributes,
          );
        }
        throw new Error(errorAlertMessages.PRODUCT_DEFINITION_NOT_FOUND);
      }

      throw new Error(errorAlertMessages.RESOURCE.UNRESOLVED);
    }

    throw new Error(errorAlertMessages.RESOURCE.NO_OUTPUT_RESOURCE);
  }

  public resetSelector(retainInitialSelections: boolean = false): void {
    this.resetExplorer(retainInitialSelections);
  }

  private setErrorMessage(
    message: string,
    showErroredOutSelector: boolean,
    isInfoAlert: boolean = false,
    remainingState: object = {},
  ) {
    const { errorAlertConfiguration } = this.props;

    if (errorAlertConfiguration && errorAlertConfiguration.showErrorAlert !== false) {
      this.setState({ isInfoAlert, showErroredOutSelector, errorMessage: message, ...remainingState });
    }
  }

  private resetExplorer(retainInitialSelections: boolean) {
    const { attributeModelExplorer, isRuleSetFamilyRuleSet, ruleSet } = this.state;

    if (attributeModelExplorer !== undefined) {
      if (retainInitialSelections) {
        attributeModelExplorer.resetWithConfiguration();
      } else {
        attributeModelExplorer.reset();
      }

      const mcpSkuReferenceId = ruleSet && isRuleSetFamilyRuleSet ? Utils.getMcpSkuFromRuleSet(ruleSet) : undefined;
      const onChangeMetadata: IChangeMetadata = {
        isUserInvoked: true,
      };

      const { stateDescriptor, metadata } = StateDescriptor.createStateFromAttributeModelExplorer(
        attributeModelExplorer,
        !!this.props.optimizePivot,
      );

      this.setState(
        {
          attributeModelExplorer,
          mcpSkuReferenceId,
          disabledSelectionKey: undefined,
          duplicateSelectionCount: 0,
          errorMessage: '',
          isInfoAlert: false,
          isStateUnresolvable: metadata.isStateUnresolvable,
          showErroredOutSelector: false,
        },
        () => {
          this.props.onChange(stateDescriptor, onChangeMetadata);
          this.props.onReset();
        },
      );
    }
  }

  /**
   * Selector is initialized by fetching the product or identifying the product model from the
   * props and attribute model explorer is created accordingly.
   */
  private async initializeSelector() {
    try {
      const amexProductInputs = await this.getProductFromProps();
      this.resourceRequiredAttributes = Utils.convertResourceAttributes(this.props.resourceRequiredAttributes);
      await this.buildSelectorState(amexProductInputs, true);
    } catch (error) {
      this.props.onError(error);
      this.setErrorMessage(`${errorAlertMessages.SELECTOR_INITIALIZATION_FAILED} ${error.message}`, false);
    }
  }

  /**
   * Selector is reinitialized by fetching the product if required. And attribute model explorer
   * is created only if the props are modified which affects the amex.
   *
   * @param prevProps Previous props
   */
  private async reintializeSelector(prevProps: IGenericSelectorProps) {
    try {
      if (
        !isEqual(this.props.resourceRequiredAttributes, prevProps.resourceRequiredAttributes) ||
        !isEqual(this.props.selectionResource, prevProps.selectionResource) ||
        !isEqual(this.props.selectWithProductAttributes, prevProps.selectWithProductAttributes)
      ) {
        this.resourceRequiredAttributes = Utils.convertResourceAttributes(this.props.resourceRequiredAttributes);
      }

      if (!isEqual(Utils.getProductAffectingFromProps(this.props), Utils.getProductAffectingFromProps(prevProps))) {
        const amexProductInputs = await this.getProductFromProps();

        if (isEmpty(this.props.resourceRequiredAttributes)) {
          this.resourceRequiredAttributes = undefined;
        }

        await this.buildSelectorState(amexProductInputs, false);
      } else if (
        !isEqual(Utils.getAMExAffectingConfigProps(this.props), Utils.getAMExAffectingConfigProps(prevProps))
      ) {
        const amexProductInputs: IAMExProductInputs = {
          attributeModel: this.state.attributeModel,
          productConfiguration: undefined,
          serializedData: this.state.serializedData,
          v1Ruleset: this.state.ruleSet,
          v2Product: this.state.product,
        };

        await this.buildSelectorState(amexProductInputs, false);
      }
    } catch (error) {
      this.props.onError(error);
      this.setErrorMessage(`${errorAlertMessages.SELECTOR_INITIALIZATION_FAILED} ${error.message}`, false);
    }
  }

  private getDisabledSelectionsInfoAlert() {
    const { allowDisabledSelection } = this.props;
    const { disabledSelectionKey } = this.state;
    let component: JSX.Element | undefined;

    if (allowDisabledSelection && disabledSelectionKey) {
      component = <DisabledSelectionAlert attributeKey={disabledSelectionKey} />;
    }

    return component;
  }

  /**
   * Gets the product from props which could be from productId or
   * by identifying the product model from passed product definition
   */
  private async getProductFromProps(): Promise<IAMExProductInputs> {
    const { authToken, configurationUrl, product, productId, productVersion, enableSerialization } = this.props;

    let v1Ruleset;
    let v2Product;
    let attributeModel;
    let serializedData: DeserializedAttributeModelData | undefined;
    let productConfiguration: IProductConfiguration | undefined;

    if (configurationUrl) {
      const configurationServiceClient = new ConfigurationServiceClient(authToken);
      productConfiguration = await configurationServiceClient.getConfiguration(configurationUrl);

      if (productConfiguration.referenceId) {
        const referenceId = productConfiguration.referenceId;
        const ruleServiceClient = new RuleServiceClient(authToken);

        v1Ruleset = await ruleServiceClient.getRuleSet(referenceId);
      } else if (productConfiguration.productId) {
        const productServiceClient = new ProductServiceClient(authToken);

        v2Product = await productServiceClient.getV2Product(
          productConfiguration.productId,
          productConfiguration.productVersion,
        );
      } else {
        throw new Error(errorAlertMessages.CONFIGURATION_URL.SELECTOR_CREATION_FAILED);
      }
    } else if (product) {
      const productModel: ProductModel = Utils.identifyProductModel(product);

      switch (productModel) {
        case ProductModel.V1:
          v1Ruleset = product;
          break;
        case ProductModel.V2:
          v2Product = product as IProduct;
          break;
        case ProductModel.AttributeModel:
          attributeModel = product as IAttributeModelConfiguration;
          break;
        case ProductModel.SerializedModel:
          const serializedProduct = product as ISerializedData;
          serializedData = ModelUtilities.buildSerializedAttributeConfigurations(
            serializedProduct.serializedData,
            serializedProduct.attributeMetadata,
          );
          break;
      }
    } else if (productId) {
      const productRepository = new ProductRepository(authToken);
      const result = await productRepository.getProduct(
        productId,
        productVersion,
        enableSerialization,
      );

      v2Product = result.product;
      v1Ruleset = result.ruleSet;
      serializedData = result.composedProduct;
    } else {
      throw new Error(errorAlertMessages.INVALID_PRODUCT_CONFIGURATION);
    }

    const amexProductInputs: IAMExProductInputs = {
      attributeModel,
      productConfiguration,
      serializedData,
      v1Ruleset,
      v2Product,
    };

    return amexProductInputs;
  }

  /**
   * Build selector state
   *
   * @param amexProductInputs
   * @param isComponentLoaded
   */
  private async buildSelectorState(amexProductInputs: IAMExProductInputs, isComponentLoaded?: boolean): Promise<void> {
    const { attributeConfigurations, onLoad, selectWithProductAttributes } = this.props;

    const v1Ruleset = amexProductInputs.v1Ruleset;
    const v2Product = amexProductInputs.v2Product;
    const attributeModel = amexProductInputs.attributeModel;
    const serializedData = amexProductInputs.serializedData;
    const productConfiguration = amexProductInputs.productConfiguration;

    const isRuleSetFamilyRuleSet = Utils.isRuleSetFamilyRuleSet(v1Ruleset);

    let mcpSkuReferenceId: any = isRuleSetFamilyRuleSet ? Utils.getMcpSkuFromRuleSet(v1Ruleset) : undefined;
    if (v1Ruleset && (attributeConfigurations.mcpsku || attributeConfigurations.McpSku)) {
      mcpSkuReferenceId = (attributeConfigurations.mcpsku || attributeConfigurations.McpSku).initialSelection;
    }
    const attributeModelExplorer: IModelExplorerRepo = await this.createAttributeModelExplorer(
      amexProductInputs,
      attributeConfigurations,
      isRuleSetFamilyRuleSet && isEmpty(mcpSkuReferenceId) ? true : !!selectWithProductAttributes,
      mcpSkuReferenceId,
    );

    if (productConfiguration) {
      attributeModelExplorer.selectValues(productConfiguration.attributeSelections);
    }
    const selectionOptions = getSelectionAttributes(attributeModelExplorer);
    const { stateDescriptor, metadata } = StateDescriptor.createStateFromAttributeModelExplorer(
      attributeModelExplorer,
      !!this.props.optimizePivot,
    );

    this.setState(
      {
        attributeModel,
        attributeModelExplorer,
        isRuleSetFamilyRuleSet,
        mcpSkuReferenceId,
        serializedData,
        disabledSelectionKey: undefined,
        errorMessage: selectionOptions.length === 0 ? errorAlertMessages.NO_SELECTION_ATTRIBUTES : '',
        isInfoAlert: selectionOptions.length === 0,
        isStateUnresolvable: metadata.isStateUnresolvable,
        product: v2Product,
        referenceId: v2Product ? v2Product.productId : v1Ruleset ? v1Ruleset.referenceId : undefined,
        ruleSet: v1Ruleset,
        showErroredOutSelector: false,
        version: v2Product ? v2Product.version?.toString() : undefined,
      },
      () =>
        isComponentLoaded ? onLoad(stateDescriptor) : this.props.onChange(stateDescriptor, { isUserInvoked: false }),
    );
  }

  /**
   * Creates attribute model explorer
   *
   * @param amexProductInputs Set of product definitions
   * @param attributeConfigurations
   * @param selectWithProductAttributes
   * @param mcpSkuReferenceId Should be passed only mcpSku is resolved from a family ruleset
   */
  private async createAttributeModelExplorer(
    amexProductInputs: IAMExProductInputs,
    attributeConfigurations: IAttributeConfigurationMap,
    selectWithProductAttributes: boolean,
    mcpSkuReferenceId?: string,
  ): Promise<IModelExplorerRepo> {
    const { authToken, displaySingleValuedAttributes, optimizePivot, selectionResource } = this.props;

    let attributeModelExplorer: IModelExplorerRepo;
    const v1Ruleset = amexProductInputs.v1Ruleset;
    const v2Product = amexProductInputs.v2Product;
    const attributeModel = amexProductInputs.attributeModel;
    const serializedData = amexProductInputs.serializedData;

    const productDefinition = v1Ruleset || v2Product || attributeModel || serializedData;
    const isRuleSetFamilyRuleSet = Utils.isRuleSetFamilyRuleSet(v1Ruleset);
    const productOrMcpSkuId = isRuleSetFamilyRuleSet
      ? mcpSkuReferenceId
        ? mcpSkuReferenceId
        : undefined
      : v2Product || get(v1Ruleset, 'referenceId', undefined);

    if (productDefinition) {
      this.attributeProcessor = AttributesProcessorFactory.getProcessor(
        productDefinition,
        selectionResource === undefined && this.resourceRequiredAttributes
          ? SelectorCustomResourceType.CUSTOM
          : selectionResource,
        selectWithProductAttributes,
        displaySingleValuedAttributes,
        optimizePivot,
      );

      if (
        !this.resourceRequiredAttributes &&
        selectionResource !== undefined &&
        selectionResource in ResourceType &&
        productOrMcpSkuId
      ) {
        this.resourceRequiredAttributes = await this.attributeProcessor.getResourceRequiredAttributes(
          authToken,
          productOrMcpSkuId,
          selectionResource,
        );
      }

      attributeModelExplorer = this.attributeProcessor.createAttributeModelExplorer(
        productDefinition,
        attributeConfigurations,
        this.resourceRequiredAttributes,
      );
    } else {
      throw new Error(errorAlertMessages.INVALID_PRODUCT_CONFIGURATION);
    }

    return attributeModelExplorer;
  }
}
