import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Select } from '@cimpress/react-components';

import {
  searchAndSetRoutingConfiguration,
  setEtag,
  setRoutingConfiguration,
  setSkuCode,
} from '../../../features/selectedConfiguration/selectedConfigurationSlice';
import { setSelectedItem } from '../../../features/selectedItem/selectedItemSlice';
import { ItemWithOrderMetadata } from '../../../services/items';
import { getRoutingConfigurationByBuyerAndSku } from '../../../services/routingConfigurationService';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ChangeRoutingConfigModal from '../ChangeRoutingConfigModal';
import ItemSelectorLabel from './ItemSelectorLabel';

const ItemSelector = ({ ...props }) => {
  const { accountId } = useParams();

  const dispatch = useAppDispatch();
  const selectedItem = useAppSelector((state) => state.selectedItem.item);

  const currentSkuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);
  const currentRoutingConfiguration = useAppSelector(
    (state) => state.selectedConfiguration.routingConfiguration,
  );
  const isLoading = useAppSelector((state) => state.resourceSearch.isLoading);

  const [showModal, setShowModal] = useState(false);
  const [tempSelectedItem, setTempSelectedItem] = useState<ItemWithOrderMetadata | undefined>();

  const trySelectItem = ({ value }: { value: ItemWithOrderMetadata }) => {
    setTempSelectedItem(value);
    if (!currentSkuCode || !currentRoutingConfiguration || currentSkuCode === value.mcpSku) {
      dispatch(setSelectedItem(value));
      dispatch(setSkuCode(value.mcpSku));
      if (accountId) {
        dispatch(searchAndSetRoutingConfiguration({ buyer: accountId, skuCode: value.mcpSku }));
      }
    } else {
      setShowModal(true);
    }
  };

  const cancelModal = () => {
    setShowModal(false);
  };

  const findMatchingConfiguration = async () => {
    if (accountId && tempSelectedItem) {
      const { configuration, etag } = await getRoutingConfigurationByBuyerAndSku({
        buyer: accountId,
        skuCode: tempSelectedItem.mcpSku,
      });
      dispatch(setRoutingConfiguration(configuration));
      dispatch(setEtag(etag));
      dispatch(setSkuCode(tempSelectedItem.mcpSku));
      dispatch(setSelectedItem(tempSelectedItem));
    }
    setShowModal(false);
  };

  const options =
    props.items?.map((item: ItemWithOrderMetadata) => {
      const label = <ItemSelectorLabel item={item} isLoading={isLoading} key={uuid()} />;
      return { label, value: item };
    }) ?? [];

  const value = selectedItem
    ? {
        label: <ItemSelectorLabel item={selectedItem} isLoading={isLoading} />,
        value: selectedItem,
      }
    : '';

  let label: string;
  if (isLoading) {
    label = 'Loading...';
  } else if (props.items?.length) {
    label = 'Select an item';
  } else {
    label = 'No items found';
  }

  return (
    <div>
      <ChangeRoutingConfigModal
        showModal={showModal}
        onRequestHide={cancelModal}
        confirmLoadNewConfiguration={findMatchingConfiguration}
      />
      <Select
        {...props}
        label={label}
        options={options}
        value={value}
        onChange={trySelectItem}
        isDisabled={!props.items?.length}
      />
    </div>
  );
};

export default ItemSelector;
