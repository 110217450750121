/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { Select } from '@cimpress/react-components';

const ValueDropdownSelect = styled(Select)`
  max-width: 200px;
  min-width: 100px;
`;

export default ValueDropdownSelect;
