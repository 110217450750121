/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { setError } from '../features/alerts/alertContentSlice';
import { resetRoutingConfiguration } from '../features/selectedConfiguration/selectedConfigurationSlice';
import { useAppDispatch } from '../store/hooks';
import { AppAlert } from './AppAlert';
import SearchBySku from './SearchBySku';

export default function ConfigurationSearchPage() {
  // Retrieve any data passed to us when another route navigated here
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));

  useEffect(() => {
    if (state?.error) {
      dispatchErrors(state?.error);
    }
    dispatch(resetRoutingConfiguration());
  }, []);

  return (
    <>
      <AppAlert />
      <SearchBySku />
    </>
  );
}
