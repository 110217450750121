import { AttributeConfigurationMap, IProduct } from '@cimpress-technology/attribute-model-explorer';
import { cloneDeep, get, mapKeys } from 'lodash';
import { IConfigurationMapBuilder } from '../..';
import { IAttributeConfigurationMap, MetadataKeys } from '../../../../Models';
import { getRangeWrappedAttribute, isV2AttributeInferable } from '../../../../UtilsV2';
import { ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY, attributeKeys, PIVOT_DEFAULT_DISPLAY_PRIORITY } from '../../../constants';

/**
 * This class builds configurationMap only for the product attributes.
 * Situtaion when user wants selection experience on product attributes.
 */
export default class ProductConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!ProductConfigurationMapBuilder.instance) {
      ProductConfigurationMapBuilder.instance = new ProductConfigurationMapBuilder();
    }

    ProductConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return ProductConfigurationMapBuilder.instance;
  }

  private static instance: ProductConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  public buildConfigurationMap(
    product: IProduct,
    configurationMap: IAttributeConfigurationMap,
  ): AttributeConfigurationMap {
    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());
    product.options.forEach((option: any) => {
      const configuration: any = cloneDeep(normalizedConfigurationMap[option.name.toLowerCase()] || {});

      if (configuration.displayPriority === undefined) {
        configuration.displayPriority = configuration.isPivot
          ? PIVOT_DEFAULT_DISPLAY_PRIORITY
          : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
      }

      // This condition addresses scenario when an attribute is inferable
      // We hide inferable attributes until and unless consumer
      // doesn't enforce to display it through attributeConfiguration.
      if (configuration.isHidden === undefined) {
        configuration.isHidden = !this.displaySingleValuedAttributes && isV2AttributeInferable(option);
      }

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
      };

      normalizedConfigurationMap[option.name.toLowerCase()] = configuration;
    });

    product.properties?.forEach((property: any) => {
      const configuration: any = get(normalizedConfigurationMap, property.name.toLowerCase(), {});

      configuration.metadata = {
        // If the user has explicitly passed configuration to display the property
        [MetadataKeys.isDisplayed]: configuration.isHidden === false,
      };

      normalizedConfigurationMap[property.name.toLowerCase()] = configuration;
    });

    const quantityConfiguration: any = cloneDeep(normalizedConfigurationMap[attributeKeys.QUANTITY] || {});

    if (quantityConfiguration.displayPriority === undefined) {
      quantityConfiguration.displayPriority = quantityConfiguration.isPivot
        ? PIVOT_DEFAULT_DISPLAY_PRIORITY
        : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
    }

    if (quantityConfiguration.isHidden === undefined) {
      quantityConfiguration.isHidden = isV2AttributeInferable(
        getRangeWrappedAttribute(
          product[Object.keys(product).find((key: string) => key.toLowerCase() === attributeKeys.QUANTITY) || 0],
        ),
      );
    }

    quantityConfiguration.metadata = {
      [MetadataKeys.displayPriority]: quantityConfiguration.displayPriority,
      [MetadataKeys.isDisplayed]: !quantityConfiguration.isHidden,
    };

    normalizedConfigurationMap[attributeKeys.QUANTITY] = quantityConfiguration;

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
