import findAttributeDependencies from '@cimpress-technology/attribute-dependencies';
import { V1AttributeTypes } from '@cimpress-technology/attribute-model-explorer';
// @ts-ignore
import { constants } from '@cimpress-technology/math-evaluation-helper';
import { IRequiredAttributes } from '@cimpress-technology/selector-resource-formatter';
import { v2ProductAttributeTypes, v2ProductAttributeValueTypes } from './Components/constants';
import { ProductModel } from './Components/Processors/AttributesProcessorFactory';

// TO-Do Need to replace these enums with rule engine enums once
// ticket https://cimpress-support.atlassian.net/browse/RAD-979 is done
export enum ProductConstraintValueSpecifierType {
  formula = 'formula',
}

export enum ProductPropertyAssignmentResultType {
  formula = 'formula',
}

const VALUES_ATTRIBUTE_TYPE = 'values';
const ATTRIBUTE_TYPE = 'type';

export function getUniqueValues(arr: string[]): string[] {
  return arr.filter((value, index, self) => self.indexOf(value) === index);
}

export function areKeysEqual(key1: string, key2: string) {
  if (key1 === key2) {
    return true;
  }

  if (key1 && key2 && key1.toLowerCase() === key2.toLowerCase()) {
    return true;
  }

  return false;
}

export function extractVariablesFromFormula(formula: string): string[] {
  const formulaVariables = formula.match(constants.VARIABLE_TOKEN_REGEX);
  let variables: string[] = [];

  if (formulaVariables !== null) {
    variables = formulaVariables.map((match: string) => match.replace(constants.VARIABLE_TOKEN_REPLACE_REGEX, ''));
  }

  return variables;
}

function getV2AttributeValue(resourceAttributeValues: any[], type: string) {
  switch (type) {
    case V1AttributeTypes.listOfRanges:
      return resourceAttributeValues.map(resourceAttributeValue => {
        const value = {};
        value[ATTRIBUTE_TYPE] = v2ProductAttributeValueTypes.RANGE;
        value[v2ProductAttributeValueTypes.RANGE] = resourceAttributeValue;

        return value;
      });
    case V1AttributeTypes.listOfValues:
      return resourceAttributeValues.map(resourceAttributeValue => {
        const value = {};
        value[ATTRIBUTE_TYPE] = v2ProductAttributeValueTypes.VALUE;
        value[v2ProductAttributeValueTypes.VALUE] = resourceAttributeValue.value;

        return value;
      });
    default:
      return {};
  }
}

function getV2AttributeType(requiredAttributeType: string, requiredAttributeName: string) {
  switch (requiredAttributeType) {
    case V1AttributeTypes.listOfRanges:
    case V1AttributeTypes.formula:
      return v2ProductAttributeTypes.NUMBER;
    case V1AttributeTypes.listOfValues:
      return v2ProductAttributeTypes.STRING;
    default:
      throw new Error(
        `Unrelevant required attribute input type: ${requiredAttributeType} for ${requiredAttributeName}`,
      );
  }
}

/**
 * Given a v2 product and find dependencies of all the provided attributes
 * @param product The v2 product to pull attributes from
 * @param attributes The set of attributes for those dependencies need to find
 * @return Full string array of dependendent attributes
 */
export function findAttributesDependencies(product: any, attributes: string[] = []): string[] {
  const fullCustomRequired: string[] = [];

  attributes.forEach(attribute => {
    fullCustomRequired.push(...findAttributeDependencies(attribute, product, ProductModel.V2));
  });

  return getUniqueValues(fullCustomRequired);
}

export function convertRequiredResourceAttributeObjectToList(
  requiredResourceAttributes: IRequiredAttributes,
): string[] {
  const requiredResourceAttributeKeys = (requiredResourceAttributes.productAttributes || [])
    .map((productAttribute: any) => productAttribute.attributeName.toLowerCase())
    .concat(
      (requiredResourceAttributes.nonProductAttributes || []).map((nonProductAttribute: any) =>
        nonProductAttribute.attributeKey.toLowerCase(),
      ),
    );

  return requiredResourceAttributeKeys;
}

export function isV2AttributeInferable(v2Attribute: any): boolean {
  let isAttributeInferable = false;

  if (v2Attribute.type === v2ProductAttributeTypes.STRING) {
    isAttributeInferable = v2Attribute.values && v2Attribute.values.length === 1;
  } else if (v2Attribute.type === v2ProductAttributeTypes.NUMBER) {
    if (v2Attribute.values.length === 1 && v2Attribute.values[0].type === v2ProductAttributeValueTypes.RANGE) {
      const range = v2Attribute.values[0].range;
      isAttributeInferable = range && range.minimum === range.maximum;
    }
  }

  return isAttributeInferable;
}

export function getRangeWrappedAttribute(rangeValue: any) {
  return {
    type: v2ProductAttributeTypes.NUMBER,
    values: rangeValue,
  };
}

export function convertResourceInputIntoV2ProductOptionsFormat(requiredAttributes: any[] = []): any[] {
  const attributes: any[] = [];

  requiredAttributes.forEach((requiredAttribute: any) => {
    const attributeType = requiredAttribute.type;

    if (
      (attributeType === V1AttributeTypes.listOfValues && requiredAttribute.attributeValues.length > 0) ||
      (attributeType === V1AttributeTypes.listOfRanges && requiredAttribute.attributeRanges.length > 0)
    ) {
      const attribute = {
        name: requiredAttribute.attributeKey,
        type: getV2AttributeType(requiredAttribute.type, requiredAttribute.attributeKey),
      };

      if (areKeysEqual(requiredAttribute.type, V1AttributeTypes.listOfRanges)) {
        attribute[VALUES_ATTRIBUTE_TYPE] = getV2AttributeValue(
          requiredAttribute.attributeRanges,
          requiredAttribute.type,
        );
      } else {
        attribute[VALUES_ATTRIBUTE_TYPE] = getV2AttributeValue(
          requiredAttribute.attributeValues,
          requiredAttribute.type,
        );
      }

      attributes.push(attribute);
    }
  });

  return attributes;
}
