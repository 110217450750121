import React from 'react';

import { Button, Modal, Spinner } from '@cimpress/react-components';

import { setLoading } from '../../features/selectedConfiguration/selectedConfigurationSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styles from './changeRoutingConfigModal.module.scss';

export default function ChangeRoutingConfigModal({
  showModal,
  onRequestHide,
  confirmLoadNewConfiguration,
}: {
  showModal: boolean;
  onRequestHide: () => void;
  confirmLoadNewConfiguration: () => void;
}) {
  const loading = useAppSelector((state) => state.selectedConfiguration.loading);
  const dispatch = useAppDispatch();

  const searchNewConfiguration = () => {
    confirmLoadNewConfiguration();
    dispatch(setLoading(true));
  };

  const footer = (
    <div className={styles.footer}>
      <Button variant="secondary" onClick={searchNewConfiguration}>
        Load New Configuration
      </Button>
      <Button variant="primary" onClick={onRequestHide}>
        Cancel
      </Button>
    </div>
  );
  return (
    <Modal
      show={showModal}
      closeButton={true}
      onRequestHide={onRequestHide}
      title="Selected SkuCode not applicable to current Routing Configuration"
      footer={footer}
    >
      <p>
        The SkuCode of the selected item is different than the SkuCode for the Routing Configuration
        you have open. Do you want to load the Routing Configuration for this new selected SkuCode?
      </p>
      <p className={styles.bottomParagraph}>
        If so, you will lose any unpublished changes made to your current Routing Configuration.
      </p>
      {loading && (
        <div className={styles.loading}>
          <Spinner />
        </div>
      )}
    </Modal>
  );
}
