import { Spinner } from '@cimpress/react-components';
import { cloneDeep, isEqual } from 'lodash';
import React, { Component } from 'react';
import { RuleServiceClient, VPCatalogServiceClient } from '../Services';
import './common.css';
import { errorAlertMessages, PRODUCT_SUPPORT_EMAIL, PRODUCT_SUPPORT_NAME } from './constants';
import { VPPrdToRulesetConverter } from './Converters/VPPrdToRulesetConverter';
import EmailUsAlert from './EmailUsAlert';
import { IGenericSelectorProps, IRuleSet } from './Interfaces';

export interface IVPSelectorProps {
  forwardedRef: any;
}

export interface IVPSelectorState {
  convertedRuleSet?: IRuleSet;
  errorMessage?: string;
  loading?: boolean;
}

// tslint:disable-next-line: variable-name
export function vpSelector(WrappedComponent: React.ComponentType<IGenericSelectorProps>) {
  class VPSelector extends Component<IGenericSelectorProps & IVPSelectorProps, IVPSelectorState> {
    public static displayName: string;

    constructor(props: IGenericSelectorProps & IVPSelectorProps) {
      super(props);

      this.state = { loading: true, errorMessage: '' };
    }

    public isVistaprintPrd(product: any) {
      return (
        product.hasOwnProperty('key') &&
        product.hasOwnProperty('version') &&
        product.hasOwnProperty('name') &&
        product.hasOwnProperty('options') &&
        product.hasOwnProperty('categories')
      );
    }

    public getVPProductAffectingProps(props: IGenericSelectorProps) {
      const clonedProps = cloneDeep(props);

      return {
        product: clonedProps.product,
        productId: clonedProps.productId,
        productVersion: clonedProps.productVersion,
      };
    }

    public async componentDidMount() {
      this.getV1RulesetFromPrd();
    }

    public async componentDidUpdate(prevProps: IGenericSelectorProps & IVPSelectorProps) {
      if (!isEqual(this.getVPProductAffectingProps(this.props), this.getVPProductAffectingProps(prevProps))) {
        this.getV1RulesetFromPrd();
      }
    }

    public render() {
      const { convertedRuleSet, loading, errorMessage } = this.state;

      const { product, forwardedRef, productId, productVersion, ...remainingProps } = { ...this.props };

      let componentToBeRendered;
      if (loading) {
        componentToBeRendered = <Spinner />;
      } else if (errorMessage) {
        componentToBeRendered = this.getErrorAlert(errorMessage);
      } else {
        componentToBeRendered = (
          <WrappedComponent
            // @ts-ignore
            ref={forwardedRef}
            product={convertedRuleSet}
            {...remainingProps}
          />
        );
      }

      return componentToBeRendered;
    }

    private async getV1RulesetFromPrd(): Promise<void> {
      const { productId, productVersion, product, authToken } = this.props;
      const ruleServiceClient = new RuleServiceClient(authToken);
      const vpService = new VPCatalogServiceClient();
      let v1Ruleset;
      let vpProduct:any;

      this.setState({
        errorMessage: '',
        loading: true,
      });

      try {
        if (product) {
          if (!this.isVistaprintPrd(product)) {
            throw new Error(errorAlertMessages.MALFORMED_VISTAPRINT_PRD);
          }

          vpProduct = product;

          const mcpSkuProduct = await vpService.getVPPrdEquivalentMCPProductId(vpProduct.key);

          v1Ruleset = await ruleServiceClient.getRuleSet(mcpSkuProduct.skuId);
        } else if (productId) {
          const prdProduct = await vpService.getVPProduct(productId, productVersion);

          const mcpSkuProduct = await vpService.getVPPrdEquivalentMCPProductId(prdProduct.key);

          vpProduct = prdProduct;

          v1Ruleset = await ruleServiceClient.getRuleSet(mcpSkuProduct.skuId);
        } else {
          throw new Error(errorAlertMessages.INVALID_PRODUCT_CONFIGURATION);
        }

        // filter out the values which is not present in vistaprint prd option list and range
        const vpPrdToRulesetConverter = new VPPrdToRulesetConverter(vpProduct, v1Ruleset);
        v1Ruleset.whiteList = vpPrdToRulesetConverter.convert();

        this.setState({
          convertedRuleSet: v1Ruleset,
          loading: false,
        });
      } catch (error) {
        // tslint:disable:no-console
        console.error(error);

        this.setState({
          errorMessage: error.toString(),
          loading: false,
        });
        this.props.onError(error);
      }
    }

    private getErrorAlert(message: string) {
      return (
        <EmailUsAlert
          message={message}
          email={PRODUCT_SUPPORT_EMAIL}
          emailText={PRODUCT_SUPPORT_NAME}
          isInfoAlert={false}
        />
      );
    }
  }

  VPSelector.displayName = 'VPSelector';

  return React.forwardRef<IGenericSelectorProps, any>((props, ref) => {
    return <VPSelector {...props} forwardedRef={ref} />;
  });
}
