// @ts-ignore
// tslint:disable-next-line:no-submodule-imports
import IconLayers from '@cimpress-technology/react-streamline-icons/lib/IconLayers';
import { colors } from '@cimpress/react-components';
import React from 'react';

/* tslint:disable-next-line */
const DefaultResourceAttributeIcon = () => <IconLayers color={colors.danger.darker} />;

export default DefaultResourceAttributeIcon;
