import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';
import IMerchantProduct from '../Components/Interfaces/IMerchantProduct';

const MERCHANT_PRODUCT_ORCHESTRATION_SERVICE_URL = 'https://merchant-orchestration-api.products.cimpress.io';

/**
 * Client to interact with the Merchant Product Orchestration Service
 */
export default class MerchantProductOrchestrationService {
  /**
   * @property authToken - The OAuth JWT to authenticate requests with.
   */
  private readonly authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  /**
   * Retrieve the Merchant Product matching the provided Product ID and, optionally, the version.
   * When version is not provided, then we retrieve the current version.
   * @param {string} productId - an identifier for a Merchant Product.
   * @param {string} [version] - the version of the Merchant Product to retrieve. When not provided, retrieve current version.
   * @returns {Promise<IMerchantProduct>} The requested Merchant Product.
   */
  public async getMerchantProduct(productId: string, version?: string): Promise<IMerchantProduct> {
    let requestedVersion: string | undefined;
    let requestUrl: string;

    if (version === undefined) {
      requestedVersion = 'current';
      requestUrl = `${MERCHANT_PRODUCT_ORCHESTRATION_SERVICE_URL}/v1/products/${productId}/current-version`;
    }
    else {
      requestedVersion = version;
      requestUrl = `${MERCHANT_PRODUCT_ORCHESTRATION_SERVICE_URL}/v1/products/${productId}/versions/${requestedVersion}`;
    }

    const response = await fetch(requestUrl, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${this.authToken}`,
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Could not find Product ID ${productId} with version ${requestedVersion}`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`${errorAlertMessages.ERROR_FETCHING_PRODUCT} ${productId} with version ${requestedVersion}`);
    }
  }
}
