import _ from 'lodash';
import { RawNodeDatum } from 'react-d3-tree';

import { MAX_TREE_DEPTH } from '../constants/constants';
import { WorkingRoutingConfigurationNode, WorkingRoutingConfigurationV3 } from '../types';
import { possibleNextNodeIds } from './nodeUtils';

export function buildTreeAndIdentifyUnreachableNodes(rc: WorkingRoutingConfigurationV3): {
  tree: RawNodeDatum;
  unreachableNodes: WorkingRoutingConfigurationNode[];
  nodeIdsNotFound: string[];
} {
  // react-d3-tree takes its data in the form of a reference to a RawNodeDatum,
  // which is a nested tree of more RawNodeDatums.

  const nodeDictionary = _.reduce(
    rc.nodes,
    (dictionary, node) => {
      dictionary[node.id] = node;
      return dictionary;
    },
    {} as Record<string, WorkingRoutingConfigurationNode>,
  );
  const startingNode = nodeDictionary[rc.startingNodeId];

  const reachableNodeIds = new Set<string>();

  // Reference to the root node that defines the tree.
  // This is what we return from the function after all the children
  // and their connections are hydrated.
  const root: RawNodeDatum = {
    name: startingNode.name ?? startingNode.description ?? startingNode.id,
    attributes: { description: startingNode.description ?? '', id: startingNode.id },
    children: [],
  };

  reachableNodeIds.add(startingNode.id);

  // This function is ultimately a depth-first population of
  // nodes and their children, but the stack keeps track of all
  // of the sibling nodes beneath children nodes.
  const stack: { node: RawNodeDatum; nextNodeIds: string[]; depth: number }[] = [];
  const nextNodeIds = possibleNextNodeIds(startingNode);

  stack.push({ node: root, nextNodeIds, depth: 1 });

  let maxDepth = 0;

  const nodeIdsNotFound: string[] = [];

  while (stack.length > 0) {
    const currentNode = stack.pop()!;
    maxDepth = Math.max(maxDepth, currentNode.depth);

    if (maxDepth > MAX_TREE_DEPTH) {
      throw new Error(
        `Routing configuration is deeper than ${MAX_TREE_DEPTH} levels;
        there is probably an infinite loop in the structure. 
        For assistance, please reach out to
        @platformsupport in the #mcp-platform-support Slack channel`,
      );
    }

    // Add all the child nodes to the stack, as well as
    // add them to the children array of the current node
    for (const nextNodeId of currentNode.nextNodeIds) {
      const nextNode = nodeDictionary[nextNodeId];
      if (nextNode === undefined) {
        nodeIdsNotFound.push(nextNodeId);
        continue;
      }
      reachableNodeIds.add(nextNodeId);

      const childNode: RawNodeDatum = {
        name: nextNode.name || nextNode.description || nextNode.id,
        attributes: { id: nextNode.id, description: nextNode.description || '' },
        children: [],
      };
      currentNode!.node.children!.push(childNode);

      stack.push({
        node: childNode,
        nextNodeIds: possibleNextNodeIds(nextNode),
        depth: currentNode.depth + 1,
      });
    }

    // If the current node has no children, add a + button to the tree,
    // which creates a new node when clicked. This function will run again
    // on the modified tree, and the new node will have a + button under it.
    if (!currentNode.node.children!.length) {
      currentNode!.node.children!.push({
        name: '',
        attributes: { isPlaceholder: true, parentId: currentNode.node.attributes!.id },
        children: [],
      });
    }
  }

  const unreachableNodes = _.filter(rc.nodes, (node) => !reachableNodeIds.has(node.id));

  return { tree: root, unreachableNodes, nodeIdsNotFound };
}
