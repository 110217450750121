import statusCodes from 'http-status-codes';
import { errorAlertMessages } from '../Components/constants';
import IRootProduct from '../Components/Interfaces/IRootProduct';

const BES_SERVICE_URL = 'https://bes.products.cimpress.io';

/**
 * Client to interact with the Binding and Evolution Service
 */
export default class BESService {
  /**
   * @property authToken - The OAuth JWT to authenticate requests with.
   */
  private readonly authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  /**
   * Retrieve the Root Product for the provided Merchant Product ID and the version.
   * @param {string} productId - an identifier for a Merchant Product.
   * @param {string} [version] - the version of the Merchant Product.
   * @returns {Promise<IRootProduct>} The requested root product id and version.
   */
  public async getRootProduct(productId: string, version: string): Promise<IRootProduct> {
    const response = await fetch(`${BES_SERVICE_URL}/api/v1/products/${productId}/${version}/rootproduct`, {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${this.authToken}`,
      },
      method: 'get',
    });

    switch (response.status) {
      case statusCodes.OK:
        return response.json();
      case statusCodes.NOT_FOUND:
        throw new Error(`Could not find Root Product for given Product ID ${productId} with version ${version}`);
      case statusCodes.UNAUTHORIZED:
        throw new Error(errorAlertMessages.INVALID_AUTH);
      default:
        throw new Error(`Error while fetching the Root Product for given Product ID ${productId} with version ${version}`);
    }
  }
}
