import { debounce, isEqual } from 'lodash';
import React, { Component, CSSProperties } from 'react';

import { colors, Tag, TextField } from '@cimpress/react-components';
import { MetadataKeys } from '../../Models';
import { IPivotInputProps } from './';
import AttributeInputContainer from './AttributeInputContainer';
import NumberTooltip from './NumberTooltip';
import ResourceAttributeIconWithLabel from './ResourceAttributeIconWithLabel';

interface IState {
  values: string[];
  currentInput: string;
  tooltipVisible: boolean;
}

/**
 * Keycodes pulled from the standard: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
 */
const KEYS = {
  BACKSPACE: 'Backspace',
  ENTER: 'Enter',
};

const DEBOUNCE_WAIT_IN_MS = 500;
const ADDITIONAL_TOOLTIP_CONTENT = 'Hit enter to add and backspace to delete a value.';

const styles = {
  container: {
    border: `1px solid ${colors.alloy}`,
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    marginBottom: '15px',
  },
  tag: {
    alignSelf: 'center',
  },
  textField: {
    flexGrow: 1,
    margin: '0',
  },
  textFieldInput: {
    border: 'none',
    height: '48px',
  },
};

/**
 * Default class for handling selection of multiple Ranges.
 */
export default class NumberMultiInput extends Component<IPivotInputProps, IState> {
  private readonly emitChange = debounce(
    () => this.props.onInputChange(this.props.attributeKey, this.state.values),
    DEBOUNCE_WAIT_IN_MS,
  );

  constructor(props: IPivotInputProps) {
    super(props);

    const { assignedValues } = props;

    this.state = {
      currentInput: '',
      tooltipVisible: false,
      values: assignedValues,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
  }

  public componentDidUpdate(prevProps: IPivotInputProps) {
    if (!isEqual(this.props.assignedValues, prevProps.assignedValues)) {
      this.setState({ values: this.props.assignedValues });
    }
  }

  public render() {
    const {
      attributeKey,
      label,
      values,
      type,
      metadata,
      resourceAttributeIcon,
      isRequired,
      allowDisabledSelection,
    } = this.props;
    const { tooltipVisible } = this.state;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    const unitOfMeasure: string =
      metadata && metadata.get(MetadataKeys.unitOfMeasure) ? metadata.get(MetadataKeys.unitOfMeasure) : '';

    const tooltipUOM = unitOfMeasure ? `<br/><br/>${attributeKey} (${unitOfMeasure})` : '';

    return (
      <AttributeInputContainer isDisabledSelectionMode={allowDisabledSelection}>
        <NumberTooltip
          type={type}
          values={values}
          showTooltip={tooltipVisible}
          additionalTooltipContent={`${ADDITIONAL_TOOLTIP_CONTENT}${tooltipUOM}`}
        >
          <div style={styles.container as CSSProperties}>
            {this.state.values.map((value, i) => (
              <Tag
                key={`${value}-${i}`}
                value={value}
                onRemoveClick={this.handleDelete}
                removable={true}
                style={styles.tag}
              />
            ))}
            <TextField
              className="textInputClass"
              value={this.state.currentInput}
              label={displayLabel}
              name={attributeKey}
              onChange={this.handleInputChange}
              onFocus={this.showTooltip}
              onBlur={this.hideTooltip}
              onKeyDown={this.handleInputKeyDown}
              inputStyle={styles.textFieldInput}
              style={styles.textField}
              required={isRequired}
            />
          </div>
        </NumberTooltip>
      </AttributeInputContainer>
    );
  }

  private handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ currentInput: event.target.value });
  }

  private handleInputKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const { values, currentInput } = this.state;
    const { value } = event.currentTarget;

    if (event.key === KEYS.ENTER && !values.includes(value) && this.isValidNumber(value)) {
      const sanitizedValue = value.replace(/[^\d.]/g, '');

      this.setState({ values: [...values, sanitizedValue], currentInput: '' }, () => this.emitChange());
    }

    if (values.length > 0 && event.key === KEYS.BACKSPACE && currentInput.length === 0) {
      this.setState({ values: values.slice(0, values.length - 1) }, () => this.emitChange());
    }
  }

  private handleDelete(value: string) {
    this.setState({ values: this.state.values.filter((item: any) => item !== value) }, () => this.emitChange());
  }

  private showTooltip() {
    this.setState({ tooltipVisible: true });
  }

  private hideTooltip() {
    this.setState({ tooltipVisible: false });
  }

  private isValidNumber(text: string): boolean {
    const numberRegex = new RegExp(/^\d+(\.\d+)?$/);

    return numberRegex.test(text);
  }
}