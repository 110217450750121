import { ValueConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { Select } from '@cimpress/react-components';
import { debounce, get, isEqual } from 'lodash';
import React, { Component } from 'react';
// @ts-ignore
import { components } from 'react-select';
import AttributeRangeHelper from '../../AttributeRangeHelper';
import { MetadataKeys } from '../../Models';
import { AMEXMetadata, MAX_OPTIONS_IN_DROPDOWN } from '../constants';
import { IAttributeStateTooltipConfiguration } from '../Interfaces';
import AttributeInputContainer from './AttributeInputContainer';
import ColorSwatch from './ColorSwatch';
import { IInputProps } from './IInputProps';
import './NumberInput.css';
import NumberTooltip from './NumberTooltip';
import ResourceAttributeIconWithLabel from './ResourceAttributeIconWithLabel';
import ValueStateTooltip from './ValueStateTooltip';

interface ISelectOption {
  className?: string;
  getValueState: (value: string) => any;
  isDisabled?: boolean;
  label: string;
  attributeStateTooltipConfiguration: IAttributeStateTooltipConfiguration;
  value: string;
}

interface IState {
  options: ISelectOption[];
  value: string;
  tooltipVisible: boolean;
}

const DISABLED_CLASS_NAME = 'disabled';

const DEBOUNCE_WAIT_IN_MS = 200;

const CLASS_NAME_PREFIX = 'number-select-input';

const LENGTH_HEXCODE = 6;

const decimalHexcodeMap = new Map();

function convertHexadecimalToHexcode(hexadecimal: string) {
  const numberOfZeroesToBeAdded = LENGTH_HEXCODE - hexadecimal.length;
  const zeroesToBeAdded = '0'.repeat(numberOfZeroesToBeAdded);
  return `#${zeroesToBeAdded}${hexadecimal}`;
}

function convertDecimalToHexcode(decimal: string) {
  const decimalNumber = parseInt(decimal, 10);
  if (isNaN(decimalNumber)) {
    return decimal;
  }
  const hexadecimalNumber = decimalNumber.toString(16);
  const hexcode = convertHexadecimalToHexcode(hexadecimalNumber);
  return hexcode;
}

const customOption = (props: any) => {
  const { children, className, data, selectProps } = props;
  const { unitOfMeasure, isColorSwatch } = selectProps;
  const optionValue = unitOfMeasure === 'hexcode' ? convertDecimalToHexcode(children) : children;
  const optionContainer =
    unitOfMeasure === 'hexcode' ? (
      isColorSwatch ? (
        <span
          className={`number-option-container ${get(data, 'styleClasses.dropdownOptionValue', '')} colorSwatchSpan`}
        >
          <ColorSwatch hexCode={optionValue} />
          {optionValue}
        </span>
      ) : (
        <span className={`number-option-container ${get(data, 'styleClasses.dropdownOptionValue', '')}`}>
          {optionValue}
        </span>
      )
    ) : (
      <span className={`number-option-container ${get(data, 'styleClasses.dropdownOptionValue', '')}`}>
        {optionValue}
      </span>
    );

  return (
    <React.Fragment>
      {data.className === DISABLED_CLASS_NAME ? (
        <components.Option {...props}>
          <ValueStateTooltip
            className={`${className} ${data.className}`}
            getValueState={data.getValueState}
            value={data.label}
            showOnExcluded={data.attributeStateTooltipConfiguration.showOnExcluded}
            showOnResolved={data.attributeStateTooltipConfiguration.showOnResolved}
          >
            {optionContainer}
          </ValueStateTooltip>
        </components.Option>
      ) : (
        <components.Option {...props}>{optionContainer}</components.Option>
      )}
    </React.Fragment>
  );
};

/**
 * Select Input Component for Range Attributes with limited options
 */
export default class NumberSelectInput extends Component<IInputProps, IState> {
  public static defaultProps = {
    attributeStateTooltipConfiguration: {},
    resolvedValue: '',
  };

  private hexcodeConvertedOptions: ISelectOption[] = [];
  private selectedColor = '';

  private readonly emitChange = debounce(
    value => this.props.onInputChange(this.props.attributeKey, value),
    DEBOUNCE_WAIT_IN_MS,
  );

  private readonly emitChangeDisabled = debounce(
    value => this.props.onInputChange(this.props.attributeKey, value, true),
    DEBOUNCE_WAIT_IN_MS,
  );

  constructor(props: IInputProps) {
    super(props);

    const { resolvedValue } = this.props;

    this.state = {
      options: [],
      tooltipVisible: false,
      value: resolvedValue,
    };

    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.loadOptionsIntoDropDown = this.loadOptionsIntoDropDown.bind(this);
    this.getElementToBeRendered = this.getElementToBeRendered.bind(this);
    this.onSelectChangeForHexcode = this.onSelectChangeForHexcode.bind(this);
  }

  public componentDidMount() {
    const { attributeKey, values } = this.props;
    this.loadOptionsIntoDropDown(values, MAX_OPTIONS_IN_DROPDOWN, attributeKey);
  }

  public componentDidUpdate(prevProps: IInputProps, prevState: IState) {
    const { allowDisabledSelection } = this.props;

    // Check added to respect change in allowDisabledSelection prop
    if (
      this.props.validValues !== prevProps.validValues ||
      this.props.allowDisabledSelection !== prevProps.allowDisabledSelection ||
      this.props.styleClasses !== prevProps.styleClasses
    ) {
      this.loadOptionsIntoDropDown(this.props.values, MAX_OPTIONS_IN_DROPDOWN, this.props.attributeKey);
    }

    // Second condition is added for reverting the selection whenever disabled selection is performed and it leads to an unrecoverable error
    if (
      this.props.resolvedValue !== prevProps.resolvedValue ||
      (allowDisabledSelection && this.isDisabledSelectionReverted(prevState))
    ) {
      this.setState({ value: this.props.resolvedValue });
    }
  }

  public render() {
    const { metadata, validValues } = this.props;
    const { value, tooltipVisible } = this.state;

    const unitOfMeasure: string =
      metadata && metadata.get(MetadataKeys.unitOfMeasure) ? metadata.get(MetadataKeys.unitOfMeasure) : '';
    let optionLabel = value;

    if (unitOfMeasure === 'hexcode') {
      const validHexcode: ValueConfiguration[] = [];
      for (let validValue = 0; validValue < validValues.length; validValue = validValue + 1) {
        validHexcode.push({ type: 'numberLiteral' });
        validHexcode[validValue].numberLiteral = convertDecimalToHexcode(validValues[validValue].numberLiteral);
      }
      optionLabel = convertDecimalToHexcode(value);
      return this.getElementToBeRendered(optionLabel, validHexcode, this.onSelectChangeForHexcode, false);
    }
    return this.getElementToBeRendered(optionLabel, validValues, this.onSelectChange, tooltipVisible);
  }

  private getElementToBeRendered(
    optionLabel: string,
    validValues: ValueConfiguration[],
    onChange: () => void,
    tooltipVisible: boolean,
  ) {
    const {
      allowDisabledSelection,
      attributeKey,
      label,
      isRequired,
      isValid,
      metadata,
      type,
      resourceAttributeIcon,
      styleClasses,
      isColorSwatch,
    } = this.props;
    const { value, options } = this.state;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );
    const unitOfMeasure: string =
      metadata && metadata.get(MetadataKeys.unitOfMeasure) ? metadata.get(MetadataKeys.unitOfMeasure) : '';
    const tooltipUOM = unitOfMeasure ? `<br/>${attributeKey} (${unitOfMeasure})` : '';

    const onChangeBound = onChange.bind(this);
    return (
      <div className="attributeInputContainerWrapper">
        <AttributeInputContainer
          isDisabledSelectionMode={allowDisabledSelection}
          isValid={allowDisabledSelection && isValid && value}
        >
          <NumberTooltip
            showTooltip={tooltipVisible}
            values={validValues}
            type={type}
            additionalTooltipContent={tooltipUOM}
          >
            {isColorSwatch === true &&
            unitOfMeasure === 'hexcode' &&
            this.selectedColor !== '' &&
            this.state.value !== '' ? (
              <div className="colorSwatch">
                <ColorSwatch hexCode={this.selectedColor} />
              </div>
            ) : (
              ''
            )}
            <Select
              classNamePrefix={CLASS_NAME_PREFIX}
              name={attributeKey}
              label={displayLabel}
              required={isRequired}
              options={options}
              valueComponent={this.customValue}
              onFocus={this.showTooltip}
              onBlur={this.hideTooltip}
              components={{ Option: customOption, SelectContainer: this.customValue }}
              // @ts-ignore
              value={
                ['', undefined].includes(value)
                  ? ''
                  : {
                      value,
                      label: optionLabel,
                    }
              }
              status={isValid ? undefined : 'error'}
              onChange={onChangeBound}
              isClearable={true}
              containerClassName={get(styleClasses, 'dropdownContainer', '')}
              unitOfMeasure={unitOfMeasure}
              isColorSwatch={isColorSwatch}
            />
          </NumberTooltip>
        </AttributeInputContainer>
      </div>
    );
  }
  private onSelectChange(option?: any) {
    const isDisabled = option && option.className === DISABLED_CLASS_NAME;
    const { allowDisabledSelection } = this.props;
    const value = get(option, 'value', '');

    if (!isDisabled) {
      this.setState({ value }, () => {
        this.emitChange(this.state.value);
      });
    } else if (allowDisabledSelection && isDisabled) {
      this.setState({ value }, () => {
        this.emitChangeDisabled(this.state.value);
      });
    }
  }
  private onSelectChangeForHexcode(option?: any) {
    const isDisabled = option && option.className === DISABLED_CLASS_NAME;
    const { allowDisabledSelection } = this.props;
    const value = get(option, 'value', '');

    this.selectedColor = value;
    if (!isDisabled) {
      this.setState({ value }, () => {
        this.emitChange(decimalHexcodeMap.get(this.state.value));
      });
    } else if (allowDisabledSelection && isDisabled) {
      this.setState({ value }, () => {
        this.emitChangeDisabled(decimalHexcodeMap.get(this.state.value));
      });
    }
  }
  private convertDecimalOptionsToHexcodeOptions(options: ISelectOption[]): ISelectOption[] {
    for (const option of options) {
      const hexcode = convertDecimalToHexcode(option.value);
      decimalHexcodeMap.set(hexcode, option.value);
      option.value = hexcode;
    }
    return options;
  }
  private loadOptionsIntoDropDown(ranges: any[], count: number, attributeKey: string) {
    const {
      allowDisabledSelection,
      attributeStateTooltipConfiguration,
      getValueState,
      styleClasses,
      metadata,
    } = this.props;

    const unitOfMeasure: string =
      metadata && metadata.get(MetadataKeys.unitOfMeasure) ? metadata.get(MetadataKeys.unitOfMeasure) : '';

    // tslint:disable: no-shadowed-variable

    const { validValues } = this.props;
    const allOptions = AttributeRangeHelper.getValidValuesFromRanges(ranges, count, attributeKey);
    const validOptions = AttributeRangeHelper.getValidValuesFromRanges(validValues, count, attributeKey);

    let options: ISelectOption[] = allOptions.map(option => {
      const isDisabled = !validOptions.find((validOption: string) => option === validOption);
      return {
        attributeStateTooltipConfiguration,
        getValueState,
        className: isDisabled ? DISABLED_CLASS_NAME : undefined,
        isDisabled: isDisabled ? true && !allowDisabledSelection : false,
        label: option,
        styleClasses: { ...styleClasses },
        value: option,
      };
    });

    if (unitOfMeasure === 'hexcode') {
      if (this.hexcodeConvertedOptions.length === 0) {
        this.hexcodeConvertedOptions = this.convertDecimalOptionsToHexcodeOptions(options);
        options = this.hexcodeConvertedOptions;
      } else {
        options = this.hexcodeConvertedOptions;
      }
    }
    options.sort(this.sortSelectOptions);
    this.setState({ options });
  }

  private showTooltip() {
    this.setState({ tooltipVisible: true });
  }

  private hideTooltip() {
    this.setState({ tooltipVisible: false });
  }

  /**
   * Sort the given options such that the disabled options are at the bottom.
   * @param optionA SelectionOption A
   * @param optionB SelectionOption B
   */
  private sortSelectOptions(optionA: ISelectOption, optionB: ISelectOption) {
    if (optionA.className === DISABLED_CLASS_NAME && optionB.className !== DISABLED_CLASS_NAME) {
      return 1;
    }

    if (optionA.className !== DISABLED_CLASS_NAME && optionB.className === DISABLED_CLASS_NAME) {
      return -1;
    }

    return 0;
  }

  /**
   * Custom Value component used to ensure that the ValueStateTooltip
   * only triggers when hovering over the input value box.
   */
  private customValue: React.FunctionComponent = ({ children, value }: any) => {
    const { allowDisabledSelection, metadata } = this.props;
    let selectValueClass = this.props.isValid ? '' : 'has-error';

    if (allowDisabledSelection) {
      if (metadata && metadata.get(AMEXMetadata.isSelectionUpdatedByInvalidSelection)) {
        selectValueClass = this.props.isValid ? 'has-warning' : 'has-error';
      }
    }
    return (
      <ValueStateTooltip
        getValueState={this.props.getValueState}
        value={this.props.resolvedValue}
        direction="top"
        showOnExcluded={this.props.attributeStateTooltipConfiguration.showOnExcluded}
        showOnResolved={this.props.attributeStateTooltipConfiguration.showOnResolved}
      >
        <div className={`Select-value`} title={value ? value.title : undefined}>
          <span
            className={`Select-value-label ${selectValueClass} ${get(
              this.props.styleClasses,
              'dropdownSelectValue',
              '',
            )}`}
          >
            {children}
          </span>
        </div>
      </ValueStateTooltip>
    );
  }; // tslint:disable-line semicolon

  private isDisabledSelectionReverted(prevState: IState) {
    const { resolvedValue } = this.props;
    const { value: previousStateValue } = prevState;

    // selection must be reverted only when there is a difference between the provided resolved value and value stored in state
    return isEqual(prevState, this.state) && resolvedValue !== previousStateValue;
  }
}
