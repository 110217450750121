import { DeserializedAttributeModelData, IProduct, ModelUtilities } from '@cimpress-technology/attribute-model-explorer';
import ISerializedAttributeMetadata from '../../Models/ISerializedAttributeMetadata';
import { BESService, MerchantProductOrchestrationService, ProductServiceClient, RuleServiceClient } from '../../Services';
import AttributeMetadataServiceClient from '../../Services/AttributeMetadataServiceClient';
import ModelComposerServiceClient from '../../Services/ModelComposerServiceClient';
import { MerchantToV2Converter } from '../Converters/MerchantToV2Converter';
import { IMerchantProduct, IRootProduct, IRuleSet } from '../Interfaces';
import { IProductRepository } from '../Interfaces/IProductRepository';

export default class ProductRepository implements IProductRepository<{
  composedProduct: DeserializedAttributeModelData|undefined;
  product: IProduct|undefined;
  ruleSet: IRuleSet|undefined;
  merchant: IMerchantProduct | undefined;
}> {
  /**
   * @property authToken - The OAuth JWT to authenticate requests with.
   */
  private readonly authToken: string;

  constructor(authToken: string) {
    this.authToken = authToken;
  }

  public async getProduct(productId: string, productVersion?: string, enableSerialization: boolean = false): Promise<{
    composedProduct: DeserializedAttributeModelData|undefined;
    product: IProduct|undefined;
    ruleSet: IRuleSet|undefined;
    merchant: IMerchantProduct | undefined;
  }> {
    let product: IProduct | undefined;
    let ruleSet: IRuleSet | undefined;
    let merchant: IMerchantProduct | undefined;


    const productServiceClient = new ProductServiceClient(this.authToken);
    const merchantProductOrchestrationService = new MerchantProductOrchestrationService(this.authToken);


    // TODO: Add a switch for composable stack
    if (enableSerialization && productId && productVersion) {
      const composedResponse = await this.getSerializedStack(productId, productVersion);

      if (composedResponse) {
        return composedResponse;
      }
    }

    if (productVersion) {
      const productPromise = productServiceClient.getV2Product(productId, productVersion);
      const merchantPromise = merchantProductOrchestrationService.getMerchantProduct(productId, productVersion);
      try {
        merchant = await merchantPromise;
      } catch (err) {
        product = await productPromise;
      }
    } else {
      const ruleServiceClient = new RuleServiceClient(this.authToken);
      const productPromise = productServiceClient.getV2Product(productId);
      const ruleSetPromise = ruleServiceClient.getRuleSet(productId);
      const merchantPromise = merchantProductOrchestrationService.getMerchantProduct(productId);

      try {
        merchant = await merchantPromise;
      } catch (err) {
        try {
          product = await productPromise;
        } catch (err) {
          // falling back to v1 ruleset service if product and merchant product is not available
          ruleSet = await ruleSetPromise;
        }
      }
    }

    if (merchant) {
      const merchantProductId: string = merchant.productId;
      const merchantProductVersion: string = merchant.version.toString();

      const baseV2Product = await this.getRootFulfillerProduct(merchantProductId, merchantProductVersion);
      const merchantConverter = new MerchantToV2Converter(merchant, baseV2Product);

      product = await merchantConverter.convert();
    }

    return {
      product,
      ruleSet,
      composedProduct: undefined,
      merchant: undefined,
    };
  }

  public async getRootFulfillerProduct(productId: string, productVersion: string): Promise<IProduct> {
    const besService = new BESService(this.authToken);
    const productServices = new ProductServiceClient(this.authToken);

    const rootProduct: IRootProduct = await besService.getRootProduct(productId, productVersion);
    const rootProductId: string = rootProduct && rootProduct.productId;
    const rootVersion: string | undefined = rootProduct && rootProduct.version ? rootProduct.version.toString() : undefined;

    const baseV2Product: IProduct = await productServices.getV2Product(rootProductId, rootVersion);
    return baseV2Product;
  }

  private async getSerializedStack(productId: string, productVersion: string) {
    let serializedModel: ArrayBufferLike | undefined;
    let attributeMetadataResponse: ISerializedAttributeMetadata | undefined;

    const modelComposerService = new ModelComposerServiceClient(this.authToken);
    const attributeMetadataService = new AttributeMetadataServiceClient(this.authToken);

    const composedProductPromise = modelComposerService.getSerializedProduct(productId, productVersion);
    const attributeMetadataPromise = attributeMetadataService.getAttributeMetadata(productId, productVersion);

    const serializedModelResponse = await composedProductPromise;
    const serializedModelBlob = await serializedModelResponse.blob();

    serializedModel = await serializedModelBlob.arrayBuffer();
    attributeMetadataResponse = await attributeMetadataPromise;

    if (!(serializedModel && attributeMetadataResponse)) {
      throw new Error('Failed to fetch complete composable stack');
    }

    // bypass extra attributes coming from metadata service
    const composedProduct: DeserializedAttributeModelData = ModelUtilities.buildSerializedAttributeConfigurations(serializedModel, attributeMetadataResponse.attributeMetadata, true);

    return {
      composedProduct,
      merchant: undefined,
      product: undefined,
      ruleSet: undefined,
    };
  }
}