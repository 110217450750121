import { AttributeConfigurationMap, DeserializedAttributeModelData, IAttributeInformations } from '@cimpress-technology/attribute-model-explorer';
import { cloneDeep, mapKeys } from 'lodash';
import { IConfigurationMapBuilder } from '../../';
import { IAttributeConfigurationMap, MetadataKeys } from '../../../../Models';
import Utils from '../../../../Utils';
import { ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY, PIVOT_DEFAULT_DISPLAY_PRIORITY, } from '../../../constants';

/**
 * This class builds configurationMap only for the product attributes.
 * Situtaion when user wants selection experience on product attributes.
 */
export default class SerializedProductConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!SerializedProductConfigurationMapBuilder.instance) {
      SerializedProductConfigurationMapBuilder.instance = new SerializedProductConfigurationMapBuilder();
    }

    SerializedProductConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return SerializedProductConfigurationMapBuilder.instance;
  }

  private static instance: SerializedProductConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  public buildConfigurationMap(
    deserializedAttributeModelData: DeserializedAttributeModelData,
    configurationMap: IAttributeConfigurationMap,
  ): AttributeConfigurationMap {
    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());

    const attributeInformations: IAttributeInformations = deserializedAttributeModelData.getAttributeInformations();
    Object.keys(attributeInformations).forEach((attributeKey: string) => {
      const configuration: any = cloneDeep(normalizedConfigurationMap[attributeKey] || {});

      if (configuration.displayPriority === undefined) {
        configuration.displayPriority = configuration.isPivot
          ? PIVOT_DEFAULT_DISPLAY_PRIORITY
          : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
      }

      const isOption = attributeInformations[attributeKey].buckets.indexOf('option') > -1;

      if (configuration.isHidden === undefined) {
        const isInferable = Utils.isSerializedAttributeInferable(attributeInformations[attributeKey].values);
        configuration.isHidden = !this.displaySingleValuedAttributes && isInferable ? true : !isOption;
      }

      configuration.isRequired = isOption;

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
        [MetadataKeys.unitOfMeasure]: attributeInformations[attributeKey].unitOfMeasure,
      };

      normalizedConfigurationMap[attributeKey] = configuration;
    });

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
