import React from 'react';

import { WorkingRoutingConfigurationNode } from '../../types';
import styles from './unassignedNode.module.scss';

export default function UnassignedNode({
  node,
  onClick,
}: {
  node: WorkingRoutingConfigurationNode;
  onClick: (node: WorkingRoutingConfigurationNode) => void;
}) {
  return (
    <div className={styles.node} onClick={() => onClick(node)}>
      {node.name}
    </div>
  );
}
