import { IProduct, Range, ResolutionState } from '@cimpress-technology/attribute-model-explorer';
import { ResourceType } from '@cimpress-technology/selector-resource-formatter';

import findAttributeDependencies from '@cimpress-technology/attribute-dependencies';
import AttributeRangeHelper from './AttributeRangeHelper';
import { MerchantToV2Converter } from './Components/Converters/MerchantToV2Converter';
import { customRequired } from './Components/CustomRequired';
import GenericSelector from './Components/GenericSelector';
import { IMerchantProduct } from './Components/Interfaces';
import { vpSelector } from './Components/VPSelector';

// tslint:disable-next-line: variable-name
const ProductState = ResolutionState;

export { findAttributeDependencies, GenericSelector, ResolutionState, ProductState, ResourceType };

// tslint:disable-next-line: variable-name
export const CustomRequiredSelector = customRequired(GenericSelector);

// tslint:disable-next-line: variable-name
export const VPSelector = vpSelector(GenericSelector);

export const generateValuesFromRanges = (ranges: Range[], count: number): string[] => {
  return AttributeRangeHelper.getIntelligentRangeValues(ranges, count);
};

export const convertMerchantProductToV2Model = (merchantProduct: IMerchantProduct, rootProduct: IProduct): IProduct => {
  const merchantToV2Converter = new MerchantToV2Converter(merchantProduct, rootProduct);
  return merchantToV2Converter.convert();
};
export default GenericSelector;
export * from './Components/Interfaces/index';
export * from './Models/index';