import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { ItemWithOrderMetadata } from '../../services/items';
import { searchExistingResources } from '../resourceSearch/resourceSearchSlice';

interface SelectedItemState {
  item?: ItemWithOrderMetadata;
}

const selectedItemSlice = createSlice({
  name: 'selectedItem',
  initialState: {} as SelectedItemState,
  reducers: {
    setSelectedItem(state, action: PayloadAction<ItemWithOrderMetadata | undefined>) {
      state.item = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(searchExistingResources.pending, searchExistingResources.fulfilled),
      (state) => {
        state.item = undefined;
      },
    );
  },
});

export const { setSelectedItem } = selectedItemSlice.actions;
export default selectedItemSlice.reducer;
