import { AttributeConfigurationMap, IAttributeModelConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { mapKeys } from 'lodash';

import { IConfigurationMapBuilder } from '../..';
import { isAttributeModelVariableInferable } from '../../../../AttributeModelUtils';
import { IAttributeConfigurationMap, MetadataKeys } from '../../../../Models';
import { ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY, PIVOT_DEFAULT_DISPLAY_PRIORITY } from '../../../constants';

export default class AMConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!AMConfigurationMapBuilder.instance) {
      AMConfigurationMapBuilder.instance = new AMConfigurationMapBuilder();
    }

    AMConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return AMConfigurationMapBuilder.instance;
  }

  private static instance: AMConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  public buildConfigurationMap(
    attributeModel: IAttributeModelConfiguration,
    configurationMap: IAttributeConfigurationMap,
  ): AttributeConfigurationMap {
    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());

    attributeModel.attributes.forEach((attribute: any) => {
      const attributeKey = attribute.key.toLowerCase();
      const configuration: any = normalizedConfigurationMap[attributeKey] || {};

      if (configuration.displayPriority === undefined) {
        configuration.displayPriority = configuration.isPivot
          ? PIVOT_DEFAULT_DISPLAY_PRIORITY
          : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
      }

      // An attribute is marked as hidden if it is allowed to be nullable or it is inferable.
      // Else we let the attributeConfiguration provided by the user override.
      if (configuration.isHidden === undefined) {
        configuration.isHidden =
          attribute.isNullable || (!this.displaySingleValuedAttributes && isAttributeModelVariableInferable(attribute));
      }

      configuration.isRequired = !configuration.isHidden;

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
      };

      normalizedConfigurationMap[attributeKey] = configuration;
    });

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
