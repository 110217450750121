/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Button, Modal } from '@cimpress/react-components';

export default function ConfirmCloseModal({
  showModal,
  onRequestHide,
  onConfirmCancel,
}: {
  showModal: boolean;
  onRequestHide: () => void;
  onConfirmCancel: () => void;
}) {
  const footer = (
    <div css={horizontalFlexStyles}>
      <Button variant="secondary" css={buttonStyles} onClick={onConfirmCancel}>
        Discard Changes
      </Button>
      <Button variant="primary" onClick={onRequestHide}>
        Cancel
      </Button>
    </div>
  );
  return (
    <Modal
      show={showModal}
      closeButton={true}
      onRequestHide={onRequestHide}
      title="Discard Changes"
      footer={footer}
    >
      <p>Are you sure you want to discard changes?</p>
    </Modal>
  );
}

const horizontalFlexStyles = css`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const buttonStyles = css`
  margin-right: 16px;
`;
