import _ from 'lodash';

import { getAccessToken } from '../auth';

export type FulfillmentExpectationData = {
  _embedded: {
    requirements: RequirementData[];
  };
};

export type RequirementData = {
  _links: {
    self?: {
      href: string;
    };
  };
  name: string;
};

const constructHeaders = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
  'Content-Type': 'application/json',
});

export const getFulfillmentExpectations = async () => {
  const params = {
    method: 'get',
    headers: constructHeaders(),
  };
  const url = `${process.env.REACT_APP_FULFILLMENT_EXPECTATIONS_SERVICE_URL}/v2/expectations`;
  const response = await fetch(url, params);
  if (response.ok) {
    const data: FulfillmentExpectationData = await response.json();
    const filteredData = data._embedded.requirements.filter((r) => r._links.self);
    return _.keyBy(filteredData, 'name') as Record<string, RequirementData>;
    // .map((r) => ({
    //   label: r.name,
    //   value: r._links.self!.href,
    // }));
  }
  throw new Error(
    `Error retrieving fulfillment expectations, received status code ${response.status}`,
  );
};
