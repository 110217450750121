/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { colors } from '@cimpress/react-components';

const NothingConfigured = styled.p`
  color: ${colors.cobalt.base};
  margin-bottom: 8px;
`;

export default NothingConfigured;
