import { StringValueConfiguration } from '@cimpress-technology/attribute-model-explorer';
import { colors, Select } from '@cimpress/react-components';
import { isEqual } from 'lodash';
import React, { Component } from 'react';

import { MetadataKeys } from '../../Models';
import { IPivotInputProps } from './';
import AttributeInputContainer from './AttributeInputContainer';
import ResourceAttributeIconWithLabel from './ResourceAttributeIconWithLabel';

/**
 * Default input component for handling selection of multiple Values.
 */
export default class StringMultiInput extends Component<IPivotInputProps, any> {
  constructor(props: IPivotInputProps) {
    super(props);

    const { assignedValues } = props;

    this.state = { values: assignedValues };

    this.onChange = this.onChange.bind(this);
  }

  public componentDidUpdate(prevProps: IPivotInputProps) {
    if (!isEqual(this.props.assignedValues, prevProps.assignedValues)) {
      this.setState({ values: this.props.assignedValues });
    }
  }

  public render() {
    const {
      attributeKey,
      label,
      valueLabelMap,
      isRequired,
      isValid,
      values,
      metadata,
      resourceAttributeIcon,
      allowDisabledSelection,
    } = this.props;

    const displayLabel: any =
      metadata && metadata.get(MetadataKeys.isResourceDerivedAttribute) ? (
        <ResourceAttributeIconWithLabel attributeKey={label} requiredAttributeIcon={resourceAttributeIcon} />
      ) : (
        label
      );

    const options = (values || []).map((value: StringValueConfiguration) => ({
      label: valueLabelMap[value.stringLiteral],
      value: value.stringLiteral,
    }));

    const selectedValues = (this.state.values || []).map((value: string) => {
      return {
        value,
        label: valueLabelMap[value],
      };
    });

    return (
      <AttributeInputContainer isDisabledSelectionMode={allowDisabledSelection}>
        <Select
          name={attributeKey}
          label={displayLabel}
          required={isRequired}
          options={options}
          value={selectedValues}
          style={isValid ? {} : { borderColor: colors.danger.base }}
          onChange={this.onChange}
          tether={true}
          isMulti={true}
        />
      </AttributeInputContainer>
    );
  }

  private onChange(options: any[]) {
    const values = (options || []).map(option => option.value);

    this.setState({ values });
    this.props.onInputChange(this.props.attributeKey, values);
  }
}
