import React, { Component } from 'react';
import ValidIcon from '../../Icons/ValidIcon';
import './AttributeInputContainer.css';

interface IProps {
  children: string | JSX.Element | JSX.Element[];
  isDisabledSelectionMode: boolean;
  isValid: any;
}

class AttributeInputContainer extends Component<IProps, any> {
  public static defaultProps = {
    isDisabledSelectionMode: false,
    isValid: false,
  };

  public render() {
    const { children, isDisabledSelectionMode, isValid } = this.props;

    const validIcon = isValid && <span> <ValidIcon /></span>;
    const selectionModeClass = isDisabledSelectionMode ? 'show' : 'hide';

    return (
      <div className="attribute-input-container">
        <div className="container-item">
          {children}
        </div>
        <div className={`right-add-on ${selectionModeClass}`}>
          {validIcon}
        </div>
      </div >
    );
  }
}

export default AttributeInputContainer;