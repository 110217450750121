import { Operator } from '../types';

export const OPERATOR_SELECTION_VALUES = {
  // json-rules-engine built-in operators
  [Operator.equal]: {
    label: 'Equal to',
    value: Operator.equal,
  },
  [Operator.notEqual]: { label: 'Not equal to', value: Operator.notEqual },
  [Operator.greaterThan]: { label: 'Greater than', value: Operator.greaterThan },
  [Operator.greaterThanInclusive]: {
    label: 'Greater or equal to',
    value: Operator.greaterThanInclusive,
  },
  [Operator.lessThan]: { label: 'Less than', value: Operator.lessThan },
  [Operator.lessThanInclusive]: { label: 'Less or equal to', value: Operator.lessThanInclusive },
  [Operator.in]: { label: 'In', value: Operator.in },
  [Operator.notIn]: { label: 'Not In', value: Operator.notIn },
  // Custom operators
  [Operator.allAre]: { label: 'All options', value: Operator.allAre },
  [Operator.noneAre]: { label: 'No options', value: Operator.noneAre },
  [Operator.anyAre]: { label: 'Any options', value: Operator.anyAre },
  [Operator.orderedWithAnyOf]: { label: 'Ordered with', value: Operator.orderedWithAnyOf },
  [Operator.routedTo]: { label: 'Routed To (Also Routes)', value: Operator.routedTo },
  [Operator.dateLess]: { label: 'Earlier than', value: Operator.dateLess },
  [Operator.dateLessEqual]: { label: 'Earlier or equal', value: Operator.dateLessEqual },
  [Operator.dateGreater]: { label: 'Later than', value: Operator.dateGreater },
  [Operator.dateGreaterEqual]: { label: 'Later or equal', value: Operator.dateGreaterEqual },

  [Operator.poBox]: { label: 'PO Box', value: Operator.poBox },
  [Operator.containsNone]: { label: 'Contains none of', value: Operator.containsNone },
};
