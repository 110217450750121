import React from 'react';
import { v4 as uuid } from 'uuid';

import { Alert } from '@cimpress/react-components';

import { clearAlert } from '../features/alerts/alertContentSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import styles from './appAlert.module.scss';

export function AppAlert() {
  const dispatch = useAppDispatch();
  const { messages, title, status } = useAppSelector((state) => state.alertContent);
  const onDismiss = () => dispatch(clearAlert());

  const body = (
    <div>
      {(messages || []).map((message) => (
        <p key={uuid()}>{message}</p>
      ))}
    </div>
  );
  return (
    <>
      {title && (
        <Alert
          className={styles.alert}
          title={title}
          message={body}
          status={status ?? 'danger'}
          onDismiss={onDismiss}
          dismissible
        />
      )}
    </>
  );
}
