/** @jsxImportSource @emotion/react */
import { SerializedStyles, css } from '@emotion/react';
import React from 'react';

import IconBin from '@cimpress-technology/react-streamline-icons/lib/IconBin';
import { Button } from '@cimpress/react-components';

import { DEFAULT_INPUT_HEIGHT } from '../../constants/stylingConstants';

export default function TrashIconButton({
  onDelete,
  size = 'lg',
  styles,
  className = '',
}: {
  onDelete: (...args) => void;
  size?: string;
  styles?: SerializedStyles;
  className?: string;
}) {
  const buttonStyles = css`
    grid-area: delete;
    justify-self: end;

    height: ${DEFAULT_INPUT_HEIGHT};
    padding: 0 16px;
    ${styles || {}};
  `;

  return (
    <Button
      className={className}
      css={buttonStyles}
      variant="link"
      onClick={onDelete}
      icon={<IconBin size={size} />}
    ></Button>
  );
}
