import { IAttribute } from '@cimpress-technology/attribute-model-explorer';
import { cloneDeep, get } from 'lodash';
import React from 'react';

import { DetailedInputList, IInputListProps } from '../AttributeInput';
import ResetAll from '../ResetAll';
import { ILayoutProps } from './';
import GroupView from './GroupView';

// tslint:disable-next-line: variable-name
const DetailedLayout = (props: ILayoutProps) => {
  const {
    unresolvableStateAlert,
    misconfiguredProductAlert,
    disabledSelectionInfoAlert,
    resetExplorer,
    showTooltip,
    selectionOptions,
    layoutConfiguration,
    attributeSpecifications,
    styleClasses,
  } = props;
  const groupings = get(layoutConfiguration, 'groupings', undefined);

  if (groupings && groupings.length > 0) {
    const groupedSelectionOption: any[] = [];
    const clonedSelections = cloneDeep(selectionOptions);

    groupings.forEach(group => {
      const selections: IAttribute[] = [];
      group.attributeKeys.forEach(attributeKey => {
        const index = clonedSelections.findIndex(option => option.key.toLowerCase() === attributeKey.toLowerCase());

        if (index !== -1) {
          selections.push(clonedSelections[index]);
          clonedSelections.splice(index, 1);
        }
      });

      if (selections.length > 0) {
        groupedSelectionOption.push({
          selections,
          groupDescription: group.description,
          groupName: group.name,
        });
      }
    });

    if (groupedSelectionOption.length > 0) {
      if (clonedSelections.length > 0) {
        groupedSelectionOption.push({
          groupDescription: '',
          groupName: '',
          selections: clonedSelections,
        });
      }

      return (
        <div className={`generic-selector ${get(styleClasses, 'genericSelectorWrapper', '')}`}>
          {misconfiguredProductAlert}
          {disabledSelectionInfoAlert}
          {unresolvableStateAlert}
          <ResetAll
            resetExplorer={resetExplorer}
            showTooltip={!!showTooltip}
            styleClass={get(styleClasses, 'resetAllContainer', '')}
          />
          {groupedSelectionOption.map(group => (
            <GroupView
              key={group.groupName}
              name={group.groupName}
              description={group.groupDescription}
            >
              {buildDetailedInputList({
                ...props,
                attributeSpecifications,
                attributes: group.selections,
              })}
            </GroupView>
          ))}
        </div>
      );
    }
  }

  return (
    <div className={`generic-selector ${get(styleClasses, 'genericSelectorWrapper', '')}`}>
      {misconfiguredProductAlert}
      {disabledSelectionInfoAlert}
      {unresolvableStateAlert}
      <ResetAll
        resetExplorer={resetExplorer}
        showTooltip={!!showTooltip}
        styleClass={get(styleClasses, 'resetAllContainer', '')} />
      {buildDetailedInputList({
        ...props,
        attributeSpecifications,
        attributes: selectionOptions,
      })}
    </div>
  );
};

const buildDetailedInputList = (inputListProps: IInputListProps) => {
  return <DetailedInputList {...inputListProps} />;
};

export default DetailedLayout;
