import { ValueState, ValueStateType } from '@cimpress-technology/attribute-model-explorer';
import { Tooltip } from '@cimpress/react-components';
import { isEqual } from 'lodash';
import React, { Component } from 'react';

interface IProps {
  children: React.ReactNode;
  getValueState: (value: string) => any;
  value: string;
  className?: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
  showOnExcluded: boolean;
  showOnResolved: boolean;
  style?: object;
}

interface IState {
  tooltipContents: string;
  showTooltipTimeout?: number;
}

function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function createTooltipMessage(valueState: ValueState): string {
  let msg = '';

  switch (valueState.state) {
    case ValueStateType.Excluded:
    case ValueStateType.Resolved:
      const { cause } = valueState;
      if (cause) {
        const { sourceConstraint } = cause;
        msg = sourceConstraint.selectedAttribute
          ? `${capitalize(valueState.state)} by the selection on ${sourceConstraint.selectedAttribute.key}`
          : `${capitalize(valueState.state)} by the constraint ${sourceConstraint.name}`;
      } else {
        msg = 'The cause cannot be deduced.';
      }
  }

  return msg;
}

const TOOLTIP_DISPLAY_TIMEOUT = 1000;

/**
 * Tooltip that displays information about a the cause of a certain values
 * state on hover over the surrounding element
 */
export default class ValueStateTooltip extends Component<IProps, IState> {
  public static defaultProps = {
    showOnExcluded: true,
    showOnResolved: true,
    style: { display: 'block' },
  };

  public constructor(props: any) {
    super(props);

    this.state = {
      tooltipContents: '',
    };

    this.showTooltip = this.showTooltip.bind(this);
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    /**
     * If we're updating but the state hasn't changed then we want to make sure not to
     * show the tooltip.
     */
    if (isEqual(this.state, prevState)) {
      window.clearTimeout(this.state.showTooltipTimeout);
    }
  }

  public render() {
    const { children, className, direction, style } = this.props;
    const { tooltipContents } = this.state;

    return tooltipContents.length !== 0 ? (
      <Tooltip className="toolTip" style={style} direction={direction || 'right'} contents={tooltipContents}>
        <div
          className={className}
          onMouseEnter={this.showTooltip}
        >
          {children}
        </div>
      </Tooltip>
    ) : (
      <div
        className={className}
        onMouseEnter={this.showTooltip}
      >
        {children}
      </div>
    );
  }

  private showTooltip() {
    window.clearTimeout(this.state.showTooltipTimeout);
    if (this.props.value !== undefined) {
      this.setState({
        showTooltipTimeout: window.setTimeout(() => {
          try {
            const valueState = this.props.getValueState(this.props.value);
            const showTooltip =
                (valueState.state === ValueStateType.Excluded && this.props.showOnExcluded) ||
                (valueState.state === ValueStateType.Resolved && this.props.showOnResolved);
            
            if (valueState.state !== ValueStateType.Included && valueState.cause && showTooltip) {
              this.setState({ tooltipContents: createTooltipMessage(valueState) });
            }
            else{
              this.setState({tooltipContents:''});
            }
            if (valueState.error) {
              // tslint:disable-next-line:no-console
              console.error(valueState.error);
            }
          } catch (err) {
            this.setState({tooltipContents:''});
            /**
             * Weird number values can cause errors so suppress them for now. No harm
             * in not showing the tooltip.
             */
          }
        }, TOOLTIP_DISPLAY_TIMEOUT),
      });
    }
  }

}
