/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { Button } from '@cimpress/react-components';

const AppLinkButton = styled(Button)`
  min-width: 140px !important;
`;

export default AppLinkButton;
