import { Alert } from '@cimpress/react-components';
import React from 'react';

interface IProps {
  message: string;
  email: string;
  emailText: string;
  isInfoAlert: boolean;
}

const createContactMessage = (message: string, email: string, emailText: string) => (
  <span>
    {message}
    <br />
    <br />
    Please contact&nbsp;
    <a href={`mailto:${email}`} target="_top">
      {emailText}
    </a>
    &nbsp; for more information.
  </span>
);

// tslint:disable-next-line: variable-name
const EmailUsAlert = ({ message, email, emailText, isInfoAlert }: IProps) => (
  <Alert
    dismissible={false}
    message={createContactMessage(message, email, emailText)}
    title=""
    status={isInfoAlert ? 'info' : 'warning'}
  />
);

export default EmailUsAlert;
