import IGenericSelectorProps from './IGenericSelector';

/* tslint:disable:no-console */
export function validateProps(props: IGenericSelectorProps) {
  const { product, configurationUrl, authToken, attributeConfigurations } = props;

  if (authToken === undefined || typeof authToken !== 'string') {
    console.error('Failed props validation: authToken is a required string property.');
  }

  if ([configurationUrl, product].every(prop => prop === undefined)) {
    console.error('Failed props validation: One of product or configurationUrl must be defined.');
  }

  if (configurationUrl !== undefined && typeof configurationUrl !== 'string') {
    console.error('Failed props validation: configurationUrl must be a string.');
  }

  if (attributeConfigurations && Object.values(attributeConfigurations).filter(config => config.isPivot).length > 2) {
    console.error('Invalid Attribute Configuration: More than two attributes have been set as pivot attributes.');
  }
}
/* tslint:enable:no-console */
