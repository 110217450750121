/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import IconFocusLandscape from '@cimpress-technology/react-streamline-icons/lib/IconFocusLandscape';
import IconZoomIn from '@cimpress-technology/react-streamline-icons/lib/IconZoomIn';
import IconZoomOut from '@cimpress-technology/react-streamline-icons/lib/IconZoomOut';
import { Button, Tooltip } from '@cimpress/react-components';

export default function ZoomButtons({
  zoom,
  setZoom,
  minZoom,
  maxZoom,
  resetPosition,
}: {
  zoom: number;
  setZoom: (z: number) => void;
  minZoom: number;
  maxZoom: number;
  resetPosition: () => void;
}) {
  return (
    <div css={zoomButtonContainerStyles}>
      <Tooltip contents={<p>Recenter canvas</p>}>
        <Button variant="link" onClick={resetPosition} icon={<IconFocusLandscape size="2x" />} />
      </Tooltip>

      <Tooltip contents={<p>Zoom out</p>}>
        <Button
          icon={<IconZoomOut size="2x" />}
          variant="link"
          onClick={() => setZoom(zoom - 0.25)}
          disabled={zoom <= minZoom}
        />
      </Tooltip>
      <Tooltip contents={<p>Zoom In</p>}>
        <Button
          icon={<IconZoomIn size="2x" />}
          variant="link"
          onClick={() => setZoom(zoom + 0.25)}
          disabled={zoom >= maxZoom}
        />
      </Tooltip>
    </div>
  );
}

const zoomButtonContainerStyles = css`
  grid-area: right;
  margin-left: auto;
`;
