import { AttributeConfigurationMap, IProduct } from '@cimpress-technology/attribute-model-explorer';
import { IRequiredAttributes } from '@cimpress-technology/selector-resource-formatter';
import { cloneDeep, get, mapKeys } from 'lodash';
import { IConfigurationMapBuilder } from '../..';
import { IAttributeConfigurationMap, MetadataKeys } from '../../../../Models';
import {
  convertRequiredResourceAttributeObjectToList,
  findAttributesDependencies,
  getRangeWrappedAttribute,
  isV2AttributeInferable,
} from '../../../../UtilsV2';
import { ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY, attributeKeys, PIVOT_DEFAULT_DISPLAY_PRIORITY } from '../../../constants';

/**
 * This class builds configurationMap resources or requiredAttributes passed along with product attributes.
 * Situtaion when user wants selection experience on required attributes or a particular
 * resource and including other product attributes.
 */
export default class ResourceProductConfigurationMapBuilder implements IConfigurationMapBuilder {
  public static getInstance(displaySingleValuedAttributes: boolean | undefined = false): IConfigurationMapBuilder {
    if (!ResourceProductConfigurationMapBuilder.instance) {
      ResourceProductConfigurationMapBuilder.instance = new ResourceProductConfigurationMapBuilder();
    }

    ResourceProductConfigurationMapBuilder.instance.displaySingleValuedAttributes = displaySingleValuedAttributes;
    return ResourceProductConfigurationMapBuilder.instance;
  }

  private static instance: ResourceProductConfigurationMapBuilder;
  private displaySingleValuedAttributes: boolean;

  public buildConfigurationMap(
    product: IProduct,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ): AttributeConfigurationMap {
    const requiredAttributes = !requiredResourceAttributes
      ? { nonProductAttributes: [], productAttributes: [] }
      : requiredResourceAttributes;

    const normalizedConfigurationMap = mapKeys(configurationMap, (value, key) => key.toLowerCase());
    const requiredResourceAttributeKeys = convertRequiredResourceAttributeObjectToList(requiredAttributes);
    const requiredAttributesDependencies = findAttributesDependencies(
      product,
      requiredResourceAttributeKeys,
    ).map((attr: string) => attr.toLowerCase());

    product.options.forEach((option: any) => {
      const configuration: any = cloneDeep(normalizedConfigurationMap[option.name.toLowerCase()] || {});
      const isResourceAttribute: boolean = requiredResourceAttributeKeys.includes(option.name.toLowerCase());
      const isDeriverForRequiredAttribute = requiredAttributesDependencies.includes(option.name.toLowerCase());

      if (configuration.displayPriority === undefined) {
        configuration.displayPriority = configuration.isPivot
          ? PIVOT_DEFAULT_DISPLAY_PRIORITY
          : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
      }

      // This condition addresses scenario when an attribute is inferable
      // We hide inferable attributes until and unless consumer
      // doesn't enforce to display it through attributeConfiguration.
      if (configuration.isHidden === undefined) {
        configuration.isHidden = !this.displaySingleValuedAttributes && isV2AttributeInferable(option);
      }

      configuration.metadata = {
        [MetadataKeys.displayPriority]: configuration.displayPriority,
        [MetadataKeys.isDisplayed]: !configuration.isHidden,
        [MetadataKeys.isResourceAttribute]: isResourceAttribute,
        [MetadataKeys.isResourceDerivedAttribute]: isDeriverForRequiredAttribute,
      };

      normalizedConfigurationMap[option.name.toLowerCase()] = configuration;
    });

    product.properties?.forEach((property: any) => {
      const configuration: any = get(normalizedConfigurationMap, property.name.toLowerCase(), {});
      const isResourceAttribute: boolean = requiredResourceAttributeKeys.includes(property.name.toLowerCase());

      configuration.metadata = {
        // If the user has explicitly passed configuration to display the property
        [MetadataKeys.isDisplayed]: configuration.isHidden === false,
        [MetadataKeys.isResourceAttribute]: isResourceAttribute,
      };

      normalizedConfigurationMap[property.name.toLowerCase()] = configuration;
    });

    const isQuantityResourceAttribute: boolean = requiredResourceAttributeKeys.includes(attributeKeys.QUANTITY);
    const isQuantityDeriverRequired = requiredAttributesDependencies.includes(attributeKeys.QUANTITY);

    const quantityConfiguration: any = cloneDeep(normalizedConfigurationMap[attributeKeys.QUANTITY] || {});

    if (quantityConfiguration.displayPriority === undefined) {
      quantityConfiguration.displayPriority = quantityConfiguration.isPivot
        ? PIVOT_DEFAULT_DISPLAY_PRIORITY
        : ATTRIBUTE_DEFAULT_DISPLAY_PRIORITY;
    }

    if (quantityConfiguration.isHidden === undefined) {
      quantityConfiguration.isHidden = isV2AttributeInferable(
        getRangeWrappedAttribute(
          product[Object.keys(product).find((key: string) => key.toLowerCase() === attributeKeys.QUANTITY) || 0],
        ),
      );
    }

    quantityConfiguration.metadata = {
      [MetadataKeys.displayPriority]: quantityConfiguration.displayPriority,
      [MetadataKeys.isDisplayed]: !quantityConfiguration.isHidden,
      [MetadataKeys.isResourceAttribute]: isQuantityResourceAttribute,
      [MetadataKeys.isResourceDerivedAttribute]: isQuantityDeriverRequired,
    };

    normalizedConfigurationMap[attributeKeys.QUANTITY] = quantityConfiguration;

    return new AttributeConfigurationMap(normalizedConfigurationMap);
  }
}
