/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import IconInformationCircle from '@cimpress-technology/react-streamline-icons/lib/IconInformationCircle';
import { Tooltip, colors } from '@cimpress/react-components';

import { WorkingRoutingConfigurationNode } from '../../types';
import UnassignedNode from './UnassignedNode';

const UnassignedNodeTooltip = (
  <Tooltip
    direction="left"
    style={{ flex: 1 }}
    contents={
      <p>
        Unassigned Nodes are Nodes that are not reachable from the rest of the Routing
        Configuration. These may be reattached by selecting one of them as the Next Node from any
        Condition Group, or as any Node&apos;s Default Next Node. Or, you can permanently delete one
        by clicking on it below and selecting Delete in the Edit Node panel.
      </p>
    }
  >
    <IconInformationCircle weight="fill" color={colors.info.base} />
  </Tooltip>
);

export default function UnassignedNodeContainer({
  nodes,
  onClick,
}: {
  nodes: WorkingRoutingConfigurationNode[];
  onClick: (node: WorkingRoutingConfigurationNode) => void;
}) {
  return (
    <div css={containerStyles}>
      <h3 css={headerStyles}>
        <span css={headerText}>Unassigned Nodes</span> {UnassignedNodeTooltip}
      </h3>
      {nodes.map((node, index) => (
        <UnassignedNode key={`unreachable-node-${index}`} node={node} onClick={onClick} />
      ))}
    </div>
  );
}

const headerStyles = css`
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  margin: 6px 0 12px;

  // This forces the span to be centered, since it now has
  // an element on either side with flex: 1
  &::before {
    content: '';
    flex: 1;
  }
`;

const headerText = css`
  justify-self: middle;
  align-self: center;
`;

const containerStyles = css`
  background-color: ${colors.horizon};
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  height: calc(70vh - 8px);
  margin-left: 16px;
  width: 300px;
`;
