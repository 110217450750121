import { V1AttributeTypes } from '@cimpress-technology/attribute-model-explorer';
import {
  InputFormatterFactory,
  IRequiredAttributes,
  ISelection,
  ISurfaceInputsWithSKU,
  OutputFormatterFactory,
  ProductModel,
  ResourceType,
} from '@cimpress-technology/selector-resource-formatter';

import { IAttributeConfigurationMap, ISKURequiredAttributes } from '../../../Models';

import { cloneDeep, isNil } from 'lodash';
import { IConfigurationMapBuilder, IProcessor } from '..';
import { errorAlertMessages } from '../../constants';
import { IRuleSet } from '../../Interfaces';
import IModelExplorerRepo from '../../Interfaces/IModelExplorerRepo';
import ModelExplorerRepo from '../../Repository/ModelExplorerRepo';

export default class RulesetProcessor implements IProcessor<IRuleSet, string, ISKURequiredAttributes> {
  private configurationMapBuilder: IConfigurationMapBuilder;

  private optimizePivot: boolean;

  public constructor(configurationMapBuilder: IConfigurationMapBuilder, optimizePivot: boolean = true) {
    this.configurationMapBuilder = configurationMapBuilder;
    this.optimizePivot = optimizePivot;
  }

  public getResourceRequiredAttributes(
    authToken: string,
    mcpSkuReferenceId: string,
    selectionResource: ResourceType,
  ): Promise<IRequiredAttributes> {
    return InputFormatterFactory.getFormatter(selectionResource, ProductModel.V1).getRequiredInputs(
      authToken,
      mcpSkuReferenceId,
    );
  }

  public getResource(
    skuRequiredAttributes: ISKURequiredAttributes,
    selectionResource: ResourceType,
    selections: ISelection[],
    requiredResourceAttributes: IRequiredAttributes,
  ): string {
    if (selectionResource === ResourceType.SURFACE) {
      const surfaceInput: ISurfaceInputsWithSKU = {
        referenceId: skuRequiredAttributes.mcpSKU,
        requiredAttributes: requiredResourceAttributes || [],
      };

      return OutputFormatterFactory.getFormatter(selectionResource, ProductModel.V1).getResource(
        surfaceInput,
        selections,
      );
    }

    throw new Error(errorAlertMessages.RESOURCE.NOT_SUPPORTED);
  }

  public createAttributeModelExplorer(
    ruleSet: IRuleSet,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes: IRequiredAttributes,
  ): IModelExplorerRepo {
    const updatedRuleSet = this.getUpdatedRuleset(ruleSet, requiredResourceAttributes);
    const attributeConfigurationMap = this.createAttributeConfigurationMap(
      updatedRuleSet,
      configurationMap,
      requiredResourceAttributes,
    );

    return ModelExplorerRepo.createFromRuleset(updatedRuleSet, attributeConfigurationMap, this.optimizePivot);
  }

  private createAttributeConfigurationMap(
    ruleSet: IRuleSet,
    configurationMap: IAttributeConfigurationMap,
    requiredResourceAttributes?: IRequiredAttributes,
  ) {
    return this.configurationMapBuilder.buildConfigurationMap(ruleSet, configurationMap, requiredResourceAttributes);
  }

  private getUpdatedRuleset(ruleSet: IRuleSet, requiredResourceAttributes?: IRequiredAttributes) {
    if (requiredResourceAttributes && requiredResourceAttributes.nonProductAttributes) {
      const ruleSetClone = cloneDeep(ruleSet);

      if (isNil(ruleSetClone.whiteList)) {
        ruleSetClone.whiteList = [];
      }

      (requiredResourceAttributes.nonProductAttributes || []).forEach(attribute => {
        const nonProductAttribute = cloneDeep(attribute);

        if (
          (nonProductAttribute.type === V1AttributeTypes.listOfValues &&
            nonProductAttribute.attributeValues &&
            nonProductAttribute.attributeValues.length > 0) ||
          (nonProductAttribute.type === V1AttributeTypes.listOfRanges &&
            nonProductAttribute.attributeRanges &&
            nonProductAttribute.attributeRanges.length > 0)
        ) {
          nonProductAttribute.required = true;
          ruleSetClone.whiteList!.push(nonProductAttribute);
        }
      });

      return ruleSetClone;
    }

    return ruleSet;
  }
}
