import React from 'react';

import IconAdd from '@cimpress-technology/react-streamline-icons/lib/IconAdd';
import { Button, colors } from '@cimpress/react-components';

import styles from './addElementButton.module.scss';

export default function AddElementButton({
  onClick,
  text,
  className,
}: {
  onClick: any;
  text: string;
  className?: string;
}) {
  return (
    <Button variant="link" onClick={onClick} className={className || styles.button}>
      <IconAdd color={colors.info.darkest} size="lg" className={styles.icon} />{' '}
      <span className={styles.span}>{text}</span>
    </Button>
  );
}
