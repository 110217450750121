/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import IconDragDrop from '@cimpress-technology/react-streamline-icons/lib/IconDragDrop';
import { colors } from '@cimpress/react-components/';

export default function DraggableElement({
  parentKey,
  index,
  children,
}: {
  parentKey: string;
  index: number;
  children: React.ReactNode;
}) {
  return (
    <div key={`condition-group-container-${parentKey}-${index}`} css={conditionGroupStyle}>
      <Draggable draggableId={`draggable-${parentKey}-${index}`} index={index} key={parentKey}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            css={dragContainerStyle(snapshot)}
          >
            <div css={handleStyle} {...provided.dragHandleProps}>
              <IconDragDrop size="lg" />
            </div>
            <div css={conditionGroupWrapperStyle}>{children}</div>
          </div>
        )}
      </Draggable>
    </div>
  );
}

const conditionGroupStyle = css`
  max-width: 1000px;
  width: 100%;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`;

const dragContainerStyle = ({ isDragging }) => css`
  display: flex;

  border-radius: 4px;
  box-shadow: ${isDragging ? '5px 5px 5px grey' : '0'};
  outline: 1px solid ${isDragging ? colors.ocean.base : 'transparent'};
`;

const handleStyle = css`
  display: flex;
  align-items: center;
  padding-right: 0px;
`;

const conditionGroupWrapperStyle = css`
  flex: 1;
`;
