/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import { Select, colors } from '@cimpress/react-components';

const DictionarySelect = styled(Select)`
  max-width: 200px;
  min-width: 100px;

  .dictionary__control {
    background-color: ${(props) => (props.isDefault ? colors.sky.base : '')};
  }
`;

export default DictionarySelect;
