import React, { useState } from 'react';

import { Button, Select } from '@cimpress/react-components';

import { getTimezone } from '../../features/globalSettings/globalSettingsSlice';
import { setProductConfigurationUrl } from '../../features/productConfigurationSearch/productConfigurationSlice';
import {
  resetOrderAndItems,
  searchExistingResources,
  setEnvironment,
} from '../../features/resourceSearch/resourceSearchSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import StyledTextField from '../styledComponents/StyledTextField';
import ItemSelector from './itemSelector/ItemSelector';
import styles from './populateFromItemOrOrder.module.scss';

export default function PopulateFromItemOrOrder() {
  const [text, setText] = useState<string>('');

  const dispatch = useAppDispatch();
  const timezone = useAppSelector((state) => state.globalSettings.timezone);
  const triedToSetTimezone = useAppSelector((state) => state.globalSettings.triedToSetTimezone);
  const items = useAppSelector((state) => state.resourceSearch.items);
  const environmentForOrderData = useAppSelector((state) => state.resourceSearch.environment);
  const dispatchEnvironment = (env: 'int' | 'prod') => dispatch(setEnvironment(env));

  // TODO - I can probably make searchExistingResources run this
  // if it hasn't run already
  if (!triedToSetTimezone) {
    dispatch(getTimezone());
  }

  const searchResources = (resourceId: string) => {
    dispatch(resetOrderAndItems());
    dispatch(setProductConfigurationUrl(''));
    dispatch(
      searchExistingResources({
        resourceId,
        timezone,
      }),
    );
  };

  return (
    <div>
      <h3 className={styles.semiBold}>Existing Order or Item</h3>
      <Select
        label="Environment for order data"
        options={[
          { label: 'Testing', value: 'int' },
          { label: 'Production', value: 'prod' },
        ]}
        value={{
          label: environmentForOrderData === 'prod' ? 'Production' : 'Testing',
          value: environmentForOrderData,
        }}
        onChange={({ value }) => dispatchEnvironment(value)}
        className={styles.environmentSelect}
      />
      <div className={styles.container}>
        <StyledTextField
          className={styles.input}
          label="Item or Order ID"
          value={text}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
        />
        <Button className={styles.button} variant="default" onClick={() => searchResources(text)}>
          Search
        </Button>
      </div>
      <ItemSelector items={items} />
    </div>
  );
}
