/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Spinner, TextField } from '@cimpress/react-components';

import { clearAlert, setError } from '../features/alerts/alertContentSlice';
import { setProductConfigurationUrl } from '../features/productConfigurationSearch/productConfigurationSlice';
import { resetOrderAndItems } from '../features/resourceSearch/resourceSearchSlice';
import {
  setEtag,
  setRoutingConfiguration,
  setSkuCode,
} from '../features/selectedConfiguration/selectedConfigurationSlice';
import { setSelectedItem } from '../features/selectedItem/selectedItemSlice';
import { selectBuilderTab } from '../features/tabs/tabSlice';
import {
  getRoutingConfigurationByBuyerAndSku,
  getRoutingConfigurationById,
} from '../services/routingConfigurationService';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { RoutingConfigurationV3 } from '../types';
import { formatHttpErrorMessage } from '../utils/formatHttpErrorMessage';

export default function SearchBySku() {
  const navigate = useNavigate();
  const { accountId } = useParams();

  const [isSearching, setIsSearching] = useState(false);
  const skuCode = useAppSelector((state) => state.selectedConfiguration.skuCode);

  // Wipe out skucode when first visiting
  useEffect(() => {
    dispatch(setSkuCode(''));
  }, []);

  const dispatch = useAppDispatch();
  const dispatchErrors = ({ messages, title }: { messages: string[]; title: string }) =>
    dispatch(setError({ messages, title }));

  const dispatchSelectedConfiguration = (rc: RoutingConfigurationV3) => {
    dispatch(setRoutingConfiguration(rc));
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSkuCode(e.target.value));
  };

  const searchConfiguration = async () => {
    dispatch(setProductConfigurationUrl(''));
    dispatch(setSelectedItem());
    dispatch(resetOrderAndItems());
    dispatch(clearAlert());
    dispatch(selectBuilderTab());
    try {
      setIsSearching(true);
      const trimmedSkuCode = _.trim(skuCode);
      dispatch(setSkuCode(trimmedSkuCode));
      const { configuration } = await getRoutingConfigurationByBuyerAndSku({
        buyer: accountId,
        skuCode: trimmedSkuCode,
      });
      if (configuration.id && _.toLower(configuration.id) !== 'default') {
        // Re-search by ID so we get a real etag
        const { configuration: configWithId, etag } = await getRoutingConfigurationById(
          configuration.id,
        );
        dispatchSelectedConfiguration(configWithId);
        dispatch(setEtag(etag));
        if (accountId) {
          navigate(`/accounts/${accountId}/configurations/edit/${configuration.id}`);
        } else {
          navigate(`/configurations/${configuration.id}`);
        }
      } else {
        dispatchSelectedConfiguration(configuration);
        if (accountId) {
          navigate(`/accounts/${accountId}/configurations/create?skuCode=${skuCode}`);
        } else {
          navigate(`/configurations/create?skuCode=${skuCode}`);
        }
      }
    } catch (e) {
      dispatchErrors({
        title: 'An error occurred while retrieving the Routing Configuration',
        messages: formatHttpErrorMessage(e),
      });
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div css={topLevelStyles}>
      <div css={containerStyles}>
        <TextField
          name="searchBySku"
          label="Search by skuCode"
          onChange={(e) => onInputChange(e)}
          value={skuCode}
          css={textFieldStyle}
        />
        <Button
          variant="primary"
          size="lg"
          css={buttonStyle}
          onClick={searchConfiguration}
          disabled={!skuCode?.length}
        >
          Search
        </Button>
      </div>
      {isSearching && <Spinner fullPage={true} />}
    </div>
  );
}

const topLevelStyles = css`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const containerStyles = css`
  align-items: center;
  display: flex;
  margin: 20px;

  @media (max-width: 700px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
`;

const textFieldStyle = css`
  margin-right: 20px;
  margin-bottom: auto;
  width: 500px;
`;

const buttonStyle = css`
  height: 48px;
`;
