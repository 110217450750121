(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("lodash"), require("@cimpress/react-components"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["lodash", "@cimpress/react-components", "react", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["generic-selector"] = factory(require("lodash"), require("@cimpress/react-components"), require("react"), require("react-dom"));
	else
		root["generic-selector"] = factory(root["_"], root[undefined], root[undefined], root[undefined]);
})(self, (__WEBPACK_EXTERNAL_MODULE__7607__, __WEBPACK_EXTERNAL_MODULE__498__, __WEBPACK_EXTERNAL_MODULE__5840__, __WEBPACK_EXTERNAL_MODULE__8768__) => {
return 