/**
 * Error returned when maximum precision is exceeded
 */
export default class RuleSetError extends Error {
  public additionalProperties: object;

  constructor(message: string, additionalProperties: object = {}) {
    super(message);

    this.additionalProperties = additionalProperties;

    Object.setPrototypeOf(this, RuleSetError.prototype);
  }
}
