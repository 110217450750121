import { DeserializedAttributeModelData, IAttributeModelConfiguration, IProduct } from '@cimpress-technology/attribute-model-explorer';
import { ResourceType } from '@cimpress-technology/selector-resource-formatter';

import { ISKURequiredAttributes } from '../../Models';
import Utils from '../../Utils';
import { errorAlertMessages } from '../constants';
import { ProductDefinition } from '../Interfaces';

import { IConfigurationMapBuilder, IProcessor } from './';
import {
  AMandResourceConfigurationMapBuilder,
  AMConfigurationMapBuilder,
  AMResourceConfigurationBuilder,
  AttributeModelProcessor,
} from './AttributeModel';
import RulesetProcessor from './RuleSet/RulesetProcessor';
import {
  ProductConfigurationMapBuilder,
  ProductV2Processor,
  ResourceProductConfigurationMapBuilder,
  V2ResourceConfigurationMapBuilder,
} from './V2Product';

import {
  RuleSetConfigurationMapBuilder,
  RuleSetResourceConfigurationMapBuilder,
  V1ResourceConfigurationMapBuilder,
} from './RuleSet';
import { SerializedModelProcessor, SerializedProductConfigurationMapBuilder } from './SerializedModel';

export enum SelectorCustomResourceType {
  CUSTOM,
}

export enum ProductModel {
  V1 = 'V1',
  V2 = 'V2',
  AttributeModel = 'AttributeModel',
  SerializedModel = 'SerializedModel',
}

export default class AttributesProcessorFactory {
  public static getProcessor(
    product: ProductDefinition,
    resourceType?: ResourceType | SelectorCustomResourceType,
    withProduct?: boolean,
    displaySingleValuedAttributes?: boolean,
    optimizePivot?: boolean,
    ): IProcessor<ProductDefinition| DeserializedAttributeModelData, IProduct | string| DeserializedAttributeModelData, IProduct | ISKURequiredAttributes| IAttributeModelConfiguration| DeserializedAttributeModelData> {

    switch (Utils.identifyProductModel(product)) {
      case ProductModel.V1:
        return new RulesetProcessor(
          this.getRuleSetConfigurationMapBuilder(resourceType, withProduct, displaySingleValuedAttributes),
          optimizePivot,
        );
      case ProductModel.V2:
        return new ProductV2Processor(
          this.getProductConfigurationMapBuilder(resourceType, withProduct, displaySingleValuedAttributes),
          optimizePivot,
        );
      case ProductModel.AttributeModel:
        return new AttributeModelProcessor(
          this.getAMConfigurationMapBuilder(resourceType, withProduct, displaySingleValuedAttributes),
          optimizePivot,
        );
      case ProductModel.SerializedModel:
        return new SerializedModelProcessor(SerializedProductConfigurationMapBuilder.getInstance(displaySingleValuedAttributes));
      default:
        throw new Error(errorAlertMessages.UNSUPPORTED_PROCESSOR);
    }
  }

  private static getProductConfigurationMapBuilder(
    resourceType?: ResourceType | SelectorCustomResourceType,
    withProduct?: boolean,
    displaySingleValuedAttributes?: boolean,
  ): IConfigurationMapBuilder {
    if (resourceType !== undefined) {
      if (withProduct) {
        return ResourceProductConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
      }
      return V2ResourceConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
    }
    return ProductConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
  }

  private static getRuleSetConfigurationMapBuilder(
    resourceType?: ResourceType | SelectorCustomResourceType,
    withProduct?: boolean,
    displaySingleValuedAttributes?: boolean,
  ): IConfigurationMapBuilder {
    if (resourceType !== undefined) {
      if (withProduct) {
        return RuleSetResourceConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
      }
      return V1ResourceConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
    }

    return RuleSetConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
  }

  private static getAMConfigurationMapBuilder(
    resourceType?: ResourceType | SelectorCustomResourceType,
    withProduct?: boolean,
    displaySingleValuedAttributes?: boolean,
  ): IConfigurationMapBuilder {
    if (resourceType !== undefined) {
      if (withProduct) {
        return AMandResourceConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
      }
      return AMResourceConfigurationBuilder.getInstance(displaySingleValuedAttributes);
    }

    return AMConfigurationMapBuilder.getInstance(displaySingleValuedAttributes);
  }
}
