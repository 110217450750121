// @ts-ignore
// tslint:disable-next-line:no-submodule-imports
import IconLayers from '@cimpress-technology/react-streamline-icons/lib/IconLayers';
import { Tooltip } from '@cimpress/react-components';

import React from 'react';

interface IResourceAttributeIconWithLabelProps {
  attributeKey: string | JSX.Element | JSX.Element[];
  requiredAttributeIcon?: any;
}

/**
 * Error boundary
 */
class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  public componentDidCatch() {}

  public render() {
    return this.state.errorInfo ? <div /> : this.props.children;
  }
}

/**
 * Component to show resource icon with the attribute label.
 * Test cases are getting failed because of `ReferenceError: SVGSVGElement is not defined`, so thats
 * why creating error boundary for this component and wrapping it in.
 */
// tslint:disable-next-line: variable-name
const ResourceAttributeIconWithLabel = ({
  attributeKey,
  requiredAttributeIcon,
}: IResourceAttributeIconWithLabelProps) => {
  /* tslint:disable-next-line */
  const ResourceIcon = requiredAttributeIcon ? requiredAttributeIcon : IconLayers;
  const icon = (
    <Tooltip className="toolTip" direction="top" contents={`Required by resource`}>
      <span className="resource-icon-class-name-as-id">
        <ResourceIcon />
      </span>
    </Tooltip>
  );

  return (
    <ErrorBoundary>
      {icon} {attributeKey}
    </ErrorBoundary>
  );
};

export default ResourceAttributeIconWithLabel;
