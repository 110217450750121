import _ from 'lodash';

export function formatHttpErrorMessage(e: any): string[] {
  if (!e.response) {
    return [e.message];
  }

  let error = `${e.response?.status}: ${e.response?.errorReason}`;
  let details: string[] = [];
  if (e.response?.additionalDetails) {
    details = _.map(
      Object.keys(e.response.additionalDetails),
      (key) => `${key}: ${e.response.additionalDetails[key]}`,
    );
  }
  return [error, ...details];
}
