import { IProduct } from '@cimpress-technology/attribute-model-explorer';
import { cloneDeep } from 'lodash';
import { errorAlertMessages } from '../constants.js';
import { IProductConverter } from '../Interfaces';
import IMerchantProduct from '../Interfaces/IMerchantProduct';

export class MerchantToV2Converter implements IProductConverter<IProduct> {
  private merchantProduct: IMerchantProduct;
  private mcpProduct: IProduct;
  private merchantProductConverted: IProduct;

  constructor(merchantProduct: IMerchantProduct, mcpProduct: IProduct) {
    this.merchantProduct = merchantProduct;
    this.mcpProduct = mcpProduct;
  }

  public convert(): IProduct {
    const clonedMerchantProduct:any = cloneDeep(this.merchantProduct);
    this.merchantProductConverted = clonedMerchantProduct;

    try {
      this.merchantProductConverted.properties = this.mcpProduct.properties;
      this.merchantProductConverted.constraints = this.mcpProduct.constraints;
    }
    catch {
      throw new Error(errorAlertMessages.ERROR_CONVERTING_MERCHANT_PRODUCT);
    }

    return this.merchantProductConverted;
  }
}
