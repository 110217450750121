import React from 'react';
import './ColorSwatch.css';


function hexCodeValidation(hexCode: string): boolean {

  const regex = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
  return regex.test(hexCode);

}

/* tslint:disable-next-line */
export default function ColorSwatch ( props : any ){

  return( hexCodeValidation(props.hexCode) ? ( 
      <span className="square" style= {{ backgroundColor: props.hexCode }}/>
    ) : null
  );
}